import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useApplicantStore from '../applicantStore/useApplicantStore';
import useCoapplicantStore from '../coapplicantStore/useCoapplicantStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { resetStore } from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { dashboardStore } from './dashboardStore';
import { DashboardState } from './types/DashboardState';
import { DashboardVehicle } from './types/DashboardVehicle';

const useDashboardStore: DevtoolsStore<DashboardState<DashboardVehicle>> =
  create()(
    devtools(
      dashboardStore({
        resetStore,
        useApiStore: lazyDependency(() => useApiStore),
        useApplicantStore: lazyDependency(() => useApplicantStore),
        useCoapplicantStore: lazyDependency(() => useCoapplicantStore),
        Vehicle: DashboardVehicle,
      }),
      devToolOptions('dashboardStore'),
    ),
  );

export default useDashboardStore;
