import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useCoapplicantStore from '../coapplicantStore/useCoapplicantStore';
import useDashboardStore from '../dashboardStore/useDashboardStore';
import {
  create,
  devToolOptions,
  lazyDependency,
  resetStore,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import useWorkflowStore from '../workflowStore/useWorkflowStore';
import {
  applicantStore,
  currentIncomeKeys,
  currentResidenceKeys,
  keysToPersist,
  previousIncomeKeys,
  previousResidenceKeys,
} from './applicantStore';
import { ApplicantState } from './types/ApplicantState';

const useApplicantStore: DevtoolsPersistStore<ApplicantState> = create({
  hmrPersist: 'applicantStore',
})(
  devtools(
    persist<ApplicantState>(
      applicantStore({
        resetStore,
        useApiStore: lazyDependency(() => useApiStore),
        useCoapplicantStore: lazyDependency(() => useCoapplicantStore),
        useDashboardStore: lazyDependency(() => useDashboardStore),
        useWorkflowStore: lazyDependency(() => useWorkflowStore),
      }),
      {
        name: 'consumer_portal:applicant',
        partialize(state: ApplicantState): ApplicantState {
          if (!state.computed.isCurrentIncomePersisted) {
            keysToPersist.push(...currentIncomeKeys);
          }

          if (!state.computed.isPreviousIncomePersisted) {
            keysToPersist.push(...previousIncomeKeys);
          }

          if (!state.computed.isCurrentResidencePersisted) {
            keysToPersist.push(...currentResidenceKeys);
          }

          if (!state.computed.isPreviousResidencePersisted) {
            keysToPersist.push(...previousResidenceKeys);
          }

          return pick(state, keysToPersist) as ApplicantState;
        },
      },
    ),
    devToolOptions('applicantStore'),
  ),
);

export default useApplicantStore;
