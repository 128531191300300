import { OwnershipStatus } from '@thesavingsgroup/enums/OwnershipStatus';
import { isNil } from 'lodash';

import { ResidenceTypeEnum } from '../enums/ResidenceTypeEnum';

export const ownershipStatusToResidenceType = (
  ownershipStatus?: OwnershipStatus | null,
): ResidenceTypeEnum | null => {
  if (isNil(ownershipStatus)) {
    return null;
  }

  switch (ownershipStatus) {
    case OwnershipStatus.OWN:
      return ResidenceTypeEnum.OWN;
    case OwnershipStatus.RENT:
      return ResidenceTypeEnum.RENT;
    default:
      return ResidenceTypeEnum.OTHER;
  }
};
