import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useWorkflowStore from '../store/workflowStore/useWorkflowStore';

const WorkflowGuard = ({ children }: { children: JSX.Element }) => {
  const [routeOverride, setRouteOverride] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // detect whether the current route is a workflow route
    useWorkflowStore.getState().detect(location.pathname, setRouteOverride);
  }, [location]);

  useEffect(() => {
    if (routeOverride) {
      // the route override can be used by the workflow store to inform
      // this guard about the user attempting to access a page that should
      // not be accessed yet because there are incomplete steps
      //
      // using `replace` here so the user is unable to go back to the
      // previous page as we have already determined they have not completed
      // enough steps
      navigate(routeOverride, { replace: true });

      // reset the override value after we navigate
      setRouteOverride('');
    }
  }, [routeOverride, navigate]);

  return children;
};

export default WorkflowGuard;
