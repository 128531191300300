import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import { cloneDeep, isEqual, merge, pick } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';

import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { VehicleContext } from '../../common/unified-workflow-context.interface';

const mapErrorsToFormFields = (apiErrors: any) => {
  const unexpectedErr =
    'There was an unexpected error attempting to fetch vehicle details. Please try again.';
  const modelFragment: any = { fieldErrors: {}, globalErrors: [] };

  apiErrors.forEach((error: any) => {
    if (error.statusCode === HttpStatusCode.NOT_FOUND) {
      modelFragment.globalErrors.push(
        'Vehicle details not found from the plate',
      );
    } else {
      modelFragment.globalErrors.push(unexpectedErr);
    }
  });
  return modelFragment;
};

type FormDataType = Required<Pick<VehicleContext, 'plate' | 'province'>>;

const Controller = ({ send, presModel, context }: any) => {
  const api = useAPI();
  const { editedVehicle } = context;
  const { vehicleInformationSource, plate, province } = editedVehicle;
  const cachedConfirmedVehicleData = pick(editedVehicle, [
    'mileage',
    'trim',
    'trimId',
    'loan.payoffAmount',
  ]);

  const isNewSearchParams = useCallback(
    (newSearchParams: FormDataType) =>
      !isEqual(newSearchParams, { plate, province }),
    [plate, province],
  );

  const onSubmit = useAsyncCallback(async (formData: FormDataType) => {
    const transformedPlateNumber = formData.plate.replace(/[^a-zA-Z0-9]/g, '');
    const fetchedVehicle = await api
      .post('/vehicles/search', {
        criteria: 'BY_PLATE',
        value: {
          plate: transformedPlateNumber,
          state: formData.province,
        },
      })
      .then((vehicles: any) => vehicles[0]);
    const { chromeDataVehicleInformation, ...restFetchedVehicleData } =
      fetchedVehicle;
    send('Next', {
      data: {
        editedVehicle: {
          vehicleInformationSource,
          ...chromeDataVehicleInformation,
          ...restFetchedVehicleData,
          ...(isNewSearchParams(formData) ? {} : cachedConfirmedVehicleData),
          ...formData,
        },
      },
    });
  });

  const formErrors = useMemo(() => {
    if (onSubmit.error) {
      return mapErrorsToFormFields(onSubmit.error);
    }
    return {
      globalErrors: [],
    };
  }, [onSubmit]);

  const enhancedProps = useMemo(
    () =>
      cloneDeep(
        merge(
          {},
          { presModel },
          {
            presModel: {
              form: {
                actions: {
                  primary: {
                    isLoading: onSubmit.loading,
                    handler: onSubmit.execute,
                  },
                },
                ...formErrors,
              },
            },
          },
        ),
      ),
    [presModel, onSubmit, formErrors],
  );

  return <PillowForm {...enhancedProps} />;
};

Controller.displayName = 'VehicleManualFindByPlate.Controller';
export default Controller;
