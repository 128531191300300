import { uniqueId } from 'lodash';
import { get as lodashGet } from 'lodash';
import { devtools } from 'zustand/middleware';

import { create, devToolOptions } from '../storeManager/storeManager';

export enum LoanDocumentType {
  RIC = 'RIC',
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  GAP_CONTRACT = 'GAP_CONTRACT',
}

export type LoanDocument = {
  description: string;
  fileNamePrefix: string;
  files: string[];
  folderName: string;
  info: any;
  key: string;
  label: string;
  subtitle: string;
  paragraph: string;
  value: LoanDocumentType;
};

type LoanDocumentStateComputed = {
  autopayNumber: string | undefined;
  currentVehicleId: string | undefined;
  listRouteAllowed: boolean;
  uploadRouteAllowed: boolean;
};

type LoanDocumentState = {
  applicationData: Record<any, any> | null;
  loanDocuments: LoanDocument[];
  selectedLoanDocument: LoanDocument | null;
  addFile: () => void;
  removeFile: (fileName: string) => void;
  setSelectedLoanDocument: (value: LoanDocumentType) => void;
  setSelectedLoanDocumentSubmitted: () => void;
  updateSelectedLoanDocument: (value: LoanDocument) => void;

  computed: LoanDocumentStateComputed;
};

const useLoanDocumentStore = create()(
  devtools<LoanDocumentState>(
    (set, get) => ({
      applicationData: null,
      loanDocuments: [
        {
          description: 'some desc',
          fileNamePrefix: 'retail-contract-',
          files: ['retail-contract-1'],
          folderName: 'retail-contract/',
          info: {
            content: [
              {
                copy: [
                  'This document typically has your name and the dealers name at the top of the documents. It includes your loan terms, details all products and charges included in your purchase / loan, and is usually one long contract, or 3-4 pages long.',
                ],
              },
            ],
            subtitle: 'Loan documents',
            title: 'Retail installment contract',
          },
          key: uniqueId('loan-document-option-'),
          label: 'Retail installment contract',
          paragraph:
            'This document typically has all of your finance and loan terms. Additionally, it itemizes all the products and charges that are rolled into our loan. If you just have one large file that contains all lending documents, upload it here.',
          subtitle: 'Tap to upload',
          value: LoanDocumentType.RIC,
        },
        {
          description: 'some desc',
          fileNamePrefix: 'service-contract-',
          files: ['service-contract-1'],
          folderName: 'service-contract/',
          info: {
            content: [
              {
                copy: [
                  'This document details the terms of your extended service contract (commonly referred to as extended warranty). These contracts can be one or several pages long. We only need the first page to examine if you are eligible for cash back.',
                ],
              },
            ],
            subtitle: 'Loan documents',
            title: 'Service contract',
          },
          key: uniqueId('loan-document-option-'),
          label: 'Service contract',
          paragraph:
            'This document details the terms of your extended service contract, commonly referred to as a warranty.',
          subtitle: 'Tap to upload',
          value: LoanDocumentType.SERVICE_CONTRACT,
        },
        {
          description: 'some desc',
          fileNamePrefix: 'gap-contract-',
          files: ['gap-contract-1'],
          folderName: 'gap-contract/',
          info: {
            content: [
              {
                copy: [
                  'This document details the terms of your GAP policy. These contracts can be one or several pages long. We only need the first page to examine if you are eligible for cash back. This information can also be found on your retail installment contract.',
                ],
              },
            ],
            subtitle: 'Loan documents',
            title: 'GAP contract',
          },
          key: uniqueId('loan-document-option-'),
          label: 'GAP contract',
          paragraph:
            'This document details the terms of your GAP contract, protecting your loan against the effects of negative equity.',
          subtitle: 'Tap to upload',
          value: LoanDocumentType.GAP_CONTRACT,
        },
      ],
      selectedLoanDocument: null,

      computed: {
        get autopayNumber() {
          return lodashGet(
            get().applicationData,
            'currentVehicle.checklightSession.autopayNumber',
          );
        },
        get currentVehicleId() {
          return lodashGet(get().applicationData, 'currentVehicle.id');
        },
        get listRouteAllowed() {
          return Boolean(get().applicationData);
        },
        get uploadRouteAllowed() {
          return Boolean(get().selectedLoanDocument && get().applicationData);
        },
      },

      addFile: () => {
        const { selectedLoanDocument, updateSelectedLoanDocument } = get();

        if (!selectedLoanDocument) {
          return;
        }

        const newFiles = selectedLoanDocument.files.concat(
          uniqueId(selectedLoanDocument.fileNamePrefix + Date.now()),
        );

        const updatedLoanDocument = {
          ...selectedLoanDocument,
          files: newFiles,
        };

        updateSelectedLoanDocument(updatedLoanDocument);
      },
      removeFile: (fileName: string) => {
        const { selectedLoanDocument, updateSelectedLoanDocument } = get();

        if (!selectedLoanDocument) {
          return;
        }

        const newFiles = selectedLoanDocument.files.filter(
          (file) => file !== fileName,
        );

        const updatedLoanDocument = {
          ...selectedLoanDocument,
          files: newFiles,
        };

        updateSelectedLoanDocument(updatedLoanDocument);
      },
      setSelectedLoanDocument: (value: LoanDocumentType) => {
        const { loanDocuments, updateSelectedLoanDocument } = get();

        const selectedLoanDocument = loanDocuments.find(
          (doc) => doc.value === value,
        );

        if (!selectedLoanDocument) {
          return;
        }

        updateSelectedLoanDocument(selectedLoanDocument);
      },
      setSelectedLoanDocumentSubmitted: () => {
        const { selectedLoanDocument, updateSelectedLoanDocument } = get();

        if (!selectedLoanDocument) {
          return;
        }

        const updatedLoanDocument = {
          ...selectedLoanDocument,
          disabled: true,
          subtitle: 'Reviewing documents',
        };

        updateSelectedLoanDocument(updatedLoanDocument);
      },
      updateSelectedLoanDocument: (loanDocument: LoanDocument) => {
        const { loanDocuments } = get();

        if (!loanDocument.value) {
          return;
        }

        set({
          loanDocuments: loanDocuments.map((doc) =>
            doc.value === loanDocument.value ? loanDocument : doc,
          ),
          selectedLoanDocument: loanDocument,
        });
      },
    }),
    devToolOptions('loanDocumentStore'),
  ),
);

export default useLoanDocumentStore;
