import { create as _create, StateCreator, UseBoundStore } from 'zustand';

import { config } from '../../config';
import { allStores } from './constants/allStores';
import { StoreConfig } from './types/StoreConfig';

/**
 * Zustand create method that allows stores to be reset
 *
 * @param {StoreConfig} config - Configuration options for store creation
 * @param {boolean | undefined} config.ignoreReset - Disallow resetting of store to initial state
 * @param {string | undefined} config.hmrPersist - Enables persistence of store state during HMR using the provided key; See: `persistStoreDuringHMR`
 */
export const create = (config?: StoreConfig) =>
  (<TState>(createState?: StateCreator<TState>) => {
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__();

    if (!createState) {
      return create;
    }

    const { ignoreReset, hmrPersist } = config || {};

    const store: UseBoundStore<any> = _create(createState);

    // setup store reset functionality
    if (!ignoreReset) {
      const initialState = store.getState();

      const resetFn = () => {
        store.setState(initialState, true);

        if (store.persist) {
          store.persist.clearStorage();
        }
      };

      // add reset function to store
      store.reset = () => resetFn();
    }

    // setup store HMR persistence
    if (hmrPersist) {
      persistStoreDuringHMR(hmrPersist, store);
    }

    allStores.push(store);

    return store;
  }) as typeof _create;

/**
 * Reset store to initial state
 * - Store must have been created without the ignoreReset option
 *
 * @param {UseBoundStore<any>} store - Store to reset
 */
export const resetStore = (store: UseBoundStore<any>) => {
  if (store.reset) {
    store.reset();
  }
};

export const resetAllStores = () => {
  allStores.forEach((store) => {
    resetStore(store);
  });
};

/**
 * Returns the options object for the store devtool middleware.
 *
 * @param storeName - The name of the store.
 * @returns The options object for the store dev tool.
 */
export const devToolOptions = (storeName: string) => ({
  enabled: config.debug.devtools,
  name: storeName,

  // https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#serialize
  serialize: {
    options: {
      true: true,
    },
    // allows for tracking of computed properties through devtools
    replacer: devToolComputedValueReplacer,
  },
});

/**
 * Replaces computed properties in an object with their current values.
 *
 * This is necessary because the Redux DevTools extension does not support computed properties.
 * Depending on when the store is serialized, the computed properties may not have access to state.
 *
 * This function ensures the computed properties have access to the store's state before it assigns its value.
 *
 * @param key - The key of the property being stringified.
 * @param value - The value of the property being stringified.
 * @returns The replaced value if the property is a computed property, otherwise the original value.
 */
const devToolComputedValueReplacer = (key: string, value: unknown) => {
  // getters are nested under the computed key within stores.
  // ensures that we target the computed key and that its value is an object
  if (key === 'computed' && typeof value === 'object' && value !== null) {
    const replacedValue: Record<string, unknown> = {};

    for (const nestedKey in value) {
      const getterDescriptor = Object.getOwnPropertyDescriptor(
        value,
        nestedKey,
      );

      if (getterDescriptor && getterDescriptor.get) {
        try {
          replacedValue[nestedKey] = getterDescriptor.get.call(value);

          // eslint-disable-next-line no-empty
        } catch {}
      }
    }

    return replacedValue;
  }

  return value;
};

/**
 * Persists store state during HMR
 *
 * During HMR, the store state is persisted to prevent loss of state when a module is reloaded.
 *
 * While developing some stores may require state to be persisted during HMR to avoid unexpected behavior.
 * This is an issue directly related to how Zustand performs with Vite's HMR.
 *
 * Example usage: the SessionGuard is dependent on the userStore to determine if a user is authenticated. When the
 * userStore is reset during HMR, the SessionGuard will not have access to the user state and will redirect the application
 * to the sign-out page. This behavior is not ideal during development.
 *
 * Related issues:
 * - https://github.com/pmndrs/zustand/issues/934
 * - https://github.com/pmndrs/zustand/discussions/827
 *
 * @param storeKey - Unique key that identifies the persisted store state
 * @param store - Store of which the state will be persisted
 */
const persistStoreDuringHMR = (storeKey: string, store: UseBoundStore<any>) => {
  if (
    !store.persist && // store does not already have persistence
    import.meta.hot && // Vite HMR is enabled
    import.meta.env.VITE_APP_ENV === 'local' // only allow in local development
  ) {
    const state = import.meta.hot.data[storeKey];

    if (state) {
      store.setState(import.meta.hot.data[storeKey]);
    }

    store.subscribe((state: any) => {
      if (import.meta.hot) {
        import.meta.hot.data[storeKey] = state;
      }
    });

    // https://vitejs.dev/guide/api-hmr#hot-accept-cb
    import.meta.hot.accept((newModule) => {
      if (import.meta.hot && newModule) {
        store.setState(import.meta.hot.data[storeKey]);
      }
    });
  }
};

/**
 * Lazily resolves a dependency to prevent circular dependency issues.
 *
 * When multiple stores depend on each other, direct imports can lead to circular dependencies,
 * causing errors or undefined behavior during module initialization. This method defers the
 * resolution of a dependency until its properties are accessed, ensuring that all stores are
 * fully initialized before being used.
 *
 * Example: When store A depends on store B, and store B depends on store A, a circular
 * dependency is created. To resolve this, store A can be created lazily in store B, and store B
 * can be created lazily in store A.
 *
 * @param {() => T} getter - A function that returns the dependency to resolve lazily.
 * @returns {T} A proxy object that resolves and caches the dependency upon first access.
 */
export const lazyDependency = <T extends object>(getter: () => T): T => {
  let cachedStore: T | undefined;

  return new Proxy({} as T, {
    get(_, prop) {
      if (!cachedStore) {
        cachedStore = getter();
      }
      return (cachedStore as any)[prop];
    },
  });
};
