import { URLSearchParams } from 'url';
import { StateCreator } from 'zustand';

import { ParameterState } from './types/ParameterState';

export const keysToPersist = [
  'apr',
  'authType',
  'autopayNumber',
  'coApplicantCollectAgreeToTerms',
  'collectAgreeToTerms',
  'error',
  'firstName',
  'hasDuplicateApplication',
  'leadChannelCode',
  'migrateUser',
  'monthlyPaymentAmount',
  'phoneNumber',
  'term',
  'token',
  'vehicleId',
];

export const parameterStore: StateCreator<ParameterState> = (set, get) => ({
  apr: null,
  authType: null,
  autopayNumber: null,
  coApplicantCollectAgreeToTerms: null,
  collectAgreeToTerms: null,
  error: null,
  firstName: null,
  hasDuplicateApplication: null,
  intent: null,
  leadChannelCode: null,
  migrateUser: null,
  monthlyPaymentAmount: null,
  phoneNumber: null,
  term: null,
  token: null,
  vehicleId: null,

  setFromURLSearchParams: (searchParams: URLSearchParams) => {
    if (!searchParams) {
      return;
    }

    Object.keys(get())
      .filter((stateKey: string) => searchParams.has(stateKey))
      .forEach((stateKey: string) =>
        set({ [stateKey]: searchParams.get(stateKey) }),
      );
  },
});
