import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';

import { convertAmountToNewPayFrequency } from './convertAmountToNewPayFrequency';

export const getAmountByPayFrequency =
  (targetFrequency: PayFrequency) =>
  (sourceFrequency: PayFrequency, amount?: number) => {
    if (sourceFrequency !== targetFrequency) {
      return convertAmountToNewPayFrequency(
        sourceFrequency,
        targetFrequency,
        amount,
      );
    }
    return Number(amount);
  };
