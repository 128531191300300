import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';

import dealerIcon from '../assets/Dealer.svg';
import privatePartyIcon from '../assets/Private-Party.svg';
import { componentTypes } from '../constants/componentTypes';
import type { FormFieldsModelT } from './formFieldsModelT';

const typeOptionsList = [
  {
    value: ApplicationType.DEALER,
    label: 'Dealer',
    subtitle: 'Buying from a dealership',
    image: dealerIcon,
  },
  {
    value: ApplicationType.PRIVATE_PARTY,
    label: 'Private Party',
    subtitle: 'Buying from a private seller',
    image: privatePartyIcon,
  },
];

export const fields: FormFieldsModelT = {
  purchaseType: {
    name: 'purchaseType',
    component: componentTypes.RADIO,
    options: typeOptionsList,
    defaultValue: typeOptionsList[0].value,
    largeTitle: true,
  },
};
