import { AuthType } from '@thesavingsgroup/auth/AuthType';
import { DeliveryMechanism } from '@thesavingsgroup/auth/DeliveryMechanism';
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import { merge } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import TagManager from 'react-gtm-module';
import { useForm } from 'react-hook-form';

import { Branding } from '../../../../../common/interfaces/branding/branding';
import { PillowForm } from '../../../../../components/PillowForm';
import { Link } from '../../../../../components/shared/link/link';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { useBranding } from '../../../../../hooks/useBranding/useBranding';
import { useCountdownTimer } from '../../../../../hooks/useCountdownTimer/useCountdownTimer';
import { useCurrentUserContext } from '../../../../../hooks/useCurrentUserContext/useCurrentUserContext';
import { useWorkflowPathname } from '../../../../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../../../../services/urlParams/urlParams';
import { urlParamHook } from '../../../../../services/urlParams/urlParamsService';
import { getUserMetaData } from '../../../../../services/user/user.service';
import { useContextCookies } from '../../../../../utils/helpers/contextCookies';
import { getSourceApplicationUrl } from '../../../../../utils/helpers/sourceApplicationUrl';
import { phoneNumberFormatter } from '../../../../../utils/pipes/phone-number';
import { getCoApplicantAddress } from '../../common/applicant.utils';
import { Signal } from '../../XState/Model';

const submitErrors = [
  {
    condition: (error: any) =>
      [HttpStatusCode.NOT_FOUND, HttpStatusCode.UNAUTHORIZED].includes(
        error.statusCode,
      ),
    error:
      'There was an error attempting to verify the code. Please click "Re-send" and try again.',
  },
  {
    condition: (error: any) => error.statusCode === HttpStatusCode.CONFLICT,
    error: (
      <span>
        An account with this Phone number and Email is already registered. Click
        to <Link href={'../sign-in'}>Sign in.</Link>
      </span>
    ),
  },
  {
    condition: () => true,
    error:
      'There was an unexpected error during account creation. Please try again.',
  },
];

const sendPinErrors = [
  {
    condition: (error: any) => error.statusCode === HttpStatusCode.CONFLICT,
    error: (
      <span>
        An account with this Phone number and Email is already registered. Click
        to <Link href={'../sign-in'}>Sign in.</Link>
      </span>
    ),
  },
  {
    condition: () => true,
    error:
      'There was an unexpected error attempting to send the verification code, please try again later.',
  },
];

const mapApiErrorsToFormErrors = (
  apiErrors: any,
  errorHandlers: {
    condition: (error: any) => boolean;
    error: string | JSX.Element;
  }[],
): (string | JSX.Element)[] => {
  // Make sure form errors are not duplicated
  const errorsPerStatus = apiErrors.reduce((acc: any, apiError: any) => {
    const errorConfigIndex = errorHandlers.findIndex(({ condition }) =>
      condition(apiError),
    );

    return {
      ...acc,
      [errorConfigIndex]: errorHandlers[errorConfigIndex].error,
    };
  }, {});

  return Object.values(errorsPerStatus);
};

const getBrandInfo = (
  branding: Branding,
  leadChannelCode?: string,
): {
  brand?: string;
  sourceApplicationUrl: string;
  leadChannelCode?: string;
} => {
  const { id: brand } = branding;
  const sourceApplicationUrl = getSourceApplicationUrl();
  return { sourceApplicationUrl, brand, leadChannelCode };
};

const Controller = (props: any) => {
  const [, setCurrentUserContext] = useCurrentUserContext();
  const { savedParam: leadChannelId } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );
  const {
    presModel,
    send,
    context: { user, vehicle, coApplicant },
  } = props;

  const api = useAPI();

  const methods = useForm();
  const { resetField } = methods;

  const [secondsLeft, setSecondsLeft] = useState(0);
  const { resendTimerDuration = 15 } = presModel;
  const { startTimer, stopTimer } = useCountdownTimer(
    resendTimerDuration,
    setSecondsLeft,
  );

  const { branding } = useBranding();
  const workflow = useWorkflowPathname();
  const { getCookieGoogleVisitorId } = useContextCookies();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'user-registering',
      },
    });
    return () => stopTimer();
  }, [stopTimer]);

  const onSubmit = useAsyncCallback(async ({ pin }) => {
    if (onResend.error) {
      return;
    }
    try {
      const newUserContext: any = await api.post('/users', {
        ...user,
        pin,
        vehicle: {
          ...vehicle,
          vin: vehicle.vin?.toUpperCase(),
        },
        coApplicant: {
          ...coApplicant,
          ...('sameAsApplicant' in (coApplicant || {})
            ? {
                residences: {
                  current: getCoApplicantAddress(props.context),
                },
              }
            : {}),
        },
        ...getBrandInfo(branding, leadChannelId as string),
        metaData: getUserMetaData(
          props.context,
          workflow,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          getCookieGoogleVisitorId()!,
        ),
      });
      const { vehicle: currentVehicle, ...userData } = newUserContext;
      setCurrentUserContext({
        ...userData,
        userVehicles: [currentVehicle],
      });

      TagManager.dataLayer({
        dataLayer: {
          event: 'user-registered',
        },
      });

      if (userData.hasLosError) {
        send(Signal.ExitToDashboard);
        return;
      }

      send(Signal.Next, {
        data: {
          vehicle: currentVehicle,
          user: userData,
        },
      });
    } catch (e) {
      throw mapApiErrorsToFormErrors(e, submitErrors);
    }
  });

  const onResend = useAsync(async () => {
    startTimer();
    onSubmit.reset();
    resetField('pin');
    try {
      await api.post('pin/deliver', {
        email: user.primaryEmailAddress,
        phone: user.primaryPhoneNumber,
        purpose: AuthType.ACCOUNT_REGISTRATION_USING_PIN,
        deliverWith: DeliveryMechanism.SMS,
      });
    } catch (e) {
      throw mapApiErrorsToFormErrors(e, sendPinErrors);
    }
  }, []);

  const transformedPhoneNumber = useMemo(
    () =>
      user.primaryPhoneNumber && phoneNumberFormatter(user.primaryPhoneNumber),
    [user.primaryPhoneNumber],
  );

  const hint = useMemo(() => {
    if (onResend.loading) {
      return 'Delivering code...';
    }
    if (onSubmit.loading) {
      return 'Verifying code...';
    }
    if (!onSubmit.error && !onResend.error) {
      return `Code sent to ${transformedPhoneNumber}`;
    }
    return '';
  }, [onResend, onSubmit, transformedPhoneNumber]);

  const enhancedProps = useMemo(() => {
    const timerIsActive = secondsLeft > 0;
    const loading = onResend.loading || onSubmit.loading;
    return merge(
      {},
      props,
      { presModel },
      {
        presModel: {
          template: {
            header: {
              showBack: !loading && !timerIsActive,
              onBack: () => {
                send(Signal.Previous);
              },
            },
          },
          form: {
            actions: {
              primary: {
                handler: onSubmit.execute,
                isDisabled: loading,
              },
              secondary: {
                handler: onResend.execute,
                isDisabled: loading || timerIsActive,
                timerDuration: timerIsActive && secondsLeft,
                ...(timerIsActive ? { label: 'Retry' } : {}),
              },
            },
            fields: {
              pin: {
                hint,
                disabled: loading,
              },
            },
          },
        },
      },
      {
        presModel: {
          form: {
            globalErrors: onSubmit.error || onResend.error,
          },
        },
      },
    );
  }, [props, onSubmit, onResend, presModel, secondsLeft, send, hint]);

  return <PillowForm {...enhancedProps} methods={methods} />;
};

Controller.displayName = 'AccountVerification.Controller';
export default Controller;
