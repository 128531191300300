import { employedTypes } from '@thesavingsgroup/constants/employedTypes';
import { unemployedTypes } from '@thesavingsgroup/constants/unemployedTypes';
import { EmploymentType } from '@thesavingsgroup/enums/EmploymentType';
import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import i18n from 'i18next';
import { isString } from 'lodash';
import { StateCreator } from 'zustand';

import { additionalIncomeDurationFormat } from '../applicantStore/constants/additionalIncomeDurationFormat';
import { additionalIncomeTypeFormat } from '../applicantStore/constants/additionalIncomeTypeFormat';
import { employmentTypeFormat } from '../applicantStore/constants/employmentTypeFormat';
import { HALF_YEAR_IN_MONTHS_AS_STRING } from '../applicantStore/constants/halfYear';
import { ONE_YEAR_IN_MONTHS_AS_STRING } from '../applicantStore/constants/oneYear';
import { residenceTypeFormat } from '../applicantStore/constants/residenceTypeFormat';
import { TWO_YEARS_IN_MONTHS_AS_STRING } from '../applicantStore/constants/twoYears';
import { IncomeFrequencyEnum } from '../applicantStore/enums/IncomeFrequencyEnum';
import { IncomeTypeEnum } from '../applicantStore/enums/IncomeTypeEnum';
import { ResidenceTypeEnum } from '../applicantStore/enums/ResidenceTypeEnum';
import { NO, YES, YesOrNo } from '../applicantStore/types/YesOrNo';
import { CoapplicantDto } from './dto/CoapplicantDto';
import { RelationshipEnum } from './enums/RelationshipEnum';
import { toCoapplicantDto } from './functions/toCoapplicantDto';
import { toCoapplicantState } from './functions/toCoapplicantState';
import { CoapplicantState } from './types/CoapplicantState';
import { CoapplicantStateDependencies } from './types/CoapplicantStateDependencies';

export const keysToPersist: string[] = [
  'coapplicantAdditionalIncomeAmount',
  'coapplicantAdditionalIncomeFrequency',
  'coapplicantAdditionalIncomeType',
  'coapplicantCurrentIncomeEmployerName',
  'coapplicantCurrentIncomeEmploymentType',
  'coapplicantCurrentIncomeGrossAnnual',
  'coapplicantCurrentIncomeJobTitle',
  'coapplicantCurrentIncomeMonthsAt',
  'coapplicantCurrentResidenceCity',
  'coapplicantCurrentResidenceLineOne',
  'coapplicantCurrentResidenceLineTwo',
  'coapplicantCurrentResidenceMonthlyPaymentAmount',
  'coapplicantCurrentResidenceMonthsAt',
  'coapplicantCurrentResidencePostalCode',
  'coapplicantCurrentResidenceState',
  'coapplicantCurrentResidenceType',
  'coapplicantId',
  'coapplicantFirstName',
  'coapplicantLastName',
  'coapplicantPreviousIncomeEmployerName',
  'coapplicantPreviousIncomeEmploymentType',
  'coapplicantPreviousIncomeGrossAnnual',
  'coapplicantPreviousIncomeJobTitle',
  'coapplicantPreviousResidenceCity',
  'coapplicantPreviousResidenceLineOne',
  'coapplicantPreviousResidenceLineTwo',
  'coapplicantPreviousResidencePostalCode',
  'coapplicantPreviousResidenceState',
  'coapplicantPrimaryEmailAddress',
  'coapplicantPrimaryPhoneNumber',
  'coapplicantRelationship',
  'hasAdditionalIncome',
  'hasSameResidenceAsApplicant',
  'isCoapplicantCurrentlyEmployed',
];

export const coapplicantStore =
  ({
    useApiStore,
    useApplicantStore,
    useDashboardStore,
    useWorkflowStore,
  }: CoapplicantStateDependencies): StateCreator<CoapplicantState> =>
  (set, get) => ({
    coapplicantAdditionalIncomeAmount: null,
    coapplicantAdditionalIncomeFrequency: null,
    coapplicantAdditionalIncomeType: null,

    coapplicantCurrentIncomeEmployerName: null,
    coapplicantCurrentIncomeEmploymentType: null,
    coapplicantCurrentIncomeGrossAnnual: null,
    coapplicantCurrentIncomeJobTitle: null,
    coapplicantCurrentIncomeMonthsAt: null,

    coapplicantCurrentResidenceCity: null,
    coapplicantCurrentResidenceLineOne: null,
    coapplicantCurrentResidenceLineTwo: null,
    coapplicantCurrentResidenceMonthlyPaymentAmount: null,
    coapplicantCurrentResidenceMonthsAt: null,
    coapplicantCurrentResidencePostalCode: null,
    coapplicantCurrentResidenceState: null,
    coapplicantCurrentResidenceType: null,

    coapplicantDateOfBirth: null,
    coapplicantFirstName: null,
    coapplicantId: null,
    coapplicantLastName: null,

    coapplicantPreviousIncomeEmployerName: null,
    coapplicantPreviousIncomeEmploymentType: null,
    coapplicantPreviousIncomeGrossAnnual: null,
    coapplicantPreviousIncomeJobTitle: null,

    coapplicantPreviousResidenceCity: null,
    coapplicantPreviousResidenceLineOne: null,
    coapplicantPreviousResidenceLineTwo: null,
    coapplicantPreviousResidencePostalCode: null,
    coapplicantPreviousResidenceState: null,

    coapplicantPrimaryEmailAddress: null,
    coapplicantPrimaryPhoneNumber: null,
    coapplicantRelationship: null,

    hasAdditionalIncome: null,
    hasLoadedCoapplicant: false,
    hasSameResidenceAsApplicant: null,

    isCoapplicantCurrentlyEmployed: null,
    isLoadingCoapplicant: false,
    isSyncingCoapplicant: false,

    syncingCoapplicantFailedMessage: null,

    computed: {
      get coapplicantAdditionalIncomeAmount() {
        const { coapplicantAdditionalIncomeAmount } = get();

        if (!coapplicantAdditionalIncomeAmount) {
          return '-';
        }

        const localeAmount = parseInt(
          coapplicantAdditionalIncomeAmount,
        ).toLocaleString();

        return `$${localeAmount}`;
      },
      get coapplicantAdditionalIncomeFrequency() {
        const { coapplicantAdditionalIncomeFrequency } = get();

        if (
          coapplicantAdditionalIncomeFrequency &&
          additionalIncomeDurationFormat[coapplicantAdditionalIncomeFrequency]
        ) {
          return additionalIncomeDurationFormat[
            coapplicantAdditionalIncomeFrequency
          ];
        }

        return '-';
      },
      get coapplicantAdditionalIncomeStatus() {
        const { hasAdditionalIncome } = get();

        return hasAdditionalIncome === YES
          ? i18n.t('incomeStatus.hasAdditionalIncome')
          : i18n.t('incomeStatus.noAdditionalIncome');
      },
      get coapplicantAdditionalIncomeType() {
        const { coapplicantAdditionalIncomeType } = get();

        if (
          coapplicantAdditionalIncomeType &&
          additionalIncomeTypeFormat[coapplicantAdditionalIncomeType]
        ) {
          return additionalIncomeTypeFormat[coapplicantAdditionalIncomeType];
        }

        return '-';
      },
      get coapplicantCurrentIncomeAmount() {
        const { coapplicantCurrentIncomeGrossAnnual } = get();

        if (!coapplicantCurrentIncomeGrossAnnual) {
          return '-';
        }

        const localeAmount = parseInt(
          coapplicantCurrentIncomeGrossAnnual,
        ).toLocaleString();

        return `$${localeAmount}`;
      },
      get coapplicantCurrentIncomeDuration() {
        const { coapplicantCurrentIncomeMonthsAt } = get();

        if (!coapplicantCurrentIncomeMonthsAt) {
          return '-';
        }

        return coapplicantCurrentIncomeMonthsAt === ONE_YEAR_IN_MONTHS_AS_STRING
          ? i18n.t('employmentDuration.moreThan1Year')
          : i18n.t('employmentDuration.lessThan1Year');
      },
      get coapplicantCurrentIncomeStatus() {
        const { isCoapplicantCurrentlyEmployed } = get();

        if (!isCoapplicantCurrentlyEmployed) {
          return '-';
        }

        return isCoapplicantCurrentlyEmployed === YES
          ? i18n.t('employmentStatus.employed')
          : i18n.t('employmentStatus.unemployed');
      },
      get coapplicantCurrentIncomeType() {
        const { coapplicantCurrentIncomeEmploymentType } = get();

        if (
          coapplicantCurrentIncomeEmploymentType &&
          employmentTypeFormat[coapplicantCurrentIncomeEmploymentType]
        ) {
          return employmentTypeFormat[coapplicantCurrentIncomeEmploymentType];
        }

        return '-';
      },
      get coapplicantCurrentResidenceAddress() {
        const {
          coapplicantCurrentResidenceCity,
          coapplicantCurrentResidenceLineOne,
          coapplicantCurrentResidenceLineTwo,
          coapplicantCurrentResidencePostalCode,
          coapplicantCurrentResidenceState,
        } = get();

        if (
          !coapplicantCurrentResidenceLineOne ||
          !coapplicantCurrentResidenceCity ||
          !coapplicantCurrentResidenceState ||
          !coapplicantCurrentResidencePostalCode
        ) {
          return '-';
        }

        let addressFormatted = '';

        if (coapplicantCurrentResidenceLineOne) {
          addressFormatted += coapplicantCurrentResidenceLineOne;
        }

        if (coapplicantCurrentResidenceLineTwo) {
          addressFormatted += `, ${coapplicantCurrentResidenceLineTwo}`;
        }

        if (coapplicantCurrentResidenceCity) {
          addressFormatted += `, ${coapplicantCurrentResidenceCity}`;
        }

        if (coapplicantCurrentResidenceState) {
          addressFormatted += `, ${coapplicantCurrentResidenceState}`;
        }

        if (coapplicantCurrentResidencePostalCode) {
          addressFormatted += `, ${coapplicantCurrentResidencePostalCode}`;
        }

        return addressFormatted;
      },
      get coapplicantCurrentResidenceAmount() {
        const { coapplicantCurrentResidenceMonthlyPaymentAmount } = get();

        if (!coapplicantCurrentResidenceMonthlyPaymentAmount) {
          return '-';
        }

        const localeAmount = parseInt(
          coapplicantCurrentResidenceMonthlyPaymentAmount,
        ).toLocaleString();

        return i18n.t('currency.aMonth', { amount: localeAmount });
      },
      get coapplicantCurrentResidenceCityAsString() {
        const { coapplicantCurrentResidenceCity } = get();

        return isNonEmptyString(coapplicantCurrentResidenceCity)
          ? coapplicantCurrentResidenceCity
          : '';
      },
      get coapplicantCurrentResidenceDuration() {
        const { coapplicantCurrentResidenceMonthsAt } = get();

        if (!coapplicantCurrentResidenceMonthsAt) {
          return '-';
        }

        return coapplicantCurrentResidenceMonthsAt ===
          TWO_YEARS_IN_MONTHS_AS_STRING
          ? i18n.t('residenceDuration.moreThan2Years')
          : i18n.t('residenceDuration.lessThan2Years');
      },
      get coapplicantCurrentResidenceLineOneAsString() {
        const { coapplicantCurrentResidenceLineOne } = get();

        return isNonEmptyString(coapplicantCurrentResidenceLineOne)
          ? coapplicantCurrentResidenceLineOne
          : '';
      },
      get coapplicantCurrentResidenceLineTwoAsString() {
        const { coapplicantCurrentResidenceLineTwo } = get();

        return isNonEmptyString(coapplicantCurrentResidenceLineTwo)
          ? coapplicantCurrentResidenceLineTwo
          : '';
      },
      get coapplicantCurrentResidencePostalCodeAsString() {
        const { coapplicantCurrentResidencePostalCode } = get();

        return isNonEmptyString(coapplicantCurrentResidencePostalCode)
          ? coapplicantCurrentResidencePostalCode
          : '';
      },
      get coapplicantCurrentResidenceStateAsString() {
        const { coapplicantCurrentResidenceState } = get();

        return isNonEmptyString(coapplicantCurrentResidenceState)
          ? coapplicantCurrentResidenceState
          : '';
      },
      get coapplicantCurrentResidenceType() {
        const { coapplicantCurrentResidenceType } = get();

        if (
          coapplicantCurrentResidenceType &&
          residenceTypeFormat[coapplicantCurrentResidenceType]
        ) {
          return residenceTypeFormat[coapplicantCurrentResidenceType];
        }

        return '-';
      },
      get coapplicantEmployedGrossAnnualIncome() {
        const {
          coapplicantCurrentIncomeEmploymentType,
          coapplicantCurrentIncomeGrossAnnual,
        } = get();

        if (
          coapplicantCurrentIncomeEmploymentType &&
          employedTypes.includes(coapplicantCurrentIncomeEmploymentType) &&
          coapplicantCurrentIncomeGrossAnnual
        ) {
          return coapplicantCurrentIncomeGrossAnnual;
        }

        return null;
      },
      get coapplicantReviewEditProgress() {
        return useWorkflowStore
          .getState()
          .progressByPath(/\/applicant\/.*\/coapp-review-applicant/);
      },
      get coapplicantUnemployedGrossAnnualIncome() {
        const {
          coapplicantCurrentIncomeGrossAnnual,
          coapplicantCurrentIncomeEmploymentType,
        } = get();

        if (
          coapplicantCurrentIncomeEmploymentType &&
          unemployedTypes.includes(coapplicantCurrentIncomeEmploymentType) &&
          coapplicantCurrentIncomeGrossAnnual
        ) {
          return coapplicantCurrentIncomeGrossAnnual;
        }

        return null;
      },
      get isCoapplicantPersisted() {
        const { coapplicantId } = get();
        return isString(coapplicantId) && coapplicantId.length !== 0;
      },
      get isCurrentIncomeLessThan1Year() {
        return (
          get().coapplicantCurrentIncomeMonthsAt ===
          HALF_YEAR_IN_MONTHS_AS_STRING
        );
      },
      get isCurrentResidenceLessThan2Years() {
        return (
          get().coapplicantCurrentResidenceMonthsAt ===
          ONE_YEAR_IN_MONTHS_AS_STRING
        );
      },
    },

    clearCoapplicantCurrentEmployment() {
      set({
        coapplicantCurrentIncomeEmployerName: null,
        coapplicantCurrentIncomeEmploymentType: null,
        coapplicantCurrentIncomeGrossAnnual: null,
        coapplicantCurrentIncomeJobTitle: null,
      });
    },
    clearCoapplicantCurrentResidence() {
      set({
        coapplicantCurrentResidenceCity: null,
        coapplicantCurrentResidenceLineOne: null,
        coapplicantCurrentResidenceLineTwo: null,
        coapplicantCurrentResidenceMonthlyPaymentAmount: null,
        coapplicantCurrentResidenceMonthsAt: null,
        coapplicantCurrentResidencePostalCode: null,
        coapplicantCurrentResidenceState: null,
        coapplicantCurrentResidenceType: null,
      });
    },

    createCoapplicant() {
      if (get().computed.isCoapplicantPersisted) {
        return Promise.resolve(true);
      }

      const {
        computed: { vehicleApplicationId, vehicleId },
      } = useDashboardStore.getState();

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, CoapplicantDto>(
          `/2.0/vehicles/${vehicleId}/application/${vehicleApplicationId}/co-applicant`,
          toCoapplicantDto(get()),
        )
        .then((coapplicant: CoapplicantDto) => {
          set(toCoapplicantState(coapplicant, useApplicantStore.getState()));

          return true;
        })
        .catch((error) => {
          console.error(error);

          set({
            syncingCoapplicantFailedMessage: i18n.t(
              'errors.createCoapplicant.defaultMessage',
            ),
          });

          return false;
        });
    },

    loadCoapplicant() {
      const { hasLoadedCoapplicant, isLoadingCoapplicant } = get();

      if (hasLoadedCoapplicant || isLoadingCoapplicant) {
        return Promise.resolve(null);
      }

      set({ isLoadingCoapplicant: true });

      const {
        computed: { vehicleApplicationId, vehicleId },
      } = useDashboardStore.getState();

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, CoapplicantDto>(
          `/2.0/vehicles/${vehicleId}/application/${vehicleApplicationId}/co-applicant`,
        )
        .then((coapplicant: CoapplicantDto) =>
          set(toCoapplicantState(coapplicant, useApplicantStore.getState())),
        )
        .then(() => null)
        .catch((error) => {
          console.error(error);
          return null;
        })
        .finally(() =>
          set({ hasLoadedCoapplicant: true, isLoadingCoapplicant: false }),
        );
    },

    setCoapplicantAdditionalIncomeAmount(value: string | null) {
      set({ coapplicantAdditionalIncomeAmount: value });
    },
    setCoapplicantAdditionalIncomeFrequency(value: IncomeFrequencyEnum | null) {
      set({ coapplicantAdditionalIncomeFrequency: value });
    },
    setCoapplicantAdditionalIncomeType(value: IncomeTypeEnum | null) {
      set({ coapplicantAdditionalIncomeType: value });
    },

    setCoapplicantCurrentIncomeEmployerName(value: string | null) {
      set({ coapplicantCurrentIncomeEmployerName: value });
    },
    setCoapplicantCurrentIncomeEmploymentType(value: EmploymentType | null) {
      set({ coapplicantCurrentIncomeEmploymentType: value });
    },
    setCoapplicantCurrentIncomeGrossAnnual(value: string) {
      set({ coapplicantCurrentIncomeGrossAnnual: value });
    },
    setCoapplicantCurrentIncomeJobTitle(value: string | null) {
      set({ coapplicantCurrentIncomeJobTitle: value });
    },
    setCoapplicantCurrentIncomeMonthsAt(value: string) {
      set({
        coapplicantCurrentIncomeMonthsAt: value,

        ...(value === ONE_YEAR_IN_MONTHS_AS_STRING && {
          coapplicantPreviousIncomeEmployerName: null,
          coapplicantPreviousIncomeEmploymentType: null,
          coapplicantPreviousIncomeGrossAnnual: null,
          coapplicantPreviousIncomeJobTitle: null,
        }),
      });
    },

    setCoapplicantCurrentResidenceCity(value: string | null) {
      set({ coapplicantCurrentResidenceCity: value });
    },
    setCoapplicantCurrentResidenceLineOne(value: string | null) {
      set({ coapplicantCurrentResidenceLineOne: value });
    },
    setCoapplicantCurrentResidenceLineTwo(value: string | null) {
      set({ coapplicantCurrentResidenceLineTwo: value });
    },
    setCoapplicantCurrentResidenceMonthlyPaymentAmount(value: string | null) {
      set({ coapplicantCurrentResidenceMonthlyPaymentAmount: value });
    },
    setCoapplicantCurrentResidenceMonthsAt(value: string | null) {
      set({
        coapplicantCurrentResidenceMonthsAt: value,

        ...(value === TWO_YEARS_IN_MONTHS_AS_STRING && {
          coapplicantPreviousResidenceCity: null,
          coapplicantPreviousResidenceLineOne: null,
          coapplicantPreviousResidenceLineTwo: null,
          coapplicantPreviousResidencePostalCode: null,
          coapplicantPreviousResidenceState: null,
        }),
      });
    },
    setCoapplicantCurrentResidencePostalCode(value: string | null) {
      set({ coapplicantCurrentResidencePostalCode: value });
    },
    setCoapplicantCurrentResidenceState(value: string | null) {
      set({ coapplicantCurrentResidenceState: value });
    },
    setCoapplicantCurrentResidenceType(value: ResidenceTypeEnum) {
      set({ coapplicantCurrentResidenceType: value });
    },

    setCoapplicantDateOfBirth(value: string) {
      set({ coapplicantDateOfBirth: value });
    },
    setCoapplicantFirstName(value: string | null) {
      set({ coapplicantFirstName: value });
    },
    setCoapplicantLastName(value: string | null) {
      set({ coapplicantLastName: value });
    },

    setCoapplicantPreviousIncomeEmployerName(value: string | null) {
      set({ coapplicantPreviousIncomeEmployerName: value });
    },
    setCoapplicantPreviousIncomeEmploymentType(value: EmploymentType | null) {
      set({ coapplicantPreviousIncomeEmploymentType: value });
    },
    setCoapplicantPreviousIncomeGrossAnnual(value: string | null) {
      set({ coapplicantPreviousIncomeGrossAnnual: value });
    },
    setCoapplicantPreviousIncomeJobTitle(value: string | null) {
      set({ coapplicantPreviousIncomeJobTitle: value });
    },

    setCoapplicantPreviousResidenceCity(value: string | null) {
      set({ coapplicantPreviousResidenceCity: value });
    },
    setCoapplicantPreviousResidenceLineOne(value: string | null) {
      set({ coapplicantPreviousResidenceLineOne: value });
    },
    setCoapplicantPreviousResidenceLineTwo(value: string | null) {
      set({ coapplicantPreviousResidenceLineTwo: value });
    },
    setCoapplicantPreviousResidencePostalCode(value: string | null) {
      set({ coapplicantPreviousResidencePostalCode: value });
    },
    setCoapplicantPreviousResidenceState(value: string | null) {
      set({ coapplicantPreviousResidenceState: value });
    },

    setCoapplicantPrimaryEmailAddress(value: string | null) {
      set({ coapplicantPrimaryEmailAddress: value });
    },
    setCoapplicantPrimaryPhoneNumber(value: string | null) {
      set({ coapplicantPrimaryPhoneNumber: value });
    },
    setCoapplicantRelationship(value: RelationshipEnum | null) {
      set({ coapplicantRelationship: value });
    },

    setHasAdditionalIncome(value: YesOrNo) {
      set({
        hasAdditionalIncome: value,

        ...(value === NO && {
          coapplicantAdditionalIncomeAmount: null,
          coapplicantAdditionalIncomeFrequency: null,
          coapplicantAdditionalIncomeType: null,
        }),
      });
    },
    setHasSameResidenceAsApplicant(value: YesOrNo) {
      const {
        currentResidenceCity,
        currentResidenceLineOne,
        currentResidenceLineTwo,
        currentResidencePostalCode,
        currentResidenceState,
        currentResidenceType,
      } = useApplicantStore.getState();

      set({
        hasSameResidenceAsApplicant: value,

        ...(value === YES && {
          coapplicantCurrentResidenceCity: currentResidenceCity,
          coapplicantCurrentResidenceLineOne: currentResidenceLineOne,
          coapplicantCurrentResidenceLineTwo: currentResidenceLineTwo,
          coapplicantCurrentResidencePostalCode: currentResidencePostalCode,
          coapplicantCurrentResidenceState: currentResidenceState,
          coapplicantCurrentResidenceType: currentResidenceType,
        }),
      });
    },
    setIsCoapplicantCurrentlyEmployed(value: YesOrNo) {
      set({ isCoapplicantCurrentlyEmployed: value });
    },

    syncCoapplicant() {
      set({
        isSyncingCoapplicant: true,
        syncingCoapplicantFailedMessage: null,
      });

      const {
        computed: { vehicleApplicationId, vehicleId },
      } = useDashboardStore.getState();

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .put<never, CoapplicantDto>(
          `/2.0/vehicles/${vehicleId}/application/${vehicleApplicationId}/co-applicant`,
          toCoapplicantDto(get()),
        )
        .then((coapplicant: CoapplicantDto) => {
          set(toCoapplicantState(coapplicant, useApplicantStore.getState()));

          return true;
        })
        .catch((error) => {
          console.error(error);

          set({
            syncingCoapplicantFailedMessage: i18n.t(
              'errors.syncCoapplicant.defaultMessage',
            ),
          });

          return false;
        })
        .finally(() => set({ isSyncingCoapplicant: false }));
    },
  });
