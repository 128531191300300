import { AuthType } from '@thesavingsgroup/auth/AuthType';
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import i18n from 'i18next';
import { get as lodashGet, isEmpty, toString } from 'lodash';
import { StateCreator } from 'zustand';

import { UserExistsExceptionCode } from './enums/UserExistsExceptionCode';
import { User } from './types/User';
import { UserState } from './types/UserState';
import { UserStateDependencies } from './types/UserStateDependencies';

export const userStore =
  ({
    useApiStore,
    useAttributionStore,
    useLeadChannelStore,
    useParameterStore,
  }: UserStateDependencies): StateCreator<UserState> =>
  (set, get) => ({
    fetchCurrentUserFailed: false,
    isUpdatingUser: false,
    user: null,
    userAuthenticateFailedMessage: null,
    userAuthenticateLocked: false,
    userAuthenticateSuccess: false,
    userCreateFailedMessage: null,
    userCreateSuccess: false,
    userExistsException: null,
    userUpdateFailedMessage: null,
    userUpdateSuccess: false,

    computed: {
      get hasCurrentUser() {
        return !isEmpty(get().user);
      },
      get userEmailAddress() {
        const user = get().user;
        const { primaryEmailAddress = '' } = user || {};

        return primaryEmailAddress;
      },
      get userFirstName() {
        const user = get().user;
        const { firstName = '' } = user || {};

        return firstName;
      },
      get userLastName() {
        const user = get().user;
        const { lastName = '' } = user || {};

        return lastName;
      },
      get userPhoneNumber() {
        const user = get().user;
        const { primaryPhoneNumber = '' } = user || {};

        return primaryPhoneNumber;
      },
      get userTruncatedName() {
        const user = get().user;
        const { firstName = '', lastName = '' } = user || {};

        return `${firstName} ${lastName.charAt(0)}.`;
      },
    },

    clearUserUpdate: () => {
      set({
        isUpdatingUser: false,
        userUpdateFailedMessage: null,
        userUpdateSuccess: false,
      });
    },
    fetchCurrentUser: () => {
      const portalHttpRequest = useApiStore.getState().portalApi();

      set({ fetchCurrentUserFailed: false });

      return portalHttpRequest
        .get<never, User>('/2.0/users/current')
        .then((user) => set({ user }))
        .catch(() => {
          // current user is being checked for on all routes aside from /sign-out.
          // if the user is unable to be fetched in any case, we reset the user state
          // and token to ensure the user cannot access an authenticated route in the SessionGuard.
          useParameterStore.setState({ token: null });

          set({
            fetchCurrentUserFailed: true,
            user: null,
          });
        });
    },
    setUserFirstName: (value: string) => {
      set({ user: { ...get().user, firstName: value } });
    },
    setUserLastName: (value: string) => {
      set({ user: { ...get().user, lastName: value } });
    },
    userAuthenticate: (
      primaryPhoneNumber: string,
      pin: string,
      leadChannelCode: string | null | undefined,
      purpose: AuthType,
      autopayNumber?: string,
    ) => {
      set({
        userAuthenticateFailedMessage: null,
        userAuthenticateLocked: false,
        userAuthenticateSuccess: false,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      const {
        computed: { attributionsForLeadChannel },
      } = useAttributionStore.getState();

      return portalHttpRequest
        .post<never, { user: User }>('/2.0/session', {
          attributions: attributionsForLeadChannel,
          autopayNumber,
          includeUser: true,
          leadChannelCode,
          pin,
          primaryPhoneNumber,
          purpose,
        })
        .then(({ user }) => set({ user, userAuthenticateSuccess: true }))
        .catch((error) => {
          set({
            userAuthenticateFailedMessage: lodashGet(
              i18n.t('errors.userAuthenticate.statusMessage'),
              toString(error.response.status),
              i18n.t('errors.userAuthenticate.defaultMessage'),
            ),
            userAuthenticateLocked:
              lodashGet(error, 'response.status') === HttpStatusCode.FORBIDDEN,
          });
        });
    },
    userCreate: (
      firstName: string,
      lastName: string,
      primaryEmailAddress: string,
      primaryPhoneNumber: string,
      pin: string,
    ) => {
      set({
        userCreateFailedMessage: null,
        userCreateSuccess: false,
        userExistsException: null,
      });

      const {
        computed: { isValidLeadChannel },
      } = useLeadChannelStore.getState();

      const { leadChannelCode } = useParameterStore.getState();

      const portalHttpRequest = useApiStore.getState().portalApi();

      const {
        computed: { attributionsForLeadChannel },
      } = useAttributionStore.getState();

      return portalHttpRequest
        .post<never, { user: User }>('/2.0/users', {
          attributions: attributionsForLeadChannel,
          firstName,
          lastName,
          ...(isValidLeadChannel && { leadChannelCode }),
          pin,
          primaryEmailAddress,
          primaryPhoneNumber,
        })
        .then(({ user }) => set({ user, userCreateSuccess: true }))
        .catch((error) => {
          const errorCode = lodashGet(
            error,
            'response.data._embedded.errors.0.errorCode',
            null,
          );

          if (errorCode in UserExistsExceptionCode) {
            set({ userExistsException: errorCode });
          } else {
            set({
              userCreateFailedMessage: lodashGet(
                i18n.t('errors.userCreate.statusMessage'),
                toString(error.response.status),
                i18n.t('errors.userCreate.defaultMessage'),
              ),
            });
          }
        });
    },
    userExists: (primaryPhoneNumber: string, primaryEmailAddress: string) => {
      set({ userExistsException: null });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get(`/2.0/users`, {
          params: {
            primaryEmailAddress,
            primaryPhoneNumber,
          },
        })
        .then(() => {})
        .catch((error) => {
          const errorCode = lodashGet(
            error,
            'response.data._embedded.errors.0.errorCode',
            null,
          );

          set({ userExistsException: errorCode });
        });
    },
    userUpdate: () => {
      const {
        computed: { userFirstName: firstName, userLastName: lastName },
      } = get();

      const portalHttpRequest = useApiStore.getState().portalApi();

      set({
        isUpdatingUser: true,
        userUpdateFailedMessage: null,
        userUpdateSuccess: false,
      });

      return portalHttpRequest
        .patch<never, User>('/2.0/users/current', {
          firstName,
          lastName,
        })
        .then((user) => set({ user, userUpdateSuccess: true }))
        .catch((error) => {
          const errorCode = lodashGet(
            error,
            'response.data._embedded.errors.0.errorCode',
            null,
          );

          if (errorCode in UserExistsExceptionCode) {
            set({ userExistsException: errorCode });
          } else {
            set({
              userUpdateFailedMessage: i18n.t(
                'errors.userUpdate.defaultMessage',
              ),
            });
          }
        })
        .finally(() => set({ isUpdatingUser: false }));
    },
  });
