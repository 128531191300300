import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { brandingStore } from './brandingStore';
import { BrandingState } from './types/BrandingState';

const useBrandingStore: DevtoolsStore<BrandingState> = create()(
  devtools<BrandingState>(
    brandingStore({
      useApiStore: lazyDependency(() => useApiStore),
    }),
    devToolOptions('brandingStore'),
  ),
);

export default useBrandingStore;
