import axios from 'axios';
import { devtools } from 'zustand/middleware';

import { config } from '../../config';
import useParameterStore from '../parameterStore/useParameterStore';
import useSignOutStore from '../signOutStore/useSignOutStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { apiStore } from './apiStore';
import { ApiState } from './types/ApiState';

const useApiStore: DevtoolsStore<ApiState> = create()(
  devtools<ApiState>(
    apiStore({
      config,
      httpClient: axios,
      useParameterStore: lazyDependency(() => useParameterStore),
      useSignOutStore: lazyDependency(() => useSignOutStore),
    }),
    devToolOptions('apiStore'),
  ),
);

export default useApiStore;
