import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { leadChannelStore } from './leadChannelStore';
import { LeadChannelState } from './types/LeadChannelState';

const useLeadChannelStore: DevtoolsStore<LeadChannelState> = create()(
  devtools<LeadChannelState>(
    leadChannelStore({
      useApiStore: lazyDependency(() => useApiStore),
    }),
    devToolOptions('leadChannelStore'),
  ),
);

export default useLeadChannelStore;
