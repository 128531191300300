import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';
import { assign, MachineConfig } from 'xstate';

import { AccountContext } from '../common/unified-workflow-context.interface';
import {
  userWantsToAddVehicleByLicensePlate,
  userWantsToAddVehicleByVin,
  userWantsToAddVehicleByYearMakeAndModel,
} from '../common/vehicle.utils';
import { ConflictError } from '../services';
import { Step } from '../steps';
import { ReviewEntity } from '../Steps/Review/Model';
import { ReviewCoApplicantEntity } from '../Steps/ReviewCoApplicant/Model';
import { VehicleInformationSource } from '../Steps/VehicleIdentity/options';

export const Signal = {
  Next: 'Next',
  Previous: 'Previous',
  ExitToSignIn: 'ExitToSignIn',
  ExitToDashboard: 'ExitToDashboard',
};

export const defaultServiceOptions = {
  actions: {
    updateContext: assign((context: AccountContext, event: any) => {
      return {
        ...context,
        ...event.data,
      };
    }),
    updateUser: assign<AccountContext, any>({
      user: (ctx, { data }) => ({
        ...ctx.user,
        ...data,
      }),
    }),
    enableVehicleReviewMode: assign<AccountContext>({
      editedVehicle: (ctx: AccountContext) => ctx.vehicle,
      isReviewVehicleMode: true,
    }),
    disableEditingVehicle: assign<AccountContext>({
      editedVehicle: undefined,
      isReviewVehicleMode: false,
    }),
    resetDuplicatedUser: assign<any>({
      userVerificationError: undefined,
      userExistsOtherErrors: undefined,
    }),
    setDuplicatedUserError: assign<AccountContext>({
      userVerificationError: (ctx: any, { data: error }: any) => {
        return error.params;
      },
    }),
    resetVehicle: assign<AccountContext, any>(
      (ctx, { data }: { data: { applicationType: ApplicationType } }) => {
        const typeChanged = data.applicationType !== ctx.user.applicationType;
        /**
         * Vehicle should be reset if it has already been found by license plate.
         * We have to restrict access to license plate search in case
         * if purchase flow was selected
         */
        const licensePlateVehicle =
          ctx.vehicle?.vehicleInformationSource ===
          VehicleInformationSource.LicensePlate;

        if (typeChanged || licensePlateVehicle) {
          return {
            vehicle: undefined,
            editedVehicle: undefined,
          };
        }
        return ctx;
      },
    ),
    resetPurchaseType: assign<AccountContext>({
      user: (ctx) => ({ ...ctx.user, purchaseType: undefined }),
    }),
    resetPrimaryGoal: assign<AccountContext>({
      user: (ctx) => ({ ...ctx.user, primaryGoal: undefined }),
    }),
  },
};

export const workflowName = 'unified-default-signup';

export const defaultConfiguration: MachineConfig<AccountContext, any, any> = {
  id: workflowName,
  initial: Step.Pillow,
  states: {
    [Step.Pillow]: {
      on: {
        [Signal.ExitToSignIn]: Step.ExitToSignIn,
        [Signal.Next]: {
          target: Step.Welcome,
          actions: assign<AccountContext>({
            invalidPhoneNumber: undefined,
          }),
        },
      },
    },
    [Step.Welcome]: {
      on: {
        [Signal.Previous]: Step.Pillow,
        [Signal.Next]: {
          target: Step.ApplicationType,
          actions: 'updateContext',
        },
      },
    },
    [Step.ApplicationType]: {
      on: {
        [Signal.Previous]: Step.Welcome,
        [Signal.Next]: [
          {
            target: Step.PrimaryGoal,
            actions: ['resetVehicle', 'resetPurchaseType', 'updateUser'],
            cond: (_, { data }) =>
              data?.applicationType === ApplicationType.REFINANCE,
          },
          {
            target: Step.PurchaseType,
            actions: ['resetVehicle', 'resetPrimaryGoal', 'updateUser'],
            cond: (_, { data }) =>
              data?.applicationType === ApplicationType.PURCHASE,
          },
          {
            target: Step.PersonalDetails,
            actions: [
              'resetVehicle',
              'resetPrimaryGoal',
              assign<AccountContext, any>({
                user: (
                  ctx,
                  { data }: { data: { applicationType: ApplicationType } },
                ) => ({
                  ...ctx.user,
                  applicationType: ApplicationType.PURCHASE,
                  purchaseType: data.applicationType,
                }),
              }),
            ],
          },
        ],
      },
    },
    [Step.PurchaseType]: {
      on: {
        [Signal.Previous]: Step.ApplicationType,
        [Signal.Next]: {
          target: Step.PersonalDetails,
          actions: 'updateContext',
        },
      },
    },
    [Step.PrimaryGoal]: {
      on: {
        [Signal.Previous]: Step.ApplicationType,
        [Signal.Next]: {
          target: Step.PersonalDetails,
          actions: 'updateContext',
        },
      },
    },
    [Step.PersonalDetails]: {
      on: {
        [Signal.Previous]: [
          {
            target: Step.PrimaryGoal,
            cond: ({ user }) =>
              user.applicationType === ApplicationType.REFINANCE,
          },
          {
            target: Step.ApplicationType,
            actions: [
              'resetPurchaseType',
              assign<AccountContext>({
                user: (ctx) => ({
                  ...ctx.user,
                  applicationType: ApplicationType.LEASE_BUYOUT,
                }),
              }),
            ],
            cond: ({ user }) =>
              user.purchaseType === ApplicationType.LEASE_BUYOUT,
          },
          {
            target: Step.PurchaseType,
          },
        ],
      },
      initial: 'Idle',
      states: {
        Idle: {
          on: {
            [Signal.Next]: {
              target: 'VerifyUser',
              actions: 'updateContext',
            },
          },
        },
        VerifyUser: {
          invoke: {
            src: 'verifyExistingUser',
            onDone: [
              {
                target: `#${workflowName}.${Step.CoApplicant}`,
                cond: ({ coApplicant }) =>
                  Boolean(coApplicant?.coApplicantAnswer),
                actions: 'resetDuplicatedUser',
              },
              {
                target: `#${workflowName}.${Step.VehicleIdentity}`,
                actions: 'resetDuplicatedUser',
              },
            ],
            onError: [
              {
                target: `#${workflowName}.${Step.CheckUserCredentials}`,
                actions: ['setDuplicatedUserError'],
                cond: (ctx, { data: error }) => error instanceof ConflictError,
              },
              {
                target: `Idle`,
                actions: assign({
                  userExistsOtherErrors: (ctx, { data }: any) => data,
                  userVerificationError: () => undefined,
                }),
              },
            ],
          },
        },
      },
      exit: assign({
        userExistsOtherErrors: () => undefined,
      }),
    },
    [Step.CheckUserCredentials]: {
      on: {
        [Signal.Previous]: {
          target: Step.PersonalDetails,
        },
        [Signal.Next]: [
          {
            target: Step.ExitReturningUserToSignIn,
            cond: ({ userVerificationError }) => !!userVerificationError?.phone,
          },
          {
            target: Step.ExitToSignIn,
          },
        ],
      },
    },
    [Step.CoApplicant]: {
      on: {
        [Signal.Next]: [
          {
            target: Step.VehicleIdentity,
            actions: 'updateContext',
          },
        ],
        [Signal.Previous]: [
          {
            target: Step.PersonalDetails,
          },
        ],
      },
    },
    [Step.VehicleIdentity]: {
      on: {
        [Signal.Next]: [
          {
            target: Step.SelectVehicleYear,
            actions: 'updateContext',
            cond: (_, { data: { editedVehicle } }) =>
              userWantsToAddVehicleByYearMakeAndModel(editedVehicle),
          },
          {
            target: Step.FindVehicleByLicensePlate,
            actions: 'updateContext',
            cond: (_, { data: { editedVehicle } }) =>
              userWantsToAddVehicleByLicensePlate(editedVehicle),
          },
          {
            target: Step.FindVehicleByVin,
            actions: 'updateContext',
            cond: (_, { data: { editedVehicle } }) =>
              userWantsToAddVehicleByVin(editedVehicle),
          },
        ],
        [Signal.Previous]: [
          {
            target: Step.Review,
            actions: 'disableEditingVehicle',
            cond: ({ isReviewVehicleMode }) => Boolean(isReviewVehicleMode),
          },
          {
            target: Step.CoApplicant,
            cond: ({ coApplicant }) => Boolean(coApplicant?.coApplicantAnswer),
          },
          {
            target: Step.PersonalDetails,
          },
        ],
      },
    },
    [Step.SelectVehicleYear]: {
      on: {
        [Signal.Next]: {
          target: Step.SelectVehicleMake,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.VehicleIdentity,
      },
    },
    [Step.SelectVehicleMake]: {
      on: {
        [Signal.Next]: {
          target: Step.SelectVehicleModel,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.SelectVehicleYear,
      },
    },
    [Step.SelectVehicleModel]: {
      on: {
        [Signal.Next]: {
          target: Step.VehicleConfirm,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.SelectVehicleMake,
      },
    },
    [Step.FindVehicleByLicensePlate]: {
      on: {
        [Signal.Next]: {
          target: Step.VehicleConfirm,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.VehicleIdentity,
      },
    },
    [Step.FindVehicleByVin]: {
      on: {
        [Signal.Next]: {
          target: Step.VehicleConfirm,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.VehicleIdentity,
      },
    },

    [Step.VehicleConfirm]: {
      entry: assign<AccountContext>({
        editedVehicle: (ctx: AccountContext) => {
          const { vehicle, editedVehicle, isReviewVehicleMode } = ctx;
          if (isReviewVehicleMode) {
            const requiredVehicleFields = ['year', 'make', 'model'];
            const hasAllRequiredFields = requiredVehicleFields.every(
              (field) => field in (editedVehicle || {}),
            );
            if (hasAllRequiredFields) {
              return editedVehicle;
            }
            return vehicle;
          }
          return editedVehicle || vehicle;
        },
      }),
      on: {
        [Signal.Next]: {
          target: Step.Review,
          actions: ['updateContext', 'disableEditingVehicle'],
        },
        [Signal.Previous]: [
          {
            target: Step.SelectVehicleModel,
            actions: 'updateContext',
            cond: ({ editedVehicle }) =>
              !!editedVehicle &&
              userWantsToAddVehicleByYearMakeAndModel(editedVehicle),
          },
          {
            target: Step.FindVehicleByVin,
            actions: 'updateContext',
            cond: ({ editedVehicle }) =>
              !!editedVehicle && userWantsToAddVehicleByVin(editedVehicle),
          },
          {
            target: Step.FindVehicleByLicensePlate,
            actions: 'updateContext',
            cond: ({ editedVehicle }) =>
              !!editedVehicle &&
              userWantsToAddVehicleByLicensePlate(editedVehicle),
          },
        ],
      },
    },
    [Step.Review]: {
      on: {
        [Signal.Next]: [
          {
            target: Step.ReviewApplicant,
            cond: (_, { edit }) => edit === ReviewEntity.Applicant,
          },
          {
            target: Step.ReviewHousing,
            cond: (_, { edit }) => edit === ReviewEntity.Housing,
          },
          {
            target: Step.VehicleIdentity,
            actions: 'enableVehicleReviewMode',
            cond: (_, { edit }) => edit === ReviewEntity.Vehicle,
          },
          {
            target: Step.ReviewCoApplicant,
            actions: 'updateContext',
            cond: ({ coApplicant }) => Boolean(coApplicant?.coApplicantAnswer),
          },
          {
            target: Step.PinVerify,
            actions: 'updateContext',
          },
        ],
        [Signal.Previous]: {
          target: Step.VehicleConfirm,
          actions: 'updateContext',
        },
      },
    },
    [Step.ReviewApplicant]: {
      on: {
        [Signal.Next]: {
          target: Step.Review,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.Review,
      },
    },
    [Step.ReviewHousing]: {
      on: {
        [Signal.Next]: {
          target: Step.Review,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.Review,
      },
    },
    [Step.ReviewCoApplicant]: {
      on: {
        [Signal.Next]: [
          {
            target: Step.ReviewCoApplicantPersonalInfo,
            cond: (_, { edit }) => edit === ReviewCoApplicantEntity.Personal,
          },
          {
            target: Step.ReviewCoApplicantHousingInfo,
            cond: (_, { edit }) => edit === ReviewCoApplicantEntity.Housing,
          },
          {
            target: Step.PinVerify,
            actions: 'updateContext',
          },
        ],
        [Signal.Previous]: Step.Review,
      },
    },
    [Step.ReviewCoApplicantPersonalInfo]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewCoApplicant,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.ReviewCoApplicant,
      },
    },
    [Step.ReviewCoApplicantHousingInfo]: {
      on: {
        [Signal.Next]: {
          target: Step.ReviewCoApplicant,
          actions: 'updateContext',
        },
        [Signal.Previous]: Step.ReviewCoApplicant,
      },
    },
    [Step.PinVerify]: {
      on: {
        [Signal.Previous]: [
          {
            target: Step.ReviewCoApplicant,
            cond: ({ coApplicant }) => Boolean(coApplicant?.coApplicantAnswer),
          },
          {
            target: Step.Review,
          },
        ],
        [Signal.Next]: {
          target: Step.ExitToOffers,
          actions: 'updateContext',
        },
        [Signal.ExitToDashboard]: Step.ExitToDashboard,
      },
    },
    [Step.ExitToSignIn]: {
      type: 'final',
    },
    [Step.ExitToOffers]: {
      type: 'final',
    },
    [Step.ExitReturningUserToSignIn]: {
      type: 'final',
    },
    [Step.ExitToDashboard]: {
      type: 'final',
    },
  } as any,
};

// NOTE: You can paste the content of this file into @see https://xstate.js.org/viz
// and uncomment this line to test the logic manually
// Machine({ ...defaultConfiguration }, { ...defaultServiceOptions });

export default defaultConfiguration;
