export const iso8601Pattern =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?(Z|[+-]\d{2}:\d{2})$/;

export const isoDateRegex =
  // eslint-disable-next-line security/detect-unsafe-regex
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,})?(Z|[+-]\d{2}:\d{2})$/;

export const phoneNumberValidator =
  /^(?:[2-9]\d{2}[2-9]\d{6}|[2-9]\d{2}-[2-9]\d{2}-\d{4})$/;

export const phoneNumberEnhancedValidator =
  /^1?(?:[2-9]\d{2}[2-9]\d{6}|[2-9]\d{2}[-. ][2-9]\d{2}[-. ]\d{4})$/;
