import { OwnershipStatus } from '@thesavingsgroup/enums/OwnershipStatus';

import { ImagePlaceholderFitContainer } from '../components/shared/imagePlaceholder/ImagePlaceholder';

export const optionsList = [
  {
    label: 'I own my home',
    largeTitle: true,
    value: OwnershipStatus.OWN,
    testId: OwnershipStatus.OWN,
    image: <ImagePlaceholderFitContainer />,
  },
  {
    label: 'I rent from a landlord',
    largeTitle: true,
    value: OwnershipStatus.RENT,
    testId: OwnershipStatus.RENT,
    image: <ImagePlaceholderFitContainer />,
  },
  {
    label: 'Other',
    largeTitle: true,
    value: OwnershipStatus.UNKNOWN,
    testId: OwnershipStatus.UNKNOWN,
    image: <ImagePlaceholderFitContainer />,
  },
];
export const unifiedOptionsList = [
  {
    label: 'Parents',
    largeTitle: true,
    value: OwnershipStatus.RELATIVE_OWNED,
    testId: OwnershipStatus.RELATIVE_OWNED,
    image: <ImagePlaceholderFitContainer />,
  },
  {
    label: 'Own',
    largeTitle: true,
    value: OwnershipStatus.OWN,
    testId: OwnershipStatus.OWN,
    image: <ImagePlaceholderFitContainer />,
  },
  {
    label: 'Rent',
    largeTitle: true,
    value: OwnershipStatus.RENT,
    testId: OwnershipStatus.RENT,
    image: <ImagePlaceholderFitContainer />,
  },
];
