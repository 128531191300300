import { isEmpty, toString } from 'lodash';

import { UserIncomesDto } from '../dto/UserIncomesDto';
import { ApplicantState } from '../types/ApplicantState';
import { NO, YES, YesOrNo } from '../types/YesOrNo';
import { incomeFrequencyFromAPI } from './incomeFrequencyFromAPI';
import { isEmployedType } from './isEmployedType';
import { reduceIncomeMonthsAt } from './reduceIncomeMonthsAt';

const isCurrentlyEmployed = (
  employmentType?: string | null,
): YesOrNo | null => {
  if (!employmentType) {
    return null;
  }

  return isEmployedType(employmentType) ? YES : NO;
};

export const toApplicantStateFromIncomes = (
  income: UserIncomesDto,
): Partial<ApplicantState> => {
  const state: Partial<ApplicantState> = {};

  if (isEmpty(income)) {
    return state;
  }

  if (income.additional) {
    state.additionalIncomeAmount = income.additional.amount?.toString() || null;
    state.additionalIncomeFrequency = incomeFrequencyFromAPI(
      income.additional.incomeFrequency,
    );
    state.additionalIncomeId = income.additional.id || null;
    state.additionalIncomeType = income.additional.additionalIncomeType || null;

    state.hasAdditionalIncome = income.additional.amount ? YES : NO;
  }

  if (income.current) {
    state.currentIncomeEmployerName = income.current.employerName || null;
    state.currentIncomeEmploymentType = income.current.employmentType || null;
    state.currentIncomeGrossAnnual = toString(income.current.amount) || null;
    state.currentIncomeId = income.current.id || null;
    state.currentIncomeJobTitle = income.current.jobTitle || null;

    state.currentIncomeMonthsAt = toString(
      reduceIncomeMonthsAt(income.current.monthsAt),
    );

    state.isCurrentlyEmployed = isCurrentlyEmployed(
      income.current.employmentType,
    );
  }

  if (income.previous) {
    state.previousIncomeEmployerName = income.previous.employerName || null;
    state.previousIncomeEmploymentType = income.previous.employmentType || null;
    state.previousIncomeGrossAnnual = income.previous.amount.toString() || null;
    state.previousIncomeId = income.previous.id || null;
    state.previousIncomeJobTitle = income.previous.jobTitle || null;
  }

  return state;
};
