import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';

import leaseBuyoutIcon from '../assets/Lease-Buy-out.svg';
import purchaseIcon from '../assets/Purchase-A.svg';
import refinanceIcon from '../assets/Refinance.svg';
import { componentTypes } from '../constants/componentTypes';
import type { FormFieldsModelT } from './formFieldsModelT';

const typeOptionsList = [
  {
    value: ApplicationType.REFINANCE,
    label: 'Refinance',
    image: refinanceIcon,
  },
  {
    value: ApplicationType.PURCHASE,
    label: 'Purchase',
    image: purchaseIcon,
  },
  {
    value: ApplicationType.LEASE_BUYOUT,
    label: 'Lease Buyout',
    image: leaseBuyoutIcon,
  },
];

export const fields: FormFieldsModelT = {
  applicationType: {
    name: 'applicationType',
    component: componentTypes.RADIO,
    options: typeOptionsList,
    defaultValue: typeOptionsList[0].value,
    largeTitle: true,
  },
  primaryGoal: {
    name: 'primaryGoal',
    component: componentTypes.RADIO,
    largeTitle: true,
  },
};
