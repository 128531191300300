import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';
import { merge, set } from 'lodash';

import { populateFields } from '../../../../../forms/populateFields';
import { unifiedCommonFields } from '../../../../../utils/helpers/income/unified';
import { isOlderThan } from '../../../../../utils/pipes/date';
import { filterCurrentIncomeNonRequiredFields } from '../../../../common/utils/applicant.utils';
import { Signal } from '../../XState/Model';
import BaseModel from '../common/BaseModel';

const model = (send: any, { context }: any) => {
  const { coApplicant = {} } = context || {};
  const { incomes = {} } = coApplicant;
  const fieldsConfig = unifiedCommonFields('current');

  return merge({}, BaseModel, {
    template: {
      header: {
        onBack: () => {
          send(Signal.Previous);
        },
      },
    },
    form: {
      fields: merge({}, fieldsConfig, populateFields(fieldsConfig, incomes)),
      actions: {
        primary: {
          label: 'Continue',
          testId: 'continue',
          handler: (incomes: any) => {
            const previousRequired = !isOlderThan(
              incomes.current.startDate,
              12,
            );
            const filteredIncomeValuesFields =
              filterCurrentIncomeNonRequiredFields(incomes, fieldsConfig);
            const mergedIncomes = merge(
              {},
              coApplicant.incomes,
              filteredIncomeValuesFields,
            );
            set(mergedIncomes, 'current.incomeFrequency', PayFrequency.YEARLY);
            send(Signal.Next, {
              data: {
                incomes: {
                  ...mergedIncomes,
                  previous: previousRequired
                    ? coApplicant.incomes.previous || {}
                    : null,
                },
              },
            });
          },
        },
      },
    },
    info: {
      title: 'Co-applicant employment details',
      subtitle: 'Help',
      content: [
        {
          copy: [
            'At a minimum, we need to know where they have worked for the past year.',
            "Haven't they been at their current job for that long? Do not fret, you'll be given the chance to add additional employment information in order to satisfy this requirement.",
          ],
        },
      ],
    },
    headerBlock: {
      title: 'Tell us more about their employment',
      subtitle: ['Co-Applicant', '60% complete'],
      progressBar: {
        progress: 60,
        withContainer: true,
      },
    },
  });
};

export default model;
