import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';

import { getAnnualPaymentCountByPayFrequency } from './getAnnualPaymentCountByPayFrequency';

export const convertAmountToNewPayFrequency = (
  sourceFrequency: PayFrequency,
  targetFrequency: PayFrequency,
  amount?: number,
) => {
  if (sourceFrequency === targetFrequency) {
    return amount;
  }

  const annualPaymentCountForSourceFrequency =
    getAnnualPaymentCountByPayFrequency(sourceFrequency);
  const annualTotalAmountForSourceFrequency =
    (amount || 0) * annualPaymentCountForSourceFrequency;
  const annualPaymentCountForTargetFrequency =
    getAnnualPaymentCountByPayFrequency(targetFrequency);
  return +(
    annualTotalAmountForSourceFrequency / annualPaymentCountForTargetFrequency
  ).toFixed();
};
