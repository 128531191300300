import { devtools } from 'zustand/middleware';

import { create, devToolOptions } from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { lazyStore } from './lazyStore';
import { LazyState } from './types/LazyState';

const useLazyStore: DevtoolsStore<LazyState> = create()(
  devtools<LazyState>(lazyStore, devToolOptions('lazyStore')),
);

export default useLazyStore;
