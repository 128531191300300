import { UploadItemType } from '@thesavingsgroup/enums/UploadItemType';

import { UploadItemMetadata } from '../types/UploadItemMetadata';

export const uploadItemTypeMetadata: Record<
  UploadItemType,
  UploadItemMetadata
> = {
  [UploadItemType.APPLICANT_FRONT_OF_LICENSE]: {
    assetDescription: 'APEX Asset - APPLICANT - DRIVERS_LICENSE_FRONT',
    documentSubType: 'DRIVERS_LICENSE_ONE',
    documentSubtypeName: "Driver's License 1",
    uploadIdentifier: 'Front of ID',
  },
  [UploadItemType.APPLICANT_BACK_OF_LICENSE]: {
    assetDescription: 'APEX Asset - APPLICANT - DRIVERS_LICENSE_BACK',
    documentSubType: 'DRIVERS_LICENSE_TWO',
    documentSubtypeName: 'Other',
    uploadIdentifier: 'Back of ID',
  },
  [UploadItemType.CO_APPLICANT_FRONT_OF_LICENSE]: {
    assetDescription: 'APEX Asset - CO_APPLICANT - DRIVERS_LICENSE_FRONT',
    documentSubType: 'DRIVERS_LICENSE_ONE',
    documentSubtypeName: "Driver's License 2",
    uploadIdentifier: 'Co-applicant Front of ID',
  },
  [UploadItemType.CO_APPLICANT_BACK_OF_LICENSE]: {
    assetDescription: 'APEX Asset - CO_APPLICANT - DRIVERS_LICENSE_BACK',
    documentSubType: 'DRIVERS_LICENSE_TWO',
    documentSubtypeName: 'Other',
    uploadIdentifier: 'Co-applicant Back of ID',
  },
  [UploadItemType.PROOF_OF_INSURANCE]: {
    assetDescription: 'APEX Asset - APPLICANT - PROOF_OF_INSURANCE',
    documentSubType: 'PROOF_OF_INSURANCE',
    documentSubtypeName: 'Proof of Insurance',
    uploadIdentifier: 'Proof of Insurance',
  },
  [UploadItemType.REGISTRATION_CARD]: {
    assetDescription: 'APEX Asset - APPLICANT - VEHICLE_REGISTRATION',
    documentSubType: 'COPY_OF_VEHICLE_REGISTRATION',
    documentSubtypeName: 'Copy of Vehicle Registration',
    uploadIdentifier: 'Registration Card',
  },
  [UploadItemType.PHOTO_OF_ODOMETER]: {
    assetDescription: 'APEX Asset - APPLICANT - PHOTO_OF_ODOMETER',
    documentSubType: 'ODOMETER_READING',
    documentSubtypeName: 'Odometer Reading',
    uploadIdentifier: 'Photo of Odometer',
  },
  [UploadItemType.ADDITIONAL_DOCUMENT]: {
    get assetDescription() {
      return `APEX Asset - APPLICANT - ADDITIONAL_DOCUMENT_${Date.now()}`;
    },
    documentSubType: 'OTHER_PROVIDED_DOCUMENT',
    documentSubtypeName: 'Other',
    uploadIdentifier: 'Additional Documents',
  },
};
