import { EmploymentType } from '@thesavingsgroup/enums/EmploymentType';
import i18n from 'i18next';

export const employmentTypeFormat: Record<EmploymentType, string> = {
  [EmploymentType.ACTIVE_MILITARY]: i18n.t('employmentType.military'),
  [EmploymentType.CONTRACTOR]: '',
  [EmploymentType.DISABILITY]: i18n.t('employmentType.disability'),
  [EmploymentType.FULL_TIME]: i18n.t('employmentType.fullTime'),
  [EmploymentType.HOMEMAKER]: i18n.t('employmentType.homemaker'),
  [EmploymentType.OTHER]: i18n.t('employmentType.other'),
  [EmploymentType.PART_TIME]: i18n.t('employmentType.partTime'),
  [EmploymentType.RETIRED]: i18n.t('employmentType.retired'),
  [EmploymentType.SELF_EMPLOYED]: i18n.t('employmentType.selfEmployed'),
  [EmploymentType.STUDENT]: i18n.t('employmentType.student'),
  [EmploymentType.UN_EMPLOYED]: '',
};
