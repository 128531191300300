export const DEFAULT_BRAND = 'AUTOPAY';
export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_SOURCE = 'LOS';
export const DEFAULT_VEHICLE_INFORMATION_PAYLOAD_VERSION = '1.0.0';
export const MAXIMUM_MAKE_LENGTH = 255;
export const MAXIMUM_MODEL_LENGTH = 255;
export const MAXIMUM_PAYOFF_AMOUNT = 999_999;
export const MAXIMUM_PER_PAGE = 100;
export const MAXIMUM_PURCHASE_PRICE = 999_999;
export const MAXIMUM_TRIM_LENGTH = 255;
export const MAXIMUM_VEHICLE_YEAR = new Date().getFullYear() + 1;
export const MAXIMUM_VIN_LENGTH = 17;
export const METADATA_SCHEMA_VERSION = '1.4.0';
export const MILEAGE_PER_MONTH = 1000;
export const MINIMUM_VEHICLE_YEAR = 1913;
export const MINIMUM_VIN_LENGTH = 11;
export const ONE_MONTH_IN_DAYS = 30;
export const ONE_YEAR_AND_SIX_MONTHS_IN_DAYS = 545; // 365 + 180
export const ONE_YEAR_IN_DAYS = 365;
export const ONE_YEAR_IN_MONTHS = 12;
export const SIX_MONTHS_IN_DAYS = 180;
export const THREE_MONTHS_IN_DAYS = 90;
export const TWO_MONTHS_IN_DAYS = 60;
export const TWO_YEARS_IN_DAYS = 730;
export const TWO_YEARS_IN_MONTHS = 24;
