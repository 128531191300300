import { has, isEmpty, omitBy } from 'lodash';
import { StateCreator } from 'zustand';

import { migrateUniqueIdentifier } from './functions/migrateUniqueIdentifier';
import { AttributionState } from './types/AttributionState';
import { AttributionStateDependencies } from './types/AttributionStateDependencies';

export const keysToPersist = ['attributions', 'leadChannelCode'];

export const attributionStore =
  ({ config }: AttributionStateDependencies): StateCreator<AttributionState> =>
  (set, get) => ({
    attributions: {},

    // this leadChannelCode is unrelated to the one found in the parameterStore.
    // its primary use here is to handle the attribution data based on whether a leadChannelCode is present in the URL.
    // whereas the parameterStore's leadChannelCode serves other purposes like tracking the source of the lead and is explicit
    // in whether it is present or not.
    leadChannelCode: config.defaultLeadChannelCode,

    computed: {
      get attributionsForLeadChannel(): Record<string, unknown> {
        const { attributions, leadChannelCode } = get();

        if (!leadChannelCode || !has(attributions, leadChannelCode)) {
          return {};
        }

        return attributions[leadChannelCode];
      },
    },

    setFromURLSearchParams(searchParams: URLSearchParams): void {
      const entries = Object.fromEntries(searchParams.entries());

      if (isEmpty(entries)) {
        return;
      }

      if (
        has(entries, 'leadChannelCode') &&
        !isEmpty(entries.leadChannelCode)
      ) {
        set({ leadChannelCode: entries.leadChannelCode });
      }

      const { attributions, leadChannelCode } = get();

      if (has(attributions, leadChannelCode)) {
        const currentParams = attributions[leadChannelCode];

        attributions[leadChannelCode] = migrateUniqueIdentifier(
          omitBy({ ...currentParams, ...entries, leadChannelCode }, isEmpty),
        );
      } else {
        attributions[leadChannelCode] = migrateUniqueIdentifier(
          omitBy({ ...entries, leadChannelCode }, isEmpty),
        );
      }

      set({ attributions });
    },
  });
