import { useEffect, useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { CircularProgressFullScreen } from '../components/shared_new/CircularProgressFullScreen/CircularProgressFullScreen';
import useParameterStore from '../store/parameterStore/useParameterStore';
import useRouteStore from '../store/routeStore/useRouteStore';
import useSignOutStore from '../store/signOutStore/useSignOutStore';
import useUserStore from '../store/userStore/useUserStore';

const SessionGuard = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const { token } = useParameterStore.getState();

  const { user, userCreateSuccess, fetchCurrentUser, fetchCurrentUserFailed } =
    useUserStore();

  const { isSigningOut } = useSignOutStore.getState();

  const {
    authRedirectPath,
    isAuthenticatedRoute,
    setAuthRedirectPath,
    computed: { nonAuthRedirectPath },
  } = useRouteStore();

  const [fetchingUserSession, setFetchingUserSession] = useState(true);

  const authenticatedRoute = useMemo(() => {
    return isAuthenticatedRoute(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    // This is used to redirect the user back to the route they were trying to reach.
    // store the current path of the authenticated route for redirection after sign-in or sign-up.
    if (authenticatedRoute) {
      setAuthRedirectPath(location.pathname);
    }
  }, [authenticatedRoute]);

  useEffect(() => {
    // rehydrate parameter store for partialization
    // based on session status
    useParameterStore.persist.rehydrate();

    if (user || fetchCurrentUserFailed) {
      setFetchingUserSession(false);
      return;
    }

    fetchCurrentUser();
  }, [user, fetchCurrentUserFailed]);

  /**
   * while fetching the current user, display a spinner
   */
  if (fetchingUserSession) {
    return <CircularProgressFullScreen />;
  }

  /**
   * local environment redirect bypass
   * - allows routes to be reached regardless of user & token state
   */
  // if (import.meta.env.VITE_APP_ENV === 'local') {
  //   return children;
  // }

  /**
   * redirect handling (sign-out)
   * where:
   * - the fetched user is not defined / user signed out
   * - token is available in local storage
   *
   * where:
   * - user signed out / user was programatically signed out
   */
  if ((!user && token) || isSigningOut) {
    return <Navigate to="/sign-out" />;
  }

  /**
   * redirect handling (sign-in)
   * where:
   * - the fetched user is not defined / user signed out
   * - token is not available in local storage
   * - user is attempting to reach an authenticated route
   */
  if (!user && !token && authenticatedRoute) {
    return <Navigate to={nonAuthRedirectPath} />;
  }

  /**
   * redirect handling (dashboard)
   * where:
   * - the fetched user is defined / user is signed in
   * - token is available in local storage
   * - user is attempting to reach a non-authenticated route
   * - user was not just created (to avoid redirect loop)
   */
  if (user && token && !authenticatedRoute && !userCreateSuccess) {
    return <Navigate to={authRedirectPath} />;
  }

  // valid route requested, load the child component
  return children;
};

export default SessionGuard;
