import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';
import { merge } from 'lodash';
import { useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FormProvider, useForm } from 'react-hook-form';

import { UserVehicle } from '../../../../../common/interfaces/vehicle/vehicle.interface';
import { PillowForm } from '../../../../../components/PillowForm';
import { useAPI } from '../../../../../hooks/useAPI/useAPI';
import { Paragraph } from '../common/styles/styles';

const Controller = (props: any) => {
  const { context, send, presModel } = props;
  const { currentVehicle }: { currentVehicle: UserVehicle } = context;
  const api = useAPI();
  const methods = useForm();

  const saveOffer = useAsyncCallback(() => {
    return api
      .put(`/vehicles/${currentVehicle.id}/application/status`, {
        id: currentVehicle.checklightSession.id,
        status: ChecklightSessionStatus.SELECTED_OFFER,
      })
      .then(() => {
        send('Next');
      });
  });
  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel },
        {
          presModel: {
            form: {
              actions: {
                primary: {
                  handler: methods.handleSubmit(saveOffer.execute),
                  isDisabled: saveOffer.loading,
                },
              },
              globalErrors: saveOffer.error,
            },
          },
        },
      ),
    [presModel, methods, saveOffer],
  );

  const beforeFormContent = (
    <Paragraph>
      Let&#39;s quickly review your information for correctness and see if we
      can provide anything else to ensure you qualify to receive the best offer
      possible.
    </Paragraph>
  );

  return (
    <FormProvider {...methods}>
      <PillowForm {...enhancedProps} beforeFormContent={beforeFormContent} />
    </FormProvider>
  );
};

Controller.displayName = 'Pillow.Controller';
export default Controller;
