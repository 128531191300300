import { CoapplicantDto } from '../dto/CoapplicantDto';
import { CoapplicantState } from '../types/CoapplicantState';
import { toCoapplicantIncomesDto } from './toCoapplicantIncomesDto';
import { toCoapplicantResidencesDto } from './toCoapplicantResidencesDto';

export const toCoapplicantDto = (state: CoapplicantState): CoapplicantDto => {
  const coapplicantDto: CoapplicantDto = {
    firstName: state.coapplicantFirstName,
    income: toCoapplicantIncomesDto(state),

    ...(state.coapplicantId && { id: state.coapplicantId }),

    lastName: state.coapplicantLastName,
    primaryEmailAddress: state.coapplicantPrimaryEmailAddress,
    primaryPhoneNumber: state.coapplicantPrimaryPhoneNumber,
    relationship: state.coapplicantRelationship,
    residence: toCoapplicantResidencesDto(state),
  };

  return coapplicantDto;
};
