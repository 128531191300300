import { config } from '../../../config';
import { HttpClientInstance } from '../../apiStore/types/HttpClientInstance';
import { LoanDocumentType } from '../types/LoanDocumentType';
import { LoanSignedUrlResponseType } from '../types/LoanSignedUrlResponseType';
import { extractPath } from './extractPath';
import { reportUploadError } from './reportUploadError';

export type UploadDocumentReturnType = {
  document: LoanDocumentType;
  path?: string | undefined;
};

export const uploadDocument =
  (document: LoanDocumentType, httpClient: HttpClientInstance) =>
  (response: LoanSignedUrlResponseType): Promise<UploadDocumentReturnType> =>
    httpClient
      .put<never, any>(response.url, document.file, {
        headers: {
          'Content-Type': document.file.type,
        },
        ...config.loanDocuments.uploadConfig,
      })
      .then(() => ({ document, path: extractPath(response.url) }))
      .catch((error: any) => {
        reportUploadError(document)(error);
        return { document, path: '' };
      });
