// prefer HttpStatusCode over @nestjs/common because these are used in the client
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';

import { HttpError } from './HttpError';

export class NotImplementedError extends HttpError {
  constructor(message = 'Not Implemented') {
    super(message);
    this.statusCode = HttpStatusCode.NOT_IMPLEMENTED;
    this.publicErrorMessage = message;
  }
}
