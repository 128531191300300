import { AuthType } from '@thesavingsgroup/auth/AuthType';
import { DeliveryMechanism } from '@thesavingsgroup/auth/DeliveryMechanism';
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import { RateSelectedErrors } from '@thesavingsgroup/enums/RateSelectedErrors';
import { get, merge } from 'lodash';
import { Fragment, useMemo } from 'react';
import { useAsync, useAsyncCallback } from 'react-async-hook';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LeadChannel } from '../../common/interfaces/branding/leadChannel';
import { PillowForm } from '../../components/PillowForm';
import { urlParamsError } from '../../constants/urlParamsError';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../hooks/useWorkflowPathname/useWorkflowPathname';
import { SearchParamKey } from '../../services/urlParams/urlParams';
import { urlParamHook } from '../../services/urlParams/urlParamsService';
import { mapErrorsToFormFields } from '../SignIn/Controller';
import model from './Model';
import { LeadChannelLogoContainer } from './styles';

const Controller = () => {
  const navigate = useNavigate();
  const rootPath = useWorkflowPathname();

  const { savedParam: phoneNumber } = urlParamHook(SearchParamKey.phoneNumber);

  const { savedParam: errorMessage } = urlParamHook<RateSelectedErrors>(
    SearchParamKey.error,
  );
  const { savedParam: leadChannelCode } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );

  const leadChannelData = useAsync(
    () =>
      api.get<any, LeadChannel>(
        `/ui-configuration/lead-channel/${leadChannelCode}`,
      ),
    [leadChannelCode],
  );

  const methods = useForm();

  const api = useAPI();

  const onSubmit = useAsyncCallback(
    async ({ primaryPhoneNumber }: { primaryPhoneNumber: string }) => {
      try {
        await api.post('pin/deliver', {
          purpose: AuthType.ACCOUNT_SIGN_IN_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: primaryPhoneNumber,
        });
        navigate(`/${rootPath}/sign-in/verify/pin`, {
          state: { primaryPhoneNumber },
        });
      } catch (e: any) {
        if (
          e.find((error: any) => error.statusCode === HttpStatusCode.NOT_FOUND)
        ) {
          navigate(`/${rootPath}/sign-up/`, {
            state: { invalidPhoneNumber: true },
          });
        }
        throw mapErrorsToFormFields(e);
      }
    },
  );

  const headerTitle = useMemo(() => {
    const leadChannelDisplayLabel = get(leadChannelData, 'result.displayLabel');

    return leadChannelDisplayLabel
      ? `Welcome back,\nfrom ${leadChannelDisplayLabel}!`
      : 'Welcome back!';
  }, [leadChannelData]);

  const beforeHeaderContent = useMemo(
    () =>
      leadChannelData?.result ? (
        <Fragment>
          <LeadChannelLogoContainer
            src={leadChannelData?.result.primaryLogo?.url}
          />
        </Fragment>
      ) : null,
    [leadChannelData],
  );

  const enhancedProps = useMemo(
    () =>
      merge(
        {},
        { presModel: model },
        {
          presModel: {
            headerBlock: {
              title: headerTitle,
              paragraph:
                'Congratulations! You have been approved. Log in to continue the application process.',
            },
            form: {
              globalErrors: [urlParamsError[errorMessage]],
              actions: {
                primary: {
                  handler: onSubmit.execute,
                  isDisabled: onSubmit.loading,
                },
              },
              fields: {
                primaryPhoneNumber: {
                  disabled: onSubmit.loading,
                  value: phoneNumber,
                  ...(onSubmit.loading
                    ? {
                        hint: 'Delivering verification code...',
                      }
                    : {}),
                },
              },
              ...onSubmit.error,
            },
          },
          beforeHeaderContent,
        },
      ),
    [headerTitle, beforeHeaderContent, onSubmit, phoneNumber, errorMessage],
  );

  return <PillowForm {...enhancedProps} methods={methods} />;
};

Controller.displayName = 'RateUpdate.Controller';
export default Controller;
