import { employedTypes } from '@thesavingsgroup/constants/employedTypes';
import { unemployedTypes } from '@thesavingsgroup/constants/unemployedTypes';
import { EmploymentType } from '@thesavingsgroup/enums/EmploymentType';
import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import i18n from 'i18next';
import { isString } from 'lodash';
import { StateCreator } from 'zustand';

import { DEFAULT_AUTH_PATH } from '../routeStore/constants/authPath';
import { additionalIncomeDurationFormat } from './constants/additionalIncomeDurationFormat';
import { additionalIncomeTypeFormat } from './constants/additionalIncomeTypeFormat';
import { employmentTypeFormat } from './constants/employmentTypeFormat';
import { HALF_YEAR_IN_MONTHS_AS_STRING } from './constants/halfYear';
import { ONE_YEAR_IN_MONTHS_AS_STRING } from './constants/oneYear';
import { residenceTypeFormat } from './constants/residenceTypeFormat';
import { TWO_YEARS_IN_MONTHS_AS_STRING } from './constants/twoYears';
import { ApplicantDto } from './dto/ApplicantDto';
import { UserIncomesDto } from './dto/UserIncomesDto';
import { UserResidencesDto } from './dto/UserResidencesDto';
import { IncomeFrequencyEnum } from './enums/IncomeFrequencyEnum';
import { IncomeTypeEnum } from './enums/IncomeTypeEnum';
import { ResidenceTypeEnum } from './enums/ResidenceTypeEnum';
import { toApplicantState } from './functions/toApplicantState';
import { toApplicantStateFromIncomes } from './functions/toApplicantStateFromIncomes';
import { toApplicantStateFromResidences } from './functions/toApplicantStateFromResidences';
import { toUserIncomesDto } from './functions/toUserIncomesDto';
import { toUserResidencesDto } from './functions/toUserResidencesDto';
import { ApplicantState } from './types/ApplicantState';
import { ApplicantStateDependencies } from './types/ApplicantStateDependencies';
import { NO, YES } from './types/YesOrNo';
import { YesOrNo } from './types/YesOrNo';

export const keysToPersist: string[] = [
  'addCoapplicant',
  'currentIncomeId',
  'currentResidenceId',
  'hasAdditionalIncome',
  'isCurrentlyEmployed',
  'previousIncomeId',
  'previousResidenceId',
];

export const currentIncomeKeys = [
  'currentIncomeEmployerName',
  'currentIncomeEmploymentType',
  'currentIncomeGrossAnnual',
  'currentIncomeJobTitle',
  'currentIncomeMonthsAt',
];

export const currentResidenceKeys = [
  'currentResidenceCity',
  'currentResidenceLineOne',
  'currentResidenceLineTwo',
  'currentResidenceMonthlyPaymentAmount',
  'currentResidenceMonthsAt',
  'currentResidencePostalCode',
  'currentResidenceState',
  'currentResidenceType',
];

export const previousIncomeKeys = [
  'previousIncomeEmployerName',
  'previousIncomeEmploymentType',
  'previousIncomeGrossAnnual',
  'previousIncomeJobTitle',
];

export const previousResidenceKeys = [
  'previousResidenceCity',
  'previousResidenceLineOne',
  'previousResidenceLineTwo',
  'previousResidencePostalCode',
  'previousResidenceState',
];

export const applicantStore =
  ({
    resetStore,
    useApiStore,
    useCoapplicantStore,
    useDashboardStore,
    useWorkflowStore,
  }: ApplicantStateDependencies): StateCreator<ApplicantState> =>
  (set, get) => ({
    addCoapplicant: null,

    additionalIncomeAmount: null,
    additionalIncomeFrequency: null,
    additionalIncomeId: null,
    additionalIncomeType: null,

    currentIncomeEmployerName: null,
    currentIncomeEmploymentType: null,
    currentIncomeGrossAnnual: null,
    currentIncomeId: null,
    currentIncomeJobTitle: null,
    currentIncomeMonthsAt: null,

    currentResidenceCity: null,
    currentResidenceId: null,
    currentResidenceLineOne: null,
    currentResidenceLineTwo: null,
    currentResidenceMonthlyPaymentAmount: null,
    currentResidenceMonthsAt: null,
    currentResidencePostalCode: null,
    currentResidenceState: null,
    currentResidenceType: null,

    dateOfBirth: null,
    disableCoapplicantFailedMessage: null,

    hasAdditionalIncome: null,
    hasLoadedApplicant: false,

    isCurrentlyEmployed: null,
    isDisablingCoapplicant: false,
    isLoadingApplicant: false,
    isSubmittingApplication: false,
    isSyncingIncome: false,
    isSyncingResidence: false,

    previousIncomeEmployerName: null,
    previousIncomeEmploymentType: null,
    previousIncomeGrossAnnual: null,
    previousIncomeId: null,
    previousIncomeJobTitle: null,

    previousResidenceCity: null,
    previousResidenceId: null,
    previousResidenceLineOne: null,
    previousResidenceLineTwo: null,
    previousResidencePostalCode: null,
    previousResidenceState: null,

    syncingIncomeFailedMessage: null,
    syncingResidenceFailedMessage: null,

    submitApplicationFailedMessage: null,

    computed: {
      get additionalIncomeAmount() {
        const { additionalIncomeAmount } = get();

        if (!additionalIncomeAmount) {
          return '-';
        }

        const localeAmount = parseInt(additionalIncomeAmount).toLocaleString();

        return `$${localeAmount}`;
      },
      get additionalIncomeFrequency() {
        const { additionalIncomeFrequency } = get();

        if (
          additionalIncomeFrequency &&
          additionalIncomeDurationFormat[additionalIncomeFrequency]
        ) {
          return additionalIncomeDurationFormat[additionalIncomeFrequency];
        }

        return '-';
      },
      get additionalIncomeStatus() {
        const { hasAdditionalIncome } = get();

        return hasAdditionalIncome === YES
          ? i18n.t('incomeStatus.hasAdditionalIncome')
          : i18n.t('incomeStatus.noAdditionalIncome');
      },
      get additionalIncomeType() {
        const { additionalIncomeType } = get();

        if (
          additionalIncomeType &&
          additionalIncomeTypeFormat[additionalIncomeType]
        ) {
          return additionalIncomeTypeFormat[additionalIncomeType];
        }

        return '-';
      },
      get currentIncomeAmount() {
        const { currentIncomeGrossAnnual } = get();

        if (!currentIncomeGrossAnnual) {
          return '-';
        }

        const localeAmount = parseInt(
          currentIncomeGrossAnnual,
        ).toLocaleString();

        return `$${localeAmount}`;
      },
      get currentIncomeDuration() {
        const { currentIncomeMonthsAt } = get();

        if (!currentIncomeMonthsAt) {
          return '-';
        }

        return currentIncomeMonthsAt === ONE_YEAR_IN_MONTHS_AS_STRING
          ? i18n.t('employmentDuration.moreThan1Year')
          : i18n.t('employmentDuration.lessThan1Year');
      },
      get currentIncomeStatus() {
        const { isCurrentlyEmployed } = get();

        if (!isCurrentlyEmployed) {
          return '-';
        }

        return isCurrentlyEmployed === YES
          ? i18n.t('employmentStatus.employed')
          : i18n.t('employmentStatus.unemployed');
      },
      get currentIncomeType() {
        const { currentIncomeEmploymentType } = get();

        if (
          currentIncomeEmploymentType &&
          employmentTypeFormat[currentIncomeEmploymentType]
        ) {
          return employmentTypeFormat[currentIncomeEmploymentType];
        }

        return '-';
      },
      get currentResidenceAddress() {
        const {
          currentResidenceCity,
          currentResidenceLineOne,
          currentResidenceLineTwo,
          currentResidencePostalCode,
          currentResidenceState,
        } = get();

        if (
          !currentResidenceLineOne ||
          !currentResidenceCity ||
          !currentResidenceState ||
          !currentResidencePostalCode
        ) {
          return '-';
        }

        let addressFormatted = '';

        if (currentResidenceLineOne) {
          addressFormatted += currentResidenceLineOne;
        }

        if (currentResidenceLineTwo) {
          addressFormatted += `, ${currentResidenceLineTwo}`;
        }

        if (currentResidenceCity) {
          addressFormatted += `, ${currentResidenceCity}`;
        }

        if (currentResidenceState) {
          addressFormatted += `, ${currentResidenceState}`;
        }

        if (currentResidencePostalCode) {
          addressFormatted += `, ${currentResidencePostalCode}`;
        }

        return addressFormatted;
      },
      get currentResidenceAmount() {
        const { currentResidenceMonthlyPaymentAmount } = get();

        if (!currentResidenceMonthlyPaymentAmount) {
          return '-';
        }

        const localeAmount = parseInt(
          currentResidenceMonthlyPaymentAmount,
        ).toLocaleString();

        return i18n.t('currency.aMonth', { amount: localeAmount });
      },
      get currentResidenceCityAsString() {
        const { currentResidenceCity } = get();

        return isNonEmptyString(currentResidenceCity)
          ? currentResidenceCity
          : '';
      },
      get currentResidenceDuration() {
        const { currentResidenceMonthsAt } = get();

        if (!currentResidenceMonthsAt) {
          return '-';
        }

        return currentResidenceMonthsAt === TWO_YEARS_IN_MONTHS_AS_STRING
          ? i18n.t('residenceDuration.moreThan2Years')
          : i18n.t('residenceDuration.lessThan2Years');
      },
      get currentResidenceLineOneAsString() {
        const { currentResidenceLineOne } = get();

        return isNonEmptyString(currentResidenceLineOne)
          ? currentResidenceLineOne
          : '';
      },
      get currentResidenceLineTwoAsString() {
        const { currentResidenceLineTwo } = get();

        return isNonEmptyString(currentResidenceLineTwo)
          ? currentResidenceLineTwo
          : '';
      },
      get currentResidencePostalCodeAsString() {
        const { currentResidencePostalCode } = get();

        return isNonEmptyString(currentResidencePostalCode)
          ? currentResidencePostalCode
          : '';
      },
      get currentResidenceStateAsString() {
        const { currentResidenceState } = get();

        return isNonEmptyString(currentResidenceState)
          ? currentResidenceState
          : '';
      },
      get currentResidenceType() {
        const { currentResidenceType } = get();

        if (currentResidenceType && residenceTypeFormat[currentResidenceType]) {
          return residenceTypeFormat[currentResidenceType];
        }

        return '-';
      },
      get employedGrossAnnualIncome() {
        const { currentIncomeEmploymentType, currentIncomeGrossAnnual } = get();

        if (
          currentIncomeEmploymentType &&
          employedTypes.includes(currentIncomeEmploymentType) &&
          currentIncomeGrossAnnual
        ) {
          return currentIncomeGrossAnnual;
        }

        return null;
      },
      get isAdditionalIncomePersisted() {
        const { additionalIncomeId } = get();
        return isString(additionalIncomeId) && additionalIncomeId.length !== 0;
      },
      get isCurrentIncomeLessThan1Year() {
        return get().currentIncomeMonthsAt === HALF_YEAR_IN_MONTHS_AS_STRING;
      },
      get isCurrentIncomeMoreThan1Year() {
        return get().currentIncomeMonthsAt === ONE_YEAR_IN_MONTHS_AS_STRING;
      },
      get isCurrentIncomePersisted() {
        const { currentIncomeId } = get();
        return isString(currentIncomeId) && currentIncomeId.length !== 0;
      },
      get isCurrentResidenceLessThan2Years() {
        return get().currentResidenceMonthsAt === ONE_YEAR_IN_MONTHS_AS_STRING;
      },
      get isCurrentResidenceMoreThan2Years() {
        return get().currentResidenceMonthsAt === TWO_YEARS_IN_MONTHS_AS_STRING;
      },
      get isCurrentResidencePersisted() {
        const { currentResidenceId } = get();
        return isString(currentResidenceId) && currentResidenceId.length !== 0;
      },
      get isPreviousIncomePersisted() {
        const { previousIncomeId } = get();
        return isString(previousIncomeId) && previousIncomeId.length !== 0;
      },
      get isPreviousResidencePersisted() {
        const { previousResidenceId } = get();
        return (
          isString(previousResidenceId) && previousResidenceId.length !== 0
        );
      },
      get reviewEditProgress() {
        return useWorkflowStore
          .getState()
          .progressByPath(/\/applicant\/.*\/review-applicant/);
      },
      get unemployedGrossAnnualIncome() {
        const { currentIncomeGrossAnnual, currentIncomeEmploymentType } = get();

        if (
          currentIncomeEmploymentType &&
          unemployedTypes.includes(currentIncomeEmploymentType) &&
          currentIncomeGrossAnnual
        ) {
          return currentIncomeGrossAnnual;
        }

        return null;
      },
    },

    clearCurrentEmployment() {
      set({
        currentIncomeEmployerName: null,
        currentIncomeEmploymentType: null,
        currentIncomeGrossAnnual: null,
        currentIncomeJobTitle: null,
      });
    },

    clearSubmitApplicationFailure() {
      set({ submitApplicationFailedMessage: null });
    },

    disableCoapplicant() {
      const {
        computed: { vehicleApplicationId, vehicleId },
      } = useDashboardStore.getState();

      set({
        disableCoapplicantFailedMessage: null,
        isDisablingCoapplicant: true,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .delete(
          `/2.0/vehicles/${vehicleId}/application/${vehicleApplicationId}/co-applicant`,
        )
        .then(() => {
          resetStore(useCoapplicantStore);

          return true;
        })
        .catch((error) => {
          console.error(error);

          set({
            disableCoapplicantFailedMessage: i18n.t(
              'errors.disableCoapplicant.defaultMessage',
            ),
          });

          return false;
        })
        .finally(() => set({ isDisablingCoapplicant: false }));
    },

    loadApplicant() {
      const { hasLoadedApplicant, isLoadingApplicant } = get();

      if (hasLoadedApplicant || isLoadingApplicant) {
        return Promise.resolve(null);
      }

      set({ isLoadingApplicant: true });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get<never, ApplicantDto>('/2.0/applicant')
        .then((applicant: ApplicantDto) => set(toApplicantState(applicant)))
        .then(() => null)
        .catch((error) => {
          console.error(error);
          return null;
        })
        .finally(() =>
          set({ hasLoadedApplicant: true, isLoadingApplicant: false }),
        );
    },
    loader: async (params: Record<string, string>) => {
      const { loadApplicant, setAddCoapplicant } = get();
      const { loadCoapplicant } = useCoapplicantStore.getState();

      const isVehicleEmpty =
        useDashboardStore.getState().computed.isVehicleEmpty;

      // Fetch vehicle if it's not already loaded
      if (isVehicleEmpty) {
        await useDashboardStore.getState().fetchVehicleById(params.vehicleId);
      }

      const fetchVehicleByIdFailed =
        useDashboardStore.getState().fetchVehicleByIdFailedMessage;

      // Redirect to default authenticated path if vehicle fetch failed
      if (fetchVehicleByIdFailed) {
        const redirectUrl = new URL(DEFAULT_AUTH_PATH, window.location.origin);

        return Response.redirect(redirectUrl.toString(), HttpStatusCode.FOUND);
      }

      // Fetch applicant data if it's not already loaded
      await loadApplicant();

      const hasCoapplicant =
        useDashboardStore.getState().computed.vehicleApplicationHasCoapplicant;

      // Fetch coapplicant data if it's not already loaded
      if (hasCoapplicant) {
        setAddCoapplicant(YES);

        await loadCoapplicant();
      }

      return null;
    },

    setAddCoapplicant(value: YesOrNo) {
      set({ addCoapplicant: value });
    },

    setAdditionalIncomeAmount(value: string | null) {
      set({ additionalIncomeAmount: value });
    },
    setAdditionalIncomeFrequency(value: IncomeFrequencyEnum | null) {
      set({ additionalIncomeFrequency: value });
    },
    setAdditionalIncomeType(value: IncomeTypeEnum | null) {
      set({ additionalIncomeType: value });
    },

    setCurrentIncomeEmployerName(value: string | null) {
      set({ currentIncomeEmployerName: value });
    },
    setCurrentIncomeEmploymentType(value: EmploymentType | null) {
      set({ currentIncomeEmploymentType: value });
    },
    setCurrentIncomeGrossAnnual(value: string) {
      set({ currentIncomeGrossAnnual: value });
    },
    setCurrentIncomeJobTitle(value: string | null) {
      set({ currentIncomeJobTitle: value });
    },
    setCurrentIncomeMonthsAt(value: string) {
      set({
        currentIncomeMonthsAt: value,

        ...(value === ONE_YEAR_IN_MONTHS_AS_STRING && {
          previousIncomeEmployerName: null,
          previousIncomeEmploymentType: null,
          previousIncomeGrossAnnual: null,
          previousIncomeJobTitle: null,
        }),
      });
    },

    setCurrentResidenceCity(value: string) {
      set({ currentResidenceCity: value });
    },
    setCurrentResidenceLineOne(value: string) {
      set({ currentResidenceLineOne: value });
    },
    setCurrentResidenceLineTwo(value: string) {
      set({ currentResidenceLineTwo: value });
    },
    setCurrentResidenceMonthlyPaymentAmount(value: string) {
      set({ currentResidenceMonthlyPaymentAmount: value });
    },
    setCurrentResidenceMonthsAt(value: string) {
      set({
        currentResidenceMonthsAt: value,

        ...(value === TWO_YEARS_IN_MONTHS_AS_STRING && {
          previousResidenceCity: null,
          previousResidenceLineOne: null,
          previousResidenceLineTwo: null,
          previousResidencePostalCode: null,
          previousResidenceState: null,
        }),
      });
    },
    setCurrentResidencePostalCode(value: string) {
      set({ currentResidencePostalCode: value });
    },
    setCurrentResidenceState(value: string) {
      set({ currentResidenceState: value });
    },
    setCurrentResidenceType(value: ResidenceTypeEnum) {
      set({ currentResidenceType: value });
    },

    setDateOfBirth(value: string) {
      set({ dateOfBirth: value });
    },

    setHasAdditionalIncome(value: YesOrNo) {
      set({
        hasAdditionalIncome: value,

        ...(value === NO && {
          additionalIncomeAmount: null,
          additionalIncomeFrequency: null,
          additionalIncomeType: null,
        }),
      });
    },
    setIsCurrentlyEmployed(value: YesOrNo) {
      set({ isCurrentlyEmployed: value });
    },

    setPreviousIncomeEmployerName(value: string | null) {
      set({ previousIncomeEmployerName: value });
    },
    setPreviousIncomeEmploymentType(value: EmploymentType | null) {
      set({ previousIncomeEmploymentType: value });
    },
    setPreviousIncomeGrossAnnual(value: string | null) {
      set({ previousIncomeGrossAnnual: value });
    },
    setPreviousIncomeJobTitle(value: string | null) {
      set({ previousIncomeJobTitle: value });
    },

    setPreviousResidenceCity(value: string | null) {
      set({ previousResidenceCity: value });
    },
    setPreviousResidenceLineOne(value: string | null) {
      set({ previousResidenceLineOne: value });
    },
    setPreviousResidenceLineTwo(value: string | null) {
      set({ previousResidenceLineTwo: value });
    },
    setPreviousResidencePostalCode(value: string | null) {
      set({ previousResidencePostalCode: value });
    },
    setPreviousResidenceState(value: string | null) {
      set({ previousResidenceState: value });
    },

    submitApplication(primaryApplicantSSN: string, coApplicantSSN?: string) {
      const { dateOfBirth } = get();
      const { coapplicantDateOfBirth } = useCoapplicantStore.getState();
      const {
        computed: { vehicleId },
      } = useDashboardStore.getState();

      const portalHttpRequest = useApiStore.getState().portalApi();

      set({
        isSubmittingApplication: true,
        submitApplicationFailedMessage: null,
      });

      return portalHttpRequest
        .post<never, null>('/2.0/applicant/submit', {
          birthDate: dateOfBirth,
          coApplicantBirthDate: coapplicantDateOfBirth,
          coApplicantSSN,
          primaryApplicantSSN,
          vehicleId,
        })
        .then(() => true)
        .catch((error: any) => {
          console.error(error);

          set({
            submitApplicationFailedMessage: i18n.t(
              'errors.submitApplication.defaultMessage',
            ),
          });

          return false;
        })
        .finally(() => set({ isSubmittingApplication: false }));
    },

    syncIncome() {
      // Sync the income data with the API
      set({
        isSyncingIncome: true,
        syncingIncomeFailedMessage: null,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, UserIncomesDto>('/2.0/income', toUserIncomesDto(get()))
        .then((incomes: UserIncomesDto) => {
          set(toApplicantStateFromIncomes(incomes));

          return true;
        })
        .catch((error) => {
          console.error(error);

          set({
            syncingIncomeFailedMessage: i18n.t(
              'errors.syncIncome.defaultMessage',
            ),
          });

          return false;
        })
        .finally(() => set({ isSyncingIncome: false }));
    },

    syncResidence() {
      set({
        isSyncingResidence: true,
        syncingResidenceFailedMessage: null,
      });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .post<never, UserResidencesDto>(
          '/2.0/residence',
          toUserResidencesDto(get()),
        )
        .then((residences: UserResidencesDto) => {
          set(toApplicantStateFromResidences(residences));

          return true;
        })
        .catch((error) => {
          console.error(error);

          set({
            syncingResidenceFailedMessage: i18n.t(
              'errors.syncResidence.defaultMessage',
            ),
          });

          return false;
        })
        .finally(() => set({ isSyncingResidence: false }));
    },
  });
