import { isNonEmptyString } from '@thesavingsgroup/utils/isNonEmptyString';
import { every, toNumber } from 'lodash';

import { DEFAULT_PREVIOUS_RESIDENCE_MONTHS_AT } from '../constants/residence';
import { UserResidencesDto } from '../dto/UserResidencesDto';
import { ResidenceTypeEnum } from '../enums/ResidenceTypeEnum';
import { ApplicantState } from '../types/ApplicantState';
import { residenceTypeToOwnershipStatus } from './residenceTypeToOwnershipStatus';

export const toUserResidencesDto = (
  applicant: ApplicantState,
): UserResidencesDto => {
  const userResidencesDto: UserResidencesDto = {
    current: {
      id: applicant.currentResidenceId || null,
      monthlyPaymentAmount: toNumber(
        applicant.currentResidenceMonthlyPaymentAmount,
      ),
      monthsAtResidence: toNumber(applicant.currentResidenceMonthsAt),
      ownershipStatus: residenceTypeToOwnershipStatus(
        applicant.currentResidenceType,
      ),
      physicalAddressCity: applicant.currentResidenceCity || '',
      physicalAddressLineOne: applicant.currentResidenceLineOne || '',
      physicalAddressLineTwo: applicant.currentResidenceLineTwo,
      physicalAddressPostalCode: applicant.currentResidencePostalCode || '',
      physicalAddressProvince: applicant.currentResidenceState || '',
    },
    previous: null,
  };

  const shouldIncludePrevious = every(
    [
      applicant.previousResidenceLineOne,
      applicant.previousResidenceCity,
      applicant.previousResidencePostalCode,
      applicant.previousResidenceState,
    ],
    isNonEmptyString,
  );

  if (shouldIncludePrevious) {
    userResidencesDto.previous = {
      id: applicant.previousResidenceId || null,
      monthlyPaymentAmount: 0,
      monthsAtResidence: DEFAULT_PREVIOUS_RESIDENCE_MONTHS_AT,
      ownershipStatus: residenceTypeToOwnershipStatus(ResidenceTypeEnum.OTHER),
      physicalAddressCity: applicant.previousResidenceCity || '',
      physicalAddressLineOne: applicant.previousResidenceLineOne || '',
      physicalAddressLineTwo: applicant.previousResidenceLineTwo,
      physicalAddressPostalCode: applicant.previousResidencePostalCode || '',
      physicalAddressProvince: applicant.previousResidenceState || '',
    };
  }

  return userResidencesDto;
};
