import './lazyLoader.scss';

import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import useLazyStore from '../../../store/lazyStore/useLazyStore';

type LazyLoaderProps = {
  children: JSX.Element;
};

/**
 * LazyLoader
 *
 * The purpose of this component is to handle the transitioning between
 * page components when the user is navigating between routes (LazyGuard).
 *
 * There are two loading states:
 * - The first is a simple fade out transition between the current page and next
 * - The second is an extended loading state that is shown when the user is still navigating after a certain period of time
 *
 * The extended loading state is useful for users with slow network connections.
 */
export const LazyLoader = ({ children }: LazyLoaderProps): JSX.Element => {
  const [showExtendedLoading, setShowExtendedLoading] = useState(false);

  const { isLazyNavigating } = useLazyStore();

  const transitionClassName = useMemo(() => {
    return [
      'transition-container',
      isLazyNavigating && 'transition-fade-out',
      showExtendedLoading && 'extended-transition-fade-out',
    ]
      .filter(Boolean)
      .join(' ');
  }, [isLazyNavigating, showExtendedLoading]);

  useEffect(() => {
    if (!isLazyNavigating) {
      setShowExtendedLoading(false);

      return;
    }

    // track if the user is still navigating after 1200ms. this will
    // likely be due to a slow network connection. In this case we want
    // to show the extended loading component.
    const timer = setTimeout(() => {
      if (isLazyNavigating) {
        setShowExtendedLoading(true);
      }
    }, 1200);

    return () => clearTimeout(timer);
  }, [isLazyNavigating]);

  return (
    <Fragment>
      {showExtendedLoading && <CircularProgress className="extended-loader" />}
      <div className={transitionClassName}>{children}</div>
    </Fragment>
  );
};

export default LazyLoader;
