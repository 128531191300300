import { StateCreator } from 'zustand';

import { LazyNavigateOptions } from './types/LazyNavigateOptions';
import { LazyState } from './types/LazyState';

export const lazyStore: StateCreator<LazyState> = (set) => ({
  isLazyNavigating: false,
  lazyNavigateOptions: null,
  lazyNavigatePath: null,

  lazyNavigate: (pathname: string, options?: LazyNavigateOptions | null) => {
    set({
      isLazyNavigating: true,
      lazyNavigateOptions: options,
      lazyNavigatePath: pathname,
    });
  },
  lazyNavigateFinished: () => {
    set({
      isLazyNavigating: false,
      lazyNavigateOptions: null,
      lazyNavigatePath: null,
    });
  },
  setIsLazyNavigating: (isLazyNavigating: boolean) => {
    set({ isLazyNavigating });
  },
});
