import { VehicleValues } from '@thesavingsgroup/enums/VehicleValues';

import {
  PanelListFormatType,
  PanelListType,
} from '../shared/PanelList/PanelList';

const currencyFormatOptions = {
  formatType: PanelListFormatType.NUMBER,
  formatOptions: {
    thousandSeparator: true,
    prefix: '$',
    displayType: 'text',
  },
};

export const valuesConfig = [
  {
    key: VehicleValues.GOOD,
    testId: 'goodCondition',
    panelType: PanelListType.TEXT,
    label: 'Good condition',
    ...currencyFormatOptions,
  },
  {
    key: VehicleValues.AVERAGE,
    testId: 'averageCondition',
    panelType: PanelListType.TEXT,
    label: 'Average condition',
    ...currencyFormatOptions,
  },
  {
    key: VehicleValues.POOR,
    testId: 'poorCondition',
    panelType: PanelListType.TEXT,
    label: 'Poor condition',
    ...currencyFormatOptions,
  },
];

export const buttonGroup = {
  primaryButton: {
    name: 'back',
    children: 'Back',
    isDisabled: false,
    testId: 'back',
  },
  rtl: true,
};
