import { set } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { PillowForm } from '../../../components/PillowForm';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import useLoanDocumentStore from '../../../store/_legacy/loanDocumentsStore';
import model from './Model';

const Controller = () => {
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();

  const {
    computed: { uploadRouteAllowed, currentVehicleId },
  } = useLoanDocumentStore();

  useEffect(() => {
    if (!uploadRouteAllowed) {
      navigate(`/${workflowName}/dashboard`);
    }
  }, []);

  const continueClicked = () => {
    navigate(`/${workflowName}/loan-documents/list`);
  };

  const dashboardClicked = () => {
    navigate(`/${workflowName}/dashboard/vehicle/application`, {
      replace: true,
      state: {
        currentVehicle: { id: currentVehicleId },
      },
    });
  };

  set(model, 'template.header.onClose', dashboardClicked);
  set(model, 'form.actions.primary.handler', continueClicked);
  set(model, 'form.actions.secondary.handler', dashboardClicked);

  return <PillowForm presModel={model} />;
};

Controller.displayName = 'LoanDocumentsSubmitted.Controller';
export default Controller;
