import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';

export const inReviewStatuses = [
  ChecklightSessionStatus.APPROVED_BY_LENDER,
  ChecklightSessionStatus.FINALIZE_STIPS,
  ChecklightSessionStatus.PREPARING_CONTRACTS,
  ChecklightSessionStatus.REVIEWING_DOCUMENTS,
  ChecklightSessionStatus.SIGN_CONTRACTS,
  ChecklightSessionStatus.SUBMITTED_TO_LENDER,
  ChecklightSessionStatus.WAITING_ON_FUNDING,
];
