import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';
import { set } from 'lodash';
import { assign } from 'xstate';

import { OffersContext } from '../interfaces/offers.interface';

export const defaultServiceOptions = {
  actions: {
    updateOffers: assign<OffersContext>({
      offers: (context, { offers }: any) => offers,
    }),
    clearOffersList: assign<OffersContext>({
      offers: (context) => ({
        ...context.offers,
        offersWithoutGap: [],
        offersWithGap: [],
        selectedOfferOptionId: '0',
      }),
    }),
    setNoOffers: assign<OffersContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.NO_OFFERS,
      ),
    ),
    setSelectedOffer: assign<OffersContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.SELECTED_OFFER,
      ),
    ),
  },
};
