import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';

import { Workflow } from '../../constants/Workflow';
import { activeApplicationSteps, cancelledApplicationSteps } from './steps';

export enum ApplicationProgressStatus {
  Passed,
  Next,
  Inactive,
}

const getCurrentStepIndex = (
  steps: any[],
  applicationStatus: ChecklightSessionStatus,
) =>
  steps.findIndex(({ statuses }) =>
    statuses.some((status: any) => status === applicationStatus),
  );

const parseSteps = (activeStepIndex: number, steps: any[]) =>
  steps.map((step: any, index) => {
    if (index <= activeStepIndex) {
      return {
        ...step,
        status: ApplicationProgressStatus.Passed,
      };
    } else if (index - 1 === activeStepIndex) {
      return {
        ...step,
        status: ApplicationProgressStatus.Next,
      };
    } else {
      return {
        ...step,
        status: ApplicationProgressStatus.Inactive,
      };
    }
  });

const getChecklightSteps = (applicationStatus: ChecklightSessionStatus) => {
  const activeStepIndex = getCurrentStepIndex(
    activeApplicationSteps,
    applicationStatus,
  );

  return parseSteps(activeStepIndex, activeApplicationSteps);
};

const getUnifiedSteps = (applicationStatus: ChecklightSessionStatus) => {
  const isCancelled = [
    ChecklightSessionStatus.CANCELLED,
    ChecklightSessionStatus.EXPIRED,
  ].includes(applicationStatus);

  if (isCancelled) {
    return parseSteps(0, cancelledApplicationSteps);
  }

  const activeStepIndex = getCurrentStepIndex(
    activeApplicationSteps,
    applicationStatus,
  );

  return parseSteps(activeStepIndex, activeApplicationSteps);
};

export const getSteps = (
  applicationStatus: ChecklightSessionStatus,
  workflow: Workflow,
) => {
  const steps = {
    [Workflow.checklight]: getChecklightSteps,
    [Workflow.unified]: getUnifiedSteps,
  };

  return steps[workflow](applicationStatus);
};
