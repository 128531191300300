import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';
import { has } from 'lodash';

import { ContextValidators as ContextValidatorsType } from '../../../common/interfaces/workflow.interface';
import { AccountContext } from '../common/unified-workflow-context.interface';
import { Step } from '../steps';
import { VehicleInformationSource } from '../Steps/VehicleIdentity/options';

const applicationTypeUserFields = ['firstName', 'lastName'];
const coApplicantChoiceFields = ['coApplicantAnswer'];
const primaryGoalFields = applicationTypeUserFields.concat('applicationType');
const purchaseTypeFields = primaryGoalFields;
const personalDetailsFields = purchaseTypeFields;
const checkUserCredentialsFields = personalDetailsFields.concat([
  'primaryPhoneNumber',
  'primaryEmailAddress',
]);
const coApplicantFields = checkUserCredentialsFields;
const vehicleIdentityFields = checkUserCredentialsFields;
const vehicleMakeFields = ['year'];
const vehicleModelFields = vehicleMakeFields.concat(['make', 'makeId']);
const vehicleConfirmFields = vehicleModelFields;
const reviewUserFields = checkUserCredentialsFields.concat([
  'residences.current.physicalAddress',
  'personalInfo',
]);
const reviewCoApplicantFields = [
  ...applicationTypeUserFields,
  ...['primaryPhoneNumber', 'primaryEmailAddress'],
  ...['residences.current.physicalAddress', 'personalInfo'],
];

const mapFieldsDependingOnApplicationType = (
  fields: string[],
  ctx: AccountContext,
) => {
  const { user } = ctx || {};
  const { applicationType } = user || {};
  if (applicationType === ApplicationType.REFINANCE) {
    return fields.concat('primaryGoal');
  }
  return fields.concat('purchaseType');
};

const hasFieldsValidationDependOnAppType =
  (contextKey: keyof AccountContext, fields: string[]) =>
  (context: AccountContext) => {
    const mappedFields = mapFieldsDependingOnApplicationType(fields, context);
    return hasFieldsValidation(contextKey, mappedFields)(context);
  };

const hasFieldsValidation =
  (contextKey: keyof AccountContext, fields: string[]) =>
  (context: AccountContext): boolean => {
    if (!context?.[contextKey]) {
      return false;
    }

    return fields.every((field) => has(context[contextKey], field));
  };

export const ContextValidators: Record<
  string,
  ContextValidatorsType<AccountContext>
> = {
  [Step.ApplicationType]: [
    hasFieldsValidation('user', applicationTypeUserFields),
    hasFieldsValidation('coApplicant', coApplicantChoiceFields),
  ],
  [Step.PrimaryGoal]: [
    (ctx: AccountContext) =>
      ctx?.user?.applicationType === ApplicationType.REFINANCE,
    hasFieldsValidation('user', primaryGoalFields),
    hasFieldsValidation('coApplicant', coApplicantChoiceFields),
  ],
  [Step.PurchaseType]: [
    (ctx: AccountContext) =>
      ctx?.user?.applicationType === ApplicationType.PURCHASE,
    hasFieldsValidation('user', purchaseTypeFields),
    hasFieldsValidation('coApplicant', coApplicantChoiceFields),
  ],
  [Step.PersonalDetails]: [
    hasFieldsValidationDependOnAppType('user', personalDetailsFields),
    hasFieldsValidation('coApplicant', coApplicantChoiceFields),
  ],
  [Step.CheckUserCredentials]: [
    hasFieldsValidationDependOnAppType('user', checkUserCredentialsFields),
    (ctx: AccountContext) => !!ctx.userVerificationError,
  ],
  [Step.CoApplicant]: [
    hasFieldsValidationDependOnAppType('user', coApplicantFields),
    (ctx: AccountContext) => Boolean(ctx?.coApplicant?.coApplicantAnswer),
  ],
  [Step.VehicleIdentity]: hasFieldsValidationDependOnAppType(
    'user',
    vehicleIdentityFields,
  ),
  [Step.SelectVehicleYear]: (ctx: AccountContext) =>
    (ctx.editedVehicle || ctx.vehicle)?.vehicleInformationSource ===
    VehicleInformationSource.MakeAndModel,
  [Step.SelectVehicleMake]: (ctx: AccountContext) =>
    hasFieldsValidation('editedVehicle', vehicleMakeFields)(ctx) ||
    hasFieldsValidation('vehicle', vehicleMakeFields)(ctx),
  [Step.SelectVehicleModel]: (ctx: AccountContext) =>
    hasFieldsValidation('editedVehicle', vehicleModelFields)(ctx) ||
    hasFieldsValidation('vehicle', vehicleModelFields)(ctx),
  [Step.FindVehicleByVin]: (ctx: AccountContext) =>
    (ctx.editedVehicle || ctx.vehicle)?.vehicleInformationSource ===
    VehicleInformationSource.Vin,
  [Step.FindVehicleByLicensePlate]: (ctx: AccountContext) =>
    (ctx.editedVehicle || ctx.vehicle)?.vehicleInformationSource ===
      VehicleInformationSource.LicensePlate &&
    ctx.user.applicationType !== ApplicationType.PURCHASE,
  [Step.VehicleConfirm]: (ctx: AccountContext) =>
    hasFieldsValidation('vehicle', vehicleConfirmFields)(ctx) ||
    hasFieldsValidation('editedVehicle', vehicleConfirmFields)(ctx),
  [Step.Review]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.ReviewApplicant]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.ReviewHousing]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.ReviewCoApplicant]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('coApplicant', reviewCoApplicantFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.ReviewCoApplicantPersonalInfo]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('coApplicant', reviewCoApplicantFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.ReviewCoApplicantHousingInfo]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('coApplicant', reviewCoApplicantFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
  ],
  [Step.PinVerify]: [
    hasFieldsValidationDependOnAppType('user', reviewUserFields),
    hasFieldsValidation('vehicle', vehicleConfirmFields),
    (ctx: AccountContext) =>
      ctx?.coApplicant?.coApplicantAnswer
        ? hasFieldsValidation('coApplicant', reviewCoApplicantFields)(ctx)
        : true,
  ],
};
