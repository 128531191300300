import { AdditionalIncomeType } from '@thesavingsgroup/enums/AdditionalIncomeType';

export const additionalIncomeChoices = [
  {
    label: 'Aid for Dependent Children',
    value: AdditionalIncomeType.AID_FOR_DEPENDENT_CHILDREN,
  },
  {
    label: 'Child Support',
    value: AdditionalIncomeType.CHILD_SUPPORT,
  },
  {
    label: 'Cost of Living Allowance (COLA)',
    value: AdditionalIncomeType.COST_OF_LIVING_ALLOWANCE,
  },
  {
    label: 'Disability',
    value: AdditionalIncomeType.DISABILITY,
  },
  {
    label: 'Employment',
    value: AdditionalIncomeType.EMPLOYMENT,
  },
  {
    label: 'Military Basic Allowance for Subsistence (BAS)',
    value: AdditionalIncomeType.MILITARY_BASIC_ALLOWANCE_FOR_SUBSISTENCE,
  },
  {
    label: 'Municipal Bond Interest',
    value: AdditionalIncomeType.MUNICIPAL_BOND_INTEREST,
  },
  {
    label: 'Non Taxable Sources of Income',
    value: AdditionalIncomeType.NON_TAXABLE_SOURCES,
  },
  {
    label: 'Other',
    value: AdditionalIncomeType.OTHER,
  },
  {
    label: 'Public Assistance',
    value: AdditionalIncomeType.PUBLIC_ASSISTANCE,
  },
  {
    label: 'Rental Income',
    value: AdditionalIncomeType.RENTAL_INCOME,
  },
  {
    label: 'Second Employer',
    value: AdditionalIncomeType.SECONDARY_EMPLOYMENT,
  },
  {
    label: 'Social Security',
    value: AdditionalIncomeType.SOCIAL_SECURITY,
  },
  {
    label: 'Supplemental Security Income',
    value: AdditionalIncomeType.SUPPLEMENTAL_SECURITY_INCOME,
  },
  {
    label: 'Unknown',
    value: AdditionalIncomeType.UNKNOWN,
  },
  {
    label: "Workman's Compensation",
    value: AdditionalIncomeType.WORKMANS_COMPENSATION,
  },
];
