import { METADATA_SCHEMA_VERSION } from '@thesavingsgroup/constants';
import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';
import { MetaDataPlatform } from '@thesavingsgroup/enums/MetaDataPlatform';
import { PermanentOrganization } from '@thesavingsgroup/enums/PermanentOrganization';

import { Workflow } from '../../constants/Workflow';
import { AccountContext } from '../../workflow/assembly/UnifiedDefaultSignUp/common/unified-workflow-context.interface';
import { SearchParamKey } from '../urlParams/urlParams';
import { urlParamHook } from '../urlParams/urlParamsService';
import { getActiveParams, getOriginalUrl } from '../urlParams/urlParamsService';

export const getUserMetaData = (
  ctx: AccountContext,
  workflow: Workflow,
  cookieId: string,
) => {
  const { vehicle: { loan: { payoffAmount = null } = {} } = {} } = ctx;
  const {
    user: { applicationType },
  } = ctx;
  const { savedParam: leadChannel } = urlParamHook(
    SearchParamKey.leadChannelCode,
  );
  const initialUrl = getOriginalUrl();
  const isRefinance = applicationType === ApplicationType.REFINANCE;

  return {
    schemaVersion: METADATA_SCHEMA_VERSION,
    onboardingExperience: PermanentOrganization.CONSUMER_PORTAL,
    googleAnalytics: {
      clientId: cookieId || 'no cookie available',
    },
    ...(isRefinance
      ? {
          consumerSelectedTradeline: {
            payoffAmount,
            selectedDate: new Date().toISOString(),
            selectedSource: PermanentOrganization.CONSUMER_PORTAL,
          },
        }
      : {}),
    experience: {
      CONSUMER_PORTAL: {
        workflow,
        leadChannel,
        initialUrl,
        queryParams: getActiveParams(),
      },
      RATES_REQUESTED: {
        onboardingImportPlatform: MetaDataPlatform.CONSUMER_PORTAL,
      },
    },
  };
};
