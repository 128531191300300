import { ApplicationType } from '@thesavingsgroup/enums/ApplicationType';

import { UserVehicle } from '../common/interfaces/vehicle/vehicle.interface';

export const isPurchaseApplication = (currentVehicle: UserVehicle) => {
  return [
    ApplicationType.DEALER,
    ApplicationType.PRIVATE_PARTY,
    ApplicationType.PURCHASE,
  ].includes(currentVehicle.checklightSession?.type as ApplicationType);
};
