import { BrowserTracing } from '@sentry/browser';
import {
  CaptureConsole,
  ExtraErrorData,
  HttpClient,
} from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { AxiosRequestConfig } from 'axios';
import { get } from 'lodash';
import React from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

const MAX_POSSIBLE_TIME_MS = 2147483647; // for setTimeout

const sessionTTL = import.meta.env.VITE_APP_SESSION_TTL;
let maxAgeMs = MAX_POSSIBLE_TIME_MS;
if (sessionTTL && parseInt(sessionTTL, 10) < MAX_POSSIBLE_TIME_MS) {
  maxAgeMs = parseInt(sessionTTL, 10);
}

const notProduction = ['local', 'dev', 'qa'].includes(
  import.meta.env.VITE_APP_ENV as string,
);

const unleashEnvironmentMap: Record<string, string> = {
  dev: 'development',
  local: 'local',
  prod: 'production',
  qa: 'qa',
  staging: 'stage',
  test: 'development',
};

const fastTrackMap: Record<string, string> = {
  dev: 'https://dev-apply.autopay.com',
  local: 'https://local-apply.autopay.com',
  prod: 'https://apply.autopay.com',
  qa: 'https://stage-apply.autopay.com',
  staging: 'https://stage-apply.autopay.com',
  test: 'https://dev-apply.autopay.com',
};

const sentryEnvironmentMap = { ...unleashEnvironmentMap };

const getRelease = () => {
  if (
    import.meta.env.VITE_APP_VERSION &&
    import.meta.env.VITE_APP_COMMIT_HASH
  ) {
    return `${import.meta.env.VITE_APP_VERSION}_${
      import.meta.env.VITE_APP_COMMIT_HASH
    }`;
  }

  return 'local';
};

const getTracesSampleRate = () => {
  // user agent is controlled by automation
  if (navigator.webdriver) {
    return 0.1;
  }

  if (notProduction) {
    return 1.0;
  }

  return 0.25;
};

export const lsPrefix = 'consumer_portal';

export const config = {
  environment: sentryEnvironmentMap[import.meta.env.VITE_APP_ENV || 'dev'],
  version: import.meta.env.VITE_APP_VERSION,
  commitHash: import.meta.env.VITE_APP_COMMIT_HASH,
  defaultLeadChannelCode: 'CONSUMER_PORTAL',
  api: {
    baseUrl:
      import.meta.env.VITE_APP_ENV === 'test'
        ? 'http://127.0.0.1:8041'
        : import.meta.env.VITE_APP_SERVICE_PUBLIC_URL,
    path: 'api',
  },
  fastTrackUrl: fastTrackMap[import.meta.env.VITE_APP_ENV || 'dev'],
  branding: {
    defaultId: 'AUTOPAY',
  },
  session: {
    maxAgeMs,
    CHECKLIGHT_TOKEN_KEY: `${lsPrefix}:token`,
  },
  debug: {
    workflow: notProduction,
    gates: notProduction,
    devtools: notProduction,
  },
  flowDataStorage: {
    encryptionEnabled:
      import.meta.env.VITE_APP_FLOW_DATA_STORAGE_ENCRYPTION === 'enabled',
    LOCAL_STORAGE_KEY: `${lsPrefix}:flow`,
  },
  analyticAuth: {
    dev: {
      gtmId: 'GTM-WG66XZF',
      auth: 'YmGMxVAiACUK6N65X918nw',
      preview: 'env-5',
    },
    qa: {
      gtmId: 'GTM-WG66XZF',
      auth: 'YmGMxVAiACUK6N65X918nw',
      preview: 'env-5',
    },
    stg: {
      gtmId: 'GTM-WG66XZF',
      auth: 'Fi73Yii0y8UzwsvEv1zFQg',
      preview: 'env-4',
    },
    prod: {
      gtmId: 'GTM-WG66XZF',
    },
  },
  unleash: {
    appName: 'consumer-portal-ui',
    clientKey: import.meta.env.VITE_APP_UNLEASH_CLIENT_KEY,
    environment: unleashEnvironmentMap[import.meta.env.VITE_APP_ENV || 'dev'],
    url: import.meta.env.VITE_APP_UNLEASH_URL,
    refreshInterval: 180, // 3 minutes
    impressionDataAll: true,
  },
  sentry: {
    debug: false,
    dsn: 'https://20adc36c08104533969c4807d9169de5@o92107.ingest.sentry.io/1398689',
    ignoreErrors: ['t.blacklistC'],
    environment: import.meta.env.VITE_APP_ENV,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new ExtraErrorData(),
      new HttpClient(),
      new CaptureConsole({ levels: ['error'] }),
    ],
    release: getRelease(),
    sendDefaultPii: true,
    tracesSampleRate: getTracesSampleRate(),
    beforeSend(event: any) {
      const exceptionsToIgnore = [
        'Please verify your Phone number',
        'Your account has been temporarily locked',
        'There was an unexpected error attempting to send the verification code, please try again later.',
        'There was an error attempting to verify the code. Please click "Re-send" and try again.',
        'There was an error attempting to verify the code. Please re-send it.',
        'There was an unexpected error attempting to verify the code. Please try again.',
        'There was an unexpected error during account creation. Please try again.',
        'Object captured as promise rejection with keys: fieldErrors, globalErrors',
        'Error: Unauthorized',
        'Error: Vehicle not found with plate',
        'Error: Vehicle details not found',
      ];

      const exceptionMessage = get<string>(
        event,
        'exception.values.0.value',
        '',
      );

      const ignore = exceptionsToIgnore.some((ignoreMessage) =>
        exceptionMessage.includes(ignoreMessage),
      );

      return !ignore ? event : null;
    },
  } as Sentry.BrowserOptions,
  targetEnvironment: import.meta.env.VITE_APP_TARGET_ENVIRONMENT,
  fileUploadTimeout: 30000,
  loanDocuments: {
    analytics: {
      eventName: 'submitted-protection-document',
    },
    dropzone: {
      accept: {
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
        'application/pdf': ['.pdf'],
      },
      maxFiles: 100,
      maxSize: 25e6, // 25MB
    },
    gap: {
      exampleDocumentUrl:
        'https://ap-consumer-portal.s3.amazonaws.com/product/protection-document-examples/gap-example/gap-example-0524.pdf',
    },
    ric: {
      exampleDocumentUrl:
        'https://ap-consumer-portal.s3.amazonaws.com/product/protection-document-examples/ric-example/ric-example-0524.pdf',
    },
    signedUrlConfig: {
      timeout: 5000,
    } as AxiosRequestConfig,
    uploadConfig: {
      timeout: 1000 * 60 * 5, // 5 minutes in milliseconds
    } as AxiosRequestConfig,
    vsc: {
      exampleDocumentUrl:
        'https://ap-consumer-portal.s3.amazonaws.com/product/protection-document-examples/vsc-example/vsc-example-0524.pdf',
    },
  },
};
