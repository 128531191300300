import { StateCreator } from 'zustand';

import { BrandingState } from './types/BrandingState';
import { BrandingStateDependencies } from './types/BrandingStateDependencies';

export const brandingStore =
  ({ useApiStore }: BrandingStateDependencies): StateCreator<BrandingState> =>
  (set) => ({
    branding: {},
    initBrandingFinished: false,

    initBranding: (leadChannelCode: string | null | undefined) => {
      if (!leadChannelCode) {
        set({ initBrandingFinished: true });
        return;
      }

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .get(`/ui-configuration/branding?leadChannelCode=${leadChannelCode}`)
        .then((branding) => {
          set({ branding });
        })
        .catch((error) => console.error(error))
        .finally(() => {
          set({ initBrandingFinished: true });
        });
    },
  });
