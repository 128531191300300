import { devtools } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useParameterStore from '../parameterStore/useParameterStore';
import useRouteStore from '../routeStore/useRouteStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { resetAllStores } from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { signOutStore } from './signOutStore';
import { SignOutState } from './types/SignOutState';

const useSignOutStore: DevtoolsStore<SignOutState> = create({
  ignoreReset: true,
})(
  devtools<SignOutState>(
    signOutStore({
      resetAllStores,
      useApiStore: lazyDependency(() => useApiStore),
      useParameterStore: lazyDependency(() => useParameterStore),
      useRouteStore: lazyDependency(() => useRouteStore),
    }),
    devToolOptions('signOutStore'),
  ),
);

export default useSignOutStore;
