import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useDashboardStore from '../dashboardStore/useDashboardStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import useUserStore from '../userStore/useUserStore';
import { getVerifications } from './functions/getVerifications';
import { VerificationState } from './types/VerificationState';
import { keysToPersist, verificationStore } from './verificationStore';

const useVerificationStore: DevtoolsStore<VerificationState> = create({
  hmrPersist: 'verificationStore',
})(
  devtools(
    persist<VerificationState>(
      verificationStore({
        getVerifications,
        useApiStore: lazyDependency(() => useApiStore),
        useDashboardStore: lazyDependency(() => useDashboardStore),
        useUserStore: lazyDependency(() => useUserStore),
      }),
      {
        name: 'consumer_portal:verification',
        partialize(state: VerificationState): VerificationState {
          return pick(state, keysToPersist) as VerificationState;
        },
      },
    ),
    devToolOptions('verificationStore'),
  ),
);

export default useVerificationStore;
