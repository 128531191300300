/**
 * yearList
 *
 * Returns a list of Years descending from next year to this year - the length
 *
 * The year we want needs to be the "length" + 2.  As this year and next year are a part of the length count
 *
 * @length {Number}
 * @param  {Array}
 */
export function yearList(length = 10): string[] {
  const startYear = new Date().getFullYear() + 1;
  return [...Array(length + 2)].map((_, i) => `${startYear - i}`);
}
