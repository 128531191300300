import { activeStatuses } from '@thesavingsgroup/constants/activeStatuses';
import { deleteNotAllowedStatuses } from '@thesavingsgroup/constants/deleteNotAllowedStatuses';
import { invalidStatuses } from '@thesavingsgroup/constants/invalidStatuses';
import { statusesForFetchingLoanDecision } from '@thesavingsgroup/constants/statusesForFetchingLoanDecision';

import { ChecklightSession } from '../interfaces';

export const isApplicationActive = (
  checklightSession: ChecklightSession,
): boolean => {
  return activeStatuses.includes(checklightSession?.status);
};

export const isApplicationValid = (
  checklightSession: ChecklightSession,
): boolean => !invalidStatuses.includes(checklightSession.status);

export const isAbleToFetchLoanDecision = (
  checklightSession: ChecklightSession,
): boolean =>
  statusesForFetchingLoanDecision.some(
    (status) => status === checklightSession.status,
  );

export const isRemoveVehicleRestricted = (
  checklightSession: ChecklightSession,
): boolean => {
  return deleteNotAllowedStatuses.includes(checklightSession?.status);
};
