import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useParameterStore from '../parameterStore/useParameterStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import { keysToPersist, routeStore } from './routeStore';
import { RouteState } from './types/RouteState';

const useRouteStore: DevtoolsPersistStore<RouteState> = create()(
  devtools(
    persist<RouteState>(
      routeStore({
        useParameterStore: lazyDependency(() => useParameterStore),
      }),
      {
        name: 'consumer_portal:route',
        partialize(state: RouteState): RouteState {
          return pick(state, keysToPersist) as RouteState;
        },
      },
    ),
    devToolOptions('routeStore'),
  ),
);

export default useRouteStore;
