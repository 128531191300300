import isEmpty from 'lodash/isEmpty';

export const splitFilename = (
  filename: string,
): { name: string; extension: string } => {
  if (isEmpty(filename)) {
    return { name: '', extension: '' };
  }

  if (!filename.includes('.')) {
    return { name: filename, extension: '' };
  }

  const indexOfLastPeriod = filename.lastIndexOf('.');
  const name = filename.substring(0, indexOfLastPeriod);
  const extension = filename.substring(indexOfLastPeriod + 1);

  return { name, extension };
};
