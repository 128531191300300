import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useApplicantStore from '../applicantStore/useApplicantStore';
import {
  coapplicantStore,
  keysToPersist,
} from '../coapplicantStore/coapplicantStore';
import useDashboardStore from '../dashboardStore/useDashboardStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import useWorkflowStore from '../workflowStore/useWorkflowStore';
import { CoapplicantState } from './types/CoapplicantState';

const useCoapplicantStore: DevtoolsPersistStore<CoapplicantState> = create({
  hmrPersist: 'coapplicantStore',
})(
  devtools(
    persist<CoapplicantState>(
      coapplicantStore({
        useApiStore: lazyDependency(() => useApiStore),
        useApplicantStore: lazyDependency(() => useApplicantStore),
        useDashboardStore: lazyDependency(() => useDashboardStore),
        useWorkflowStore: lazyDependency(() => useWorkflowStore),
      }),
      {
        name: 'consumer_portal:coapplicant',
        partialize(state: CoapplicantState) {
          return pick(state, keysToPersist) as CoapplicantState;
        },
      },
    ),
    devToolOptions('coapplicantStore'),
  ),
);

export default useCoapplicantStore;
