import { StateCreator } from 'zustand';

import { DEFAULT_AUTH_PATH } from '../routeStore/constants/authPath';
import { SignOutOptions } from './types/SignOutOptions';
import { SignOutState } from './types/SignOutState';
import { SignOutStateDependencies } from './types/SignOutStateDependencies';

export const signOutStore =
  ({
    resetAllStores,
    useApiStore,
    useParameterStore,
    useRouteStore,
  }: SignOutStateDependencies): StateCreator<SignOutState> =>
  (set) => ({
    isSigningOut: false,
    signOutMessage: null,

    signOut: (options?: SignOutOptions) => {
      // set the state that determines if the user just signed out.
      // this should be set before the API call to ensure the user is
      // redirected to the correct page in the SessionGuard.
      set({ isSigningOut: true });

      const portalHttpRequest = useApiStore.getState().portalApi();

      return portalHttpRequest
        .delete('/2.0/session')
        .then(() => {})
        .catch(() => {
          // ignore for now..
        })
        .finally(() => {
          // reset all stores to their initial state
          resetAllStores();

          // set the sign out message to display on the sign out page
          set({ signOutMessage: options?.withMessage });

          // the parameterStore is not reset in resetAllStores as we require it to always be persisted
          // so we need to manually reset the token here.
          useParameterStore.setState({ token: null });

          // set the auth redirect path to where we'd want the user to go after signing back in.
          // if not provided, the user will be redirected to the default auth path after sign in.
          useRouteStore
            .getState()
            .setAuthRedirectPath(
              options?.withAuthRedirectPath || DEFAULT_AUTH_PATH,
            );
        });
    },
  });
