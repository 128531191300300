/**
 * Vehicle Util
 */
import { ImageType } from '@thesavingsgroup/enums/ImageType';

import carShadowDarkImg from '../../assets/car-shadow-dark.png';
import carShadowProfileImg from '../../assets/car-shadow-profile.png';

export const defaultImagesByType: Record<string, any> = {
  [ImageType.PROFILE]: carShadowProfileImg,
  [ImageType.DEFAULT]: carShadowDarkImg,
};

/**
 * imageListToTypeUrlMap
 *
 * transforms our image list from radix into an object with the "image type" as key and url as value.  ie:
 *
 * {
 *   [ImageType.PROFILE]: 'https://urltosomeimage.com',
 *
 * }
 */
const imageListToTypeUrlMap = (imageList = []) =>
  imageList.reduce(
    (acc: any, radixImageObject: any) => ({
      ...acc,
      [radixImageObject.vehicleImageType]: radixImageObject.url,
    }),
    {},
  );
/**
 * getImageByType
 *
 * Returns the vehicle image by type, or a default image for that given type.
 */
export const getImageByType =
  (type: any) =>
  (imageList: any, includeDefault = true) => {
    const imagesByType = imageListToTypeUrlMap(imageList);
    if (imagesByType[type]) {
      return imagesByType[type];
    }
    if (includeDefault) {
      return defaultImagesByType[type];
    }
    return undefined;
  };

export default {
  getImageByType,
};
