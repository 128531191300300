import { useEffect, useState } from 'react';

import { CircularProgressFullScreen } from '../components/shared_new/CircularProgressFullScreen/CircularProgressFullScreen';
import useBrandingStore from '../store/brandingStore/useBrandingStore';
import useLeadChannelStore from '../store/leadChannelStore/useLeadChannelStore';
import useParameterStore from '../store/parameterStore/useParameterStore';

const CmsGuard = ({ children }: { children: JSX.Element }) => {
  const { initLeadChannel, initLeadChannelFinished } = useLeadChannelStore();
  const [fetchingLeadChannel, setFetchingLeadChannel] = useState(true);

  const { initBranding, initBrandingFinished } = useBrandingStore();
  const [fetchingBranding, setFetchingBranding] = useState(true);

  const { leadChannelCode } = useParameterStore();

  useEffect(() => {
    if (initLeadChannelFinished) {
      setFetchingLeadChannel(false);
      return;
    }

    initLeadChannel(leadChannelCode);
  }, [initLeadChannel, initLeadChannelFinished]);

  useEffect(() => {
    if (initBrandingFinished) {
      setFetchingBranding(false);
      return;
    }

    initBranding(leadChannelCode);
  }, [initBranding, initBrandingFinished]);

  if (fetchingLeadChannel || fetchingBranding) {
    return <CircularProgressFullScreen />;
  }

  return children;
};

export default CmsGuard;
