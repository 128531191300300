import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';

import { ApplicationTaskEnum } from '../../constants/applicationTasks';
import { LoanTaskEnum } from '../../constants/loanTasks';
import { Workflow } from '../../constants/Workflow';
import { APPLICATION_STATUSES_PER_TASK } from './applicationStatuses';

const makeTasksPerStatus = (statusesPerTask: {
  [key in LoanTaskEnum | ApplicationTaskEnum]?: ChecklightSessionStatus[];
}) =>
  Object.entries(statusesPerTask).reduce((acc, [task, statuses]) => {
    const statusToTask = statuses.reduce(
      (accStatus, status) => ({
        ...accStatus,
        [status]: (acc[status] || []).concat(
          task as LoanTaskEnum | ApplicationTaskEnum,
        ),
      }),
      {} as any,
    );

    return {
      ...acc,
      ...statusToTask,
    };
  }, {} as any);

export const getApplicationTasksPerStatus = (workflow: Workflow) =>
  makeTasksPerStatus(APPLICATION_STATUSES_PER_TASK[workflow]);

export const getAllApplicationTasks = (workflow: Workflow) =>
  Object.keys(APPLICATION_STATUSES_PER_TASK[workflow]);
