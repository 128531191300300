import { AgnosticIndexRouteObject } from '@sentry/react/types/types';
import { toString } from 'lodash';
import { matchRoutes } from 'react-router-dom';
import { StateCreator } from 'zustand';

import { routes } from '../../routes/ChecklightRouter';
import { DEFAULT_AUTH_PATH } from './constants/authPath';
import { NonAuthRedirectPath } from './enums/NonAuthRedirectPath';
import { RouteState } from './types/RouteState';
import { RouteStateDependencies } from './types/RouteStateDependencies';

export const keysToPersist = ['authRedirectPath'];

export const routeStore =
  ({ useParameterStore }: RouteStateDependencies): StateCreator<RouteState> =>
  (set, get) => ({
    authRedirectPath: DEFAULT_AUTH_PATH,
    isOnNewUIRoute: true,

    computed: {
      get authenticatedRoutes() {
        const authenticatedRoutes: AgnosticIndexRouteObject[] = routes
          // filter out the wildcard route that matches all paths.
          // this route is used to redirect the user when attempting to go to a non-existent route.
          .filter((route) => route.path !== '*')

          // filter out non-authenticated routes
          .map((route) => ({
            ...route,
            children: route.children?.filter(
              (childRoute) => !childRoute.isPublicRoute,
            ),
          }));

        return authenticatedRoutes;
      },
      get nonAuthRedirectPath() {
        const { intent } = useParameterStore.getState();

        if (
          intent &&
          Object.keys(NonAuthRedirectPath).includes(toString(intent))
        ) {
          return NonAuthRedirectPath[intent];
        }

        return NonAuthRedirectPath.SIGN_IN;
      },
      get signUpRedirectPath() {
        const { authRedirectPath } = get();

        if (authRedirectPath === DEFAULT_AUTH_PATH) {
          return '/apply';
        }

        return authRedirectPath;
      },
    },

    isAuthenticatedRoute: (pathname: string) => {
      const { authenticatedRoutes } = get().computed;

      const routeExists = matchRoutes(authenticatedRoutes, pathname);

      return Boolean(routeExists);
    },
    setAuthRedirectPath: (pathname: string) => {
      const { isAuthenticatedRoute } = get();

      set({
        authRedirectPath: isAuthenticatedRoute(pathname)
          ? pathname
          : DEFAULT_AUTH_PATH,
      });
    },
  });
