import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import useUserStore from '../userStore/useUserStore';
import { keysToPersist, parameterStore } from './parameterStore';
import { ParameterState } from './types/ParameterState';

const useParameterStore: DevtoolsPersistStore<ParameterState> = create({
  ignoreReset: true,
})(
  devtools(
    persist<ParameterState>(parameterStore, {
      name: 'consumer_portal:storage',
      partialize(state: ParameterState): ParameterState {
        const userStore = lazyDependency(() => useUserStore);

        // current user is available. only keep keys that are required when authenticated.
        if (userStore.getState().computed.hasCurrentUser) {
          return {
            hasDuplicateApplication: state.hasDuplicateApplication,
            leadChannelCode: state.leadChannelCode,
            migrateUser: state.migrateUser,
            token: state.token,
            vehicleId: state.vehicleId,
          } as ParameterState;
        }

        return pick(state, keysToPersist) as ParameterState;
      },
    }),
    devToolOptions('parameterStore'),
  ),
);

export default useParameterStore;
