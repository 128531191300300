import { devtools } from 'zustand/middleware';

import { create, devToolOptions } from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { WorkflowState } from './types/WorkflowState';
import { workflowStore } from './workflowStore';

const useWorkflowStore: DevtoolsStore<WorkflowState> = create()(
  devtools<WorkflowState>(workflowStore, devToolOptions('workflowStore')),
);

export default useWorkflowStore;
