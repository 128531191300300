import { ONE_YEAR_IN_MONTHS_AS_NUMBER } from '../constants/oneYear';
import { TWO_YEARS_IN_MONTHS_AS_NUMBER } from '../constants/twoYears';

export const reduceResidenceMonthsAt = (
  months: number | undefined | null,
): number | null => {
  if (!months || months < 1) {
    return null;
  }

  return months >= 24
    ? TWO_YEARS_IN_MONTHS_AS_NUMBER
    : ONE_YEAR_IN_MONTHS_AS_NUMBER;
};
