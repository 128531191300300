export enum HttpStatusCode {
  // 2xx
  OK = 200,
  ACCEPTED = 202,
  NO_CONTENT = 204,

  // 3xx
  FOUND = 302,

  // 4xx
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CONFLICT = 409, // conflict or duplicates
  PRECONDITION_FAILED = 412,
  UNPROCESSABLE_ENTITY = 422,
  UNSUPPORTED_MEDIA_TYPE = 415,

  // 5xx
  SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  SERVICE_UNAVAILABLE = 503,
}
