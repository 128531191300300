import { VerificationStatus } from '../enums/VerificationStatus';
import { UploadItem } from './UploadItem';

export class Verification {
  condition? = true;
  continuousUploads? = false;
  copy: string;
  headline: string;
  isOptional? = false;
  name: string;
  path: string;
  uploadItems: UploadItem[];

  constructor(verification?: Verification | Partial<Verification>) {
    Object.assign(this, verification);
  }

  get isComplete() {
    return (
      !this.continuousUploads &&
      this.uploadItems.every((item) => item.isUploaded)
    );
  }

  get status() {
    if (this.isComplete) {
      return VerificationStatus.COMPLETE;
    }

    if (this.isOptional) {
      return VerificationStatus.OPTIONAL;
    }

    return VerificationStatus.REQUESTED;
  }
}
