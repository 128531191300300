import TagManager from 'react-gtm-module';
import { devtools } from 'zustand/middleware';

import { config } from '../../config';
import { create, devToolOptions } from '../storeManager/storeManager';
import { DevtoolsStore } from '../storeManager/types/DevtoolsStore';
import { analyticStore } from './analyticStore';
import { AnalyticState } from './types/AnalyticState';

const useAnalyticStore: DevtoolsStore<AnalyticState> = create()(
  devtools<AnalyticState>(
    analyticStore({
      addEventListener: document.addEventListener.bind(document),
      config,
      removeEventListener: document.removeEventListener.bind(document),
      TagManager,
    }),
    devToolOptions('analyticStore'),
  ),
);

export default useAnalyticStore;
