import { Suspense, useEffect, useMemo, useTransition } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { CircularProgressFullScreen } from '../components/shared_new/CircularProgressFullScreen/CircularProgressFullScreen';
import LazyLoader from '../components/shared_new/LazyLoader/LazyLoader';
import useLazyStore from '../store/lazyStore/useLazyStore';

type LazyGuardProps = {
  children: JSX.Element;
};

/**
 * LazyGuard
 *
 * The purpose of this guard is to show the currently mounted component
 * while the next router component is being lazily loaded.
 */
export const LazyGuard = ({ children }: LazyGuardProps): JSX.Element => {
  const [isPending, startTransition] = useTransition();

  const { lazyNavigateOptions, lazyNavigatePath, lazyNavigateFinished } =
    useLazyStore();

  const navigate = useNavigate();
  const location = useLocation();

  // with the router we can ensure the lazy navigation has been completed
  // when the location pathname has been updated to the lazyNavigatePath.
  const navigationCompleted = useMemo(() => {
    return location.pathname === lazyNavigatePath;
  }, [location.pathname, lazyNavigatePath]);

  useEffect(() => {
    if (!lazyNavigatePath) {
      return;
    }

    // by setting a timeout we can transition to the next page smoothly
    // avoiding any flickering or jarring transitions.
    setTimeout(() => {
      startTransition(() => {
        navigate(lazyNavigatePath, { ...lazyNavigateOptions });
      });
    }, 300);
  }, [lazyNavigatePath]);

  useEffect(() => {
    if (!isPending && navigationCompleted) {
      if (lazyNavigateOptions?.withPostExecute) {
        lazyNavigateOptions.withPostExecute();
      }

      lazyNavigateFinished();
    }
  }, [isPending, navigationCompleted]);

  return (
    <LazyLoader>
      <Suspense fallback={<CircularProgressFullScreen />}>{children}</Suspense>
    </LazyLoader>
  );
};

export default LazyGuard;
