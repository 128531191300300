import { inReviewStatuses } from '@thesavingsgroup/constants/inReviewStatuses';
import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';

/**
 * Used to restrict vehicle deletion if it's related Application is in one
 * of those statuses
 */
export const deleteNotAllowedStatuses = [
  ...inReviewStatuses,
  ChecklightSessionStatus.FUNDED,
];
