import { get as lodashGet, has } from 'lodash';
import { TagManagerArgs } from 'react-gtm-module';
import { StateCreator } from 'zustand';

import { AnalyticState } from './types/AnalyticState';
import { AnalyticStateDependencies } from './types/AnalyticStateDependencies';

export const analyticStore =
  ({
    addEventListener,
    config,
    removeEventListener,
    TagManager,
  }: AnalyticStateDependencies): StateCreator<AnalyticState> =>
  (set, get) => ({
    inputFocusedTimestamp: Date.now(),

    bindAnalyticListeners: () => {
      addEventListener('focusin', get().sendElementAnalytics);
      addEventListener('focusout', get().sendElementAnalytics);
      addEventListener('click', get().sendElementAnalytics);
    },
    clearAnalyticListeners: () => {
      removeEventListener('focusin', get().sendElementAnalytics);
      removeEventListener('focusout', get().sendElementAnalytics);
      removeEventListener('click', get().sendElementAnalytics);
    },
    initializeAnalytics: () => {
      const currentEnvironment = config.targetEnvironment;
      const analyticAuth: { [key: string]: TagManagerArgs } =
        config.analyticAuth;

      if (!currentEnvironment || !has(analyticAuth, currentEnvironment)) {
        return;
      }

      TagManager.initialize(analyticAuth[currentEnvironment]);

      // bind event listeners after initialization
      get().bindAnalyticListeners();
    },
    sendAnalytic: (
      eventName: string,
      elementName: string,
      elementLocation: string,
      eventDuration: number | null,
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          [eventName]: elementName,
          ...(eventDuration ? { 'Input Duration': eventDuration } : {}),
          Location: elementLocation,
        },
      });
    },
    sendElementAnalytics: (event: Event) => {
      const targetElement = lodashGet(event, 'target');

      const isInput = lodashGet(targetElement, 'tagName') === 'INPUT';
      const isButton = lodashGet(targetElement, 'tagName') === 'BUTTON';

      // determine whether the element was focused or blurred
      const isFocused = lodashGet(event, 'type') === 'focusin';
      const isBlurred = lodashGet(event, 'type') === 'focusout';
      const isClick = lodashGet(event, 'type') === 'click';

      if (
        (!isInput && !isButton) || // element is not an input or button
        (isInput && isClick) || // element is an input that was clicked (only capture input focus/blur)
        (isButton && !isClick) // element is an button that was not clicked (only capture button clicks)
      ) {
        return;
      }

      // assign event data default values
      let eventName = '';
      let elementName = '';
      let elementLocation = '';
      let eventDuration = null;

      // focused input data
      if (isInput && isFocused) {
        eventName = 'Input In';
        elementName = lodashGet(targetElement, 'name', '');
        elementLocation = `${lodashGet(
          event,
          'view.window.location.pathname',
        )}__${elementName}`;

        // store timestamp of when input was focused for blurred calculation
        set({ inputFocusedTimestamp: Date.now() });
      }

      // blurred input data
      if (isInput && isBlurred) {
        eventName = 'Input Out';
        elementName = lodashGet(targetElement, 'name', '');
        elementLocation = `${lodashGet(
          event,
          'view.window.location.pathname',
        )}__${elementName}`;

        // calculate the amount of time that the input was focused for
        eventDuration = (Date.now() - get().inputFocusedTimestamp) / 1000;
      }

      // clicked button data
      if (isButton && isClick) {
        eventName = 'Button';
        elementName = lodashGet(targetElement, 'innerText', '');
        elementLocation = lodashGet(event, 'view.window.location.pathname', '');
      }

      // send analytic event
      get().sendAnalytic(
        eventName,
        elementName,
        elementLocation,
        eventDuration,
      );
    },
    sendLandedWithParametersAnalytics: (
      eventName: string,
      url: string,
      urlHost: string,
      urlPathname: string,
      parameters: Record<string, unknown>,
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          event: eventName,
          url,
          urlHost,
          urlPathname,
          ...parameters,
        },
      });
    },
    sendLoanDocumentAnalytics: (
      eventName: string,
      documentType: string,
      count: number,
    ) => {
      TagManager.dataLayer({
        dataLayer: {
          Count: count,
          event: eventName,
          LoanDocumentType: documentType,
        },
      });
    },
  });
