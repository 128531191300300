import { AuthType } from '@thesavingsgroup/auth/AuthType';
import { DeliveryMechanism } from '@thesavingsgroup/auth/DeliveryMechanism';
import { useMemo, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { fields } from '../../forms/user';
import { useAPI } from '../../hooks/useAPI/useAPI';
import { useCountdownTimer } from '../../hooks/useCountdownTimer/useCountdownTimer';
import { useCurrentUserContext } from '../../hooks/useCurrentUserContext/useCurrentUserContext';
import { withMatchMedia } from '../../providers/context/MatchMediaProvider/withMatchMedia';
import { mapGlobalErrors } from '../../utils/pipes/form-global-errors';
import { FormConfirmModal } from '../FormConfirmModal/FormConfirmModal';
import { Form } from '../shared/Form/Form';
import { phoneNumberErrors, pinErrors } from './config';
import { FormContainer } from './styles';

export const EditPhoneNumberComponent = ({
  isDesktop,
}: {
  isDesktop?: boolean;
}) => {
  const navigate = useNavigate();

  const [currentUserContext, setCurrentUserContext] = useCurrentUserContext();
  const api = useAPI();

  const [secondsLeft, setSecondsLeft] = useState(0);
  const { startTimer, stopTimer } = useCountdownTimer(15, setSecondsLeft);
  const methods = useForm();
  const { resetField } = methods;

  const { primaryPhoneNumber } = currentUserContext;

  const watchPhoneNumber = methods.watch(
    'primaryPhoneNumber',
    primaryPhoneNumber,
  );

  const updateFields = useAsyncCallback(
    ({ pin, ...data }, errorsConfig = {}) => {
      if (onResendPin.result) {
        return api
          .put('/users/current', {
            pin,
            phone: currentUserContext.primaryPhoneNumber,
            ...data,
          })
          .then((user) => {
            setCurrentUserContext({ ...currentUserContext, ...user });
            stopTimer();
            navigate('..');
          })
          .catch((errors) => {
            console.error('Error updating user', errors);
            throw mapGlobalErrors(errors, errorsConfig);
          });
      } else {
        navigate('..');
      }
    },
  );
  const onResendPin = useAsyncCallback(
    async (errorsConfig = {}, isPhoneChanging) => {
      startTimer();
      updateFields.reset();
      resetField('pin');
      try {
        await api.post('pin/deliver', {
          purpose: isPhoneChanging
            ? AuthType.EDIT_PHONE_USING_PIN
            : AuthType.EDIT_DATA_USING_PIN,
          deliverWith: DeliveryMechanism.SMS,
          phone: watchPhoneNumber || primaryPhoneNumber,
        });
        return watchPhoneNumber || primaryPhoneNumber;
      } catch (e) {
        stopTimer();
        throw mapGlobalErrors(e, errorsConfig);
      }
    },
  );

  const formCancelButtonConfig = useMemo(
    () => ({
      handler: () => navigate('..'),
      label: 'Cancel',
      testId: 'nameCancel',
      isDisabled: onResendPin.loading || updateFields.loading,
    }),
    [navigate, onResendPin.loading, updateFields.loading],
  );

  const formConfig = useMemo(
    () => ({
      fields: {
        primaryPhoneNumber: {
          ...fields.primaryPhoneNumber,
          value: primaryPhoneNumber,
          ...(!!onResendPin.result && watchPhoneNumber !== onResendPin.result
            ? {
                hint: 'You must send code again for a new Phone Number',
              }
            : {}),
        },
        ...(!onResendPin.result
          ? {}
          : {
              pin: fields.pin,
            }),
      },
      actions: {
        primary: {
          handler: !!onResendPin.result
            ? (data: any) =>
                updateFields.execute(data, {
                  ...pinErrors,
                  ...phoneNumberErrors,
                })
            : () =>
                onResendPin.execute(
                  { ...pinErrors, ...phoneNumberErrors },
                  true,
                ),
          label: 'Save',
          testId: 'phoneSave',
          isDisabled:
            onResendPin.loading ||
            updateFields.loading ||
            (!!onResendPin.result && onResendPin.result !== watchPhoneNumber),
        },
        secondary: !!onResendPin.result
          ? {
              handler: () => onResendPin.execute(pinErrors, true),
              isDisabled: secondsLeft > 0,
              ...(secondsLeft > 0
                ? {
                    timerDuration: secondsLeft,
                  }
                : {}),
              label: secondsLeft > 0 ? 'Retry' : 'Re-send',
              testId: 'pinRetry',
              isHidden: !onResendPin.result,
            }
          : formCancelButtonConfig,
      },
      globalErrors: updateFields.error || onResendPin.error,
    }),
    [
      primaryPhoneNumber,
      watchPhoneNumber,
      formCancelButtonConfig,
      onResendPin,
      secondsLeft,
      updateFields,
    ],
  );

  return (
    <FormProvider {...methods}>
      <FormContainer $isDesktop={isDesktop}>
        <Form form={formConfig} methods={methods} />
      </FormContainer>
      <FormConfirmModal formFields={formConfig.fields} methods={methods} />
    </FormProvider>
  );
};

export const EditPhoneNumber = withMatchMedia(EditPhoneNumberComponent);
