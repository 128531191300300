import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import { config } from '../../config';
import useAnalyticStore from '../analyticStore/useAnalyticStore';
import useApiStore from '../apiStore/useApiStore';
import useAttributionStore from '../attributionStore/useAttributionStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import useUserStore from '../userStore/useUserStore';
import { keysToPersist, loanDocumentStore } from './loanDocumentStore';
import { LoanDocumentStateType } from './types/LoanDocumentStateType';

const useNewLoanDocumentStore: DevtoolsPersistStore<LoanDocumentStateType> =
  create()(
    devtools(
      persist<LoanDocumentStateType>(
        loanDocumentStore({
          config,
          revokeObjectURL: URL.revokeObjectURL,
          useAnalyticStore: lazyDependency(() => useAnalyticStore),
          useApiStore: lazyDependency(() => useApiStore),
          useAttributionStore: lazyDependency(() => useAttributionStore),
          useUserStore: lazyDependency(() => useUserStore),
        }),
        {
          name: 'consumer_portal:loanDocuments',
          partialize: (state: LoanDocumentStateType) =>
            pick(state, keysToPersist) as LoanDocumentStateType,
        },
      ),
      devToolOptions('newLoanDocumentStore'),
    ),
  );

export default useNewLoanDocumentStore;
