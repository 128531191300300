import { useEffect } from 'react';

import useAnalyticStore from '../store/analyticStore/useAnalyticStore';
import useRouteStore from '../store/routeStore/useRouteStore';

/**
 * UIRevampCleanupGuard
 *
 * The purpose of this guard is to cleanup any UI Revamp conflicts due to the
 * existing UI.
 *
 * This is meant to run at the root of the unified path in the router. By doing
 * so it will ensure any UI Revamp code does not make into the existing UI
 */
const UIRevampCleanupGuard = ({ children }: { children: JSX.Element }) => {
  const { clearAnalyticListeners } = useAnalyticStore();

  useEffect(() => {
    // isOnNewUIRoute is being used to display the correct ErrorBoundary component.
    // see App.tsx (errorElement on router)
    useRouteStore.setState({ isOnNewUIRoute: false });

    // the new UI binds event listeners to the document used for analytics on load of the app.
    // we want to remove these listeners when the user navigates to the old UI.
    clearAnalyticListeners();
  }, []);

  return children;
};

export default UIRevampCleanupGuard;
