import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useApiStore from '../apiStore/useApiStore';
import useDashboardStore from '../dashboardStore/useDashboardStore';
import useParameterStore from '../parameterStore/useParameterStore';
import {
  create,
  devToolOptions,
  lazyDependency,
} from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import { applyStore, keysToPersist } from './applyStore';
import { ApplyState } from './types/ApplyState';

const useApplyStore: DevtoolsPersistStore<ApplyState> = create()(
  devtools(
    persist<ApplyState>(
      applyStore({
        useApiStore: lazyDependency(() => useApiStore),
        useDashboardStore: lazyDependency(() => useDashboardStore),
        useParameterStore: lazyDependency(() => useParameterStore),
      }),
      {
        name: 'consumer_portal:apply',
        partialize: (state: ApplyState) =>
          pick(state, keysToPersist) as ApplyState,
      },
    ),
    devToolOptions('applyStore'),
  ),
);

export default useApplyStore;
