import { additionalIncomeChoices } from '@thesavingsgroup/constants/additionalIncomeChoices';

import {
  INCOME_FREQUENCY_CHOICES,
  INCOME_TYPE_CHOICES,
} from '../constants/choices/checklight';
import {
  INCOME_FREQUENCY_CHOICES as UNIFIED_INCOME_FREQUENCY_CHOICES,
  UNIFIED_EMPLOYMENT_TYPE_CHOICES,
} from '../constants/choices/unified';
import { componentTypes } from '../constants/componentTypes';
import { InputFormatter } from '../utils/input/inputFormatter';
import { generateDropdownPresentationModel } from '../utils/listOptions/DropdownPresentationModel';
import type { FormFieldsModelT } from './formFieldsModelT';

export const startDateRegex = /^\d{4}-\d{2}/;

const incomeTypeConfig = {
  name: 'incomeType',
  label: 'Income type',
  component: componentTypes.DROPDOWN,
  validationRules: {
    required: 'Income type is required',
  },
  ...generateDropdownPresentationModel({
    optionsSource: INCOME_TYPE_CHOICES,
  }),
};

const unifiedIncomeTypeConfig = {
  name: 'incomeType',
  label: 'Employment type',
  component: componentTypes.DROPDOWN,
  validationRules: {
    required: 'Employment type is required',
  },
  ...generateDropdownPresentationModel({
    optionsSource: UNIFIED_EMPLOYMENT_TYPE_CHOICES,
  }),
};

export const fields: FormFieldsModelT = {
  incomeType: incomeTypeConfig,
  incomeFrequency: {
    name: 'incomeFrequency',
    label: 'Income frequency',
    component: componentTypes.DROPDOWN,
    validationRules: {
      required: 'Income frequency is required',
    },
    ...generateDropdownPresentationModel({
      optionsSource: INCOME_FREQUENCY_CHOICES,
    }),
  },
  amount: {
    name: 'amount',
    label: 'Amount',
    component: componentTypes.TEXT,
    validationRules: {
      required: 'Amount is required',
      min: {
        value: 1,
        message: 'You should have some income',
      },
    },
    format: InputFormatter.MONEY,
  },
  additionalIncomeType: {
    ...incomeTypeConfig,
    ...generateDropdownPresentationModel({
      optionsSource: additionalIncomeChoices,
    }),
    name: 'additionalIncomeType',
  },
  employerName: {
    name: 'employerName',
    label: 'Employer name',
    component: componentTypes.TEXT,
    validationRules: {
      required: 'Employer name is required',
    },
  },
  jobTitle: {
    name: 'jobTitle',
    label: 'Job title',
    component: componentTypes.TEXT,
    validationRules: {
      required: 'Job title is required',
    },
  },
  startDate: {
    name: 'startDate',
    component: componentTypes.TEXT,
    label: 'Start date',
    validationRules: {
      required: 'Date is required',
      pattern: {
        value: startDateRegex,
        message: 'Correct date is required',
      },
      validate: (v: string) => {
        return Date.now() > Date.parse(v) || 'Start date must be a past date';
      },
    },
    format: InputFormatter.DATE,
    formatOptions: {
      placeholder: 'MM/YYYY',
      displayFormat: '##/####',
    },
  },
};

export const unifiedFields: FormFieldsModelT = {
  incomeType: unifiedIncomeTypeConfig,
  incomeFrequency: {
    ...fields.incomeFrequency,
    ...generateDropdownPresentationModel({
      optionsSource: UNIFIED_INCOME_FREQUENCY_CHOICES,
    }),
  },
};
