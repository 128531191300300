import { ChecklightSessionStatus } from '@thesavingsgroup/enums/ChecklightSessionStatus';
import { mergeWith, pick, set } from 'lodash';
import { assign } from 'xstate';

import { FormFieldsModelT } from '../../../forms/formFieldsModelT';
import {
  UserIncome,
  UserIncomes,
} from '../../../providers/context/CurrentUserContext/CurrentUserContextT';
import { shouldHaveExtendedFields } from '../../../utils/helpers/income/unified';
import { isOlderThan } from '../../../utils/pipes/date';
import { ApplicantContext } from '../interfaces/applicant.interface';
import { populatePreviousIncomesInfo } from '../Steps/ApplicantEmploymentPreviousInfo/utils';

export const defaultServiceOptions = {
  actions: {
    updateUser: assign<ApplicantContext>({
      updatedUser: (context, { updatedUser }: any) => updatedUser,
    }),
    patchUser: assign<ApplicantContext>({
      updatedUser: (context, { data }: any) => {
        return mergeWith(
          { ...context.updatedUser },
          data,
          (oldValue, newValue, key) => {
            // Do no deep merge incomes/residences
            if (key === 'incomes' || key === 'residences') {
              return { ...oldValue, ...newValue };
            }
          },
        );
      },
    }),
    setApplicantComplete: assign<ApplicantContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.APPLICANT_COMPLETE,
      ),
    ),
    setCoApplicantComplete: assign<ApplicantContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.CO_APPLICANT_COMPLETE,
      ),
    ),
    setReviewComplete: assign<ApplicantContext>((context) =>
      set(
        context,
        'currentVehicle.checklightSession.status',
        ChecklightSessionStatus.SUBMITTED_TO_LENDER,
      ),
    ),
  },
};

export const isRequiredPreviousIncome = ({ startDate = '' }: UserIncome) => {
  return !isOlderThan(startDate, 12);
};

export const getPreviousIncomeFieldValues =
  (fields: any) =>
  (send: any, { context }: any) => {
    return populatePreviousIncomesInfo(fields, context.updatedUser);
  };

export const filterCurrentIncomeNonRequiredFields = (
  incomes: Required<UserIncomes>,
  fields: FormFieldsModelT,
) => {
  const incomesReset = JSON.parse(JSON.stringify(incomes));
  const {
    current: { incomeType },
  } = incomesReset;
  if (!shouldHaveExtendedFields(incomeType)) {
    const fieldsToReset = pick(fields, ['employerName', 'jobTitle']);
    Object.values(fieldsToReset).forEach(({ name }: any) => {
      set(incomesReset, name, '');
    });
  }
  return incomesReset;
};
