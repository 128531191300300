import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';

export const phoneNumberErrors = {
  [HttpStatusCode.UNPROCESSABLE_ENTITY]: 'Valid Cell Phone number is required',
  [HttpStatusCode.CONFLICT]:
    'Sorry, an account with such phone number is already registered',
};

export const pinErrors = {
  [HttpStatusCode.NOT_FOUND]:
    'There was an error attempting to verify the code. Please click "Re-send" and try again.',
  [HttpStatusCode.BAD_REQUEST]:
    'There was an error attempting to verify the code. Please click "Re-send" and try again.',
  [HttpStatusCode.SERVER_ERROR]:
    'There was an unexpected error attempting to deliver the code. Please try again',
};

export const emailErrors = {
  [HttpStatusCode.UNPROCESSABLE_ENTITY]: {
    Constraint: 'An account with this email address is already registered',
  },
  [HttpStatusCode.CONFLICT]:
    'Sorry, an account with this email is already registered.',
};

export const dobErrors = {
  [HttpStatusCode.UNPROCESSABLE_ENTITY]: {
    Past: 'Date of birth must be a past date',
  },
};
