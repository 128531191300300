import {
  filter,
  findIndex,
  findLastIndex,
  get as lodashGet,
  isEmpty,
  isFunction,
  map,
  some,
} from 'lodash';
import { StateCreator } from 'zustand';

import { defaultOverrideFunction } from './functions/defaultOverrideFunction';
import { RouteOverrideFunction } from './types/RouteOverrideFunction';
import { WorkflowPage } from './types/WorkflowPage';
import { WorkflowState } from './types/WorkflowState';
import { applicantWorkflow } from './workflows/applicant';
import { applyWorkflow } from './workflows/apply';

export const workflowStore: StateCreator<WorkflowState> = (set, get) => ({
  activeWorkflow: null,
  availableWorkflows: [applyWorkflow, applicantWorkflow],
  currentRoute: '',

  computed: {
    get activeWorkflowChecksCompletion() {
      return some(get().activeWorkflow, ({ completed }) =>
        isFunction(completed),
      );
    },
    get completedPages() {
      const { activeWorkflow } = get();

      return filter(activeWorkflow, (page: WorkflowPage) =>
        page.completed ? page.completed() : false,
      );
    },
    get currentPageIndex() {
      const { activeWorkflow, currentRoute } = get();

      if (!activeWorkflow || !currentRoute) {
        return -1;
      }

      const currentIndex = findIndex(
        activeWorkflow,
        ({ path }) => path === currentRoute,
      );

      return currentIndex;
    },
    get hasCompletedPages() {
      const {
        computed: { completedPages },
      } = get();

      return !isEmpty(completedPages);
    },
    get hasIncompletePagesBeforeCurrent() {
      const {
        computed: { currentPageIndex, incompleteIndexes },
      } = get();

      return some(
        incompleteIndexes,
        (index: number) => index < currentPageIndex,
      );
    },
    get incompleteIndexes() {
      return filter(
        map(get().activeWorkflow, (page: WorkflowPage, index: number) => {
          if (page.completed) {
            return page.completed() ? -1 : index;
          }

          return -1;
        }),
        (index: number) => index !== -1,
      );
    },
    get lastPageIndex() {
      const { activeWorkflow } = get();

      return activeWorkflow ? activeWorkflow.length - 1 : -1;
    },
    get nextIncompleteIndex() {
      const {
        activeWorkflow,
        computed: { currentPageIndex },
      } = get();

      if (isEmpty(activeWorkflow)) {
        return -1;
      }

      return findIndex(
        activeWorkflow,
        (page: WorkflowPage) => (page.completed ? !page.completed() : false),
        currentPageIndex,
      );
    },
    get nextPageIndex() {
      const {
        activeWorkflow,
        computed: { currentPageIndex },
      } = get();

      // if there is no active workflow just return zero
      // as we do not have an array to traverse
      if (!activeWorkflow) {
        return 0;
      }

      const nextPageIndex = Math.min(
        currentPageIndex + 1,
        activeWorkflow.length,
      );

      return findIndex(
        activeWorkflow,
        (page: WorkflowPage) => (page.condition ? page.condition() : true),
        nextPageIndex,
      );
    },
    get previousPageIndex() {
      const {
        activeWorkflow,
        computed: { currentPageIndex },
      } = get();

      // if there is no active workflow just return zero
      // as we do not have an array to traverse
      if (!activeWorkflow) {
        return 0;
      }

      const previousPageIndex = Math.max(currentPageIndex - 1, 0);

      return findLastIndex(
        activeWorkflow,
        (page: WorkflowPage) => (page.condition ? page.condition() : true),
        previousPageIndex,
      );
    },
    get progress() {
      const {
        activeWorkflow,
        computed: { currentPageIndex },
      } = get();

      if (!activeWorkflow || currentPageIndex === -1) {
        return 0;
      }

      return Math.round(((currentPageIndex + 1) / activeWorkflow.length) * 100);
    },
  },

  detect(
    currentRoute: string,
    setRouteOverride: RouteOverrideFunction = defaultOverrideFunction,
  ) {
    let route = currentRoute;

    // clean up ending forward slash to better match route path
    if (route.endsWith('/')) {
      route = route.substring(0, route.length - 1);
    }

    set({ currentRoute: route });

    const { workflowByPath } = get();

    // we are making an assumption that there is a 1:1 mapping
    // between the route and the workflow, meaning we can just
    // use the first workflow that matches the current route
    const activeWorkflow = workflowByPath(route);

    set({ activeWorkflow });

    if (activeWorkflow) {
      // important to get these values after current route and active workflow
      // have been set, otherwise there will be some unexpected behavior
      const {
        computed: { hasIncompletePagesBeforeCurrent },
        first,
      } = get();

      if (hasIncompletePagesBeforeCurrent) {
        setRouteOverride(first());
      }
    }
  },
  first() {
    const { activeWorkflow, currentRoute } = get();

    return lodashGet(activeWorkflow, [0, 'path']) || currentRoute;
  },
  last() {
    const {
      activeWorkflow,
      computed: { lastPageIndex },
      currentRoute,
    } = get();

    return lodashGet(activeWorkflow, [lastPageIndex, 'path']) || currentRoute;
  },
  next() {
    const {
      activeWorkflow,
      computed: { nextPageIndex },
      currentRoute,
    } = get();

    return lodashGet(activeWorkflow, [nextPageIndex, 'path']) || currentRoute;
  },
  previous() {
    const {
      activeWorkflow,
      computed: { previousPageIndex },
      currentRoute,
    } = get();

    return (
      lodashGet(activeWorkflow, [previousPageIndex, 'path']) || currentRoute
    );
  },
  progressByPath: (providedPath: string | RegExp) => {
    const { workflowByPath } = get();

    const workflow = workflowByPath(providedPath);

    const index = findIndex(workflow, ({ path }) =>
      providedPath instanceof RegExp
        ? path.match(providedPath) !== null
        : path === providedPath,
    );

    if (index === -1) {
      return 0;
    }

    return Math.round(((index + 1) / workflow.length) * 100);
  },
  resume() {
    const {
      activeWorkflow,
      computed: {
        activeWorkflowChecksCompletion,
        currentPageIndex,
        nextIncompleteIndex,
        incompleteIndexes,
      },
      currentRoute,
      last,
      next,
    } = get();

    // if there is not an active workflow
    // or the current route is not in the active workflow
    // return the current route
    if (currentPageIndex === -1) {
      return currentRoute;
    }

    // the current route is in the active workflow
    // and we have an active workflow
    // and no incomplete pages
    if (isEmpty(incompleteIndexes)) {
      // and the active workflow checks completion of pages
      // return the last page of the workflow
      if (activeWorkflowChecksCompletion) {
        return last();
      }

      // and the active workflow does not check the completion of pages
      // return the next page of the workflow
      return next();
    }

    // the current route is in the active workflow
    // and we have an active workflow
    // and we have incomplete pages
    // but the next incomplete index is likely prior to the current index
    // return the first incomplete page of the workflow
    if (nextIncompleteIndex === -1) {
      return (
        lodashGet(activeWorkflow, [incompleteIndexes[0], 'path']) ||
        currentRoute
      );
    }

    // the current route is in the active workflow
    // and we have an active workflow
    // and we have an incomplete page after the current page
    // return the next incomplete page
    // fallback to the current route if the path is falsy (null, '', etc)
    return (
      lodashGet(activeWorkflow, [nextIncompleteIndex, 'path']) || currentRoute
    );
  },
  workflowByPath: (providedPath: string | RegExp) => {
    const { availableWorkflows } = get();

    return (
      availableWorkflows.find((workflow: WorkflowPage[]) =>
        workflow.find(({ path }) =>
          providedPath instanceof RegExp
            ? path.match(providedPath) !== null
            : path === providedPath,
        ),
      ) || null
    );
  },
});
