import { EmploymentType } from '@thesavingsgroup/enums/EmploymentType';
import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';

export const UNIFIED_EMPLOYMENT_TYPE_CHOICES = [
  {
    value: EmploymentType.FULL_TIME,
    label: 'Full time',
  },
  {
    value: EmploymentType.PART_TIME,
    label: 'Part time',
  },
  {
    value: EmploymentType.ACTIVE_MILITARY,
    label: 'Military',
  },
  {
    value: EmploymentType.SELF_EMPLOYED,
    label: 'Self-employed',
  },
  {
    value: EmploymentType.STUDENT,
    label: 'Student',
  },
  {
    value: EmploymentType.HOMEMAKER,
    label: 'Homemaker',
  },
  {
    value: EmploymentType.RETIRED,
    label: 'Retired',
  },
  {
    value: EmploymentType.UN_EMPLOYED,
    label: 'Unemployed',
  },
  {
    value: EmploymentType.DISABILITY,
    label: 'Disability',
  },
  {
    value: EmploymentType.OTHER,
    label: 'Other',
  },
];

export const INCOME_FREQUENCY_CHOICES = [
  {
    label: 'Annually',
    value: PayFrequency.YEARLY,
  },
  {
    label: 'Bi-Weekly',
    value: PayFrequency.BI_WEEKLY,
  },
  {
    label: 'Monthly',
    value: PayFrequency.MONTHLY,
  },
  {
    label: 'Semi-Monthly',
    value: PayFrequency.TWICE_MONTHLY,
  },
  {
    label: 'Weekly',
    value: PayFrequency.WEEKLY,
  },
];
