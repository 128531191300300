import { isEmpty, toString } from 'lodash';

import { ownershipStatusToResidenceType } from '../../applicantStore/functions/ownershipStatusToResidenceType';
import { reduceResidenceMonthsAt } from '../../applicantStore/functions/reduceResidenceMonthsAt';
import { ApplicantState } from '../../applicantStore/types/ApplicantState';
import { NO, YES } from '../../applicantStore/types/YesOrNo';
import { CoapplicantResidencesDto } from '../dto/CoapplicantResidencesDto';
import { CoapplicantState } from '../types/CoapplicantState';
import { currentResidenceMatchesApplicant } from './currentResidenceMatchesApplicant';

export const toCoapplicantStateFromResidences = (
  residences: CoapplicantResidencesDto,
  applicantState: Partial<ApplicantState>,
): Partial<CoapplicantState> => {
  const state: Partial<CoapplicantState> = {};

  if (isEmpty(residences)) {
    return state;
  }

  if (residences.current) {
    state.coapplicantCurrentResidenceCity =
      residences.current.physicalAddressCity || null;
    state.coapplicantCurrentResidenceLineOne =
      residences.current.physicalAddressLineOne || null;
    state.coapplicantCurrentResidenceLineTwo =
      residences.current.physicalAddressLineTwo || null;
    state.coapplicantCurrentResidenceMonthlyPaymentAmount =
      toString(residences.current.monthlyPaymentAmount) || null;
    state.coapplicantCurrentResidenceMonthsAt = toString(
      reduceResidenceMonthsAt(residences.current.monthsAtResidence),
    );
    state.coapplicantCurrentResidencePostalCode =
      residences.current.physicalAddressPostalCode || null;
    state.coapplicantCurrentResidenceState =
      residences.current.physicalAddressProvince || null;
    state.coapplicantCurrentResidenceType = ownershipStatusToResidenceType(
      residences.current.ownershipStatus,
    );

    const isSameResidenceAsApplicant = currentResidenceMatchesApplicant(
      applicantState,
      state,
    );

    state.hasSameResidenceAsApplicant = isSameResidenceAsApplicant ? YES : NO;
  }

  if (residences.previous) {
    state.coapplicantPreviousResidenceCity =
      residences.previous.physicalAddressCity || null;
    state.coapplicantPreviousResidenceLineOne =
      residences.previous.physicalAddressLineOne || null;
    state.coapplicantPreviousResidenceLineTwo =
      residences.previous.physicalAddressLineTwo || null;
    state.coapplicantPreviousResidencePostalCode =
      residences.previous.physicalAddressPostalCode || null;
    state.coapplicantPreviousResidenceState =
      residences.previous.physicalAddressProvince || null;
  }

  return state;
};
