export enum ChecklightSessionStatus {
  APPLICANT_COMPLETE = 'APPLICANT_COMPLETE',
  APPROVED_BY_LENDER = 'APPROVED_BY_LENDER',
  CANCELLED = 'CANCELLED',
  CANCELLED_CONSUMER = 'CANCELLED_CONSUMER',
  CO_APPLICANT_COMPLETE = 'CO_APPLICANT_COMPLETE',
  DUPLICATE = 'DUPLICATE',
  EXPIRED = 'EXPIRED',
  FINALIZE_STIPS = 'FINALIZE_STIPS',
  FUNDED = 'FUNDED',
  HAS_OFFERS = 'HAS_OFFERS',
  LOAN_DOCUMENTS_PROVIDED = 'LOAN_DOCUMENTS_PROVIDED',
  LOAN_DOCUMENTS_STARTED = 'LOAN_DOCUMENTS_STARTED',
  NO_LOAN = 'NO_LOAN',
  NO_OFFERS = 'NO_OFFERS',
  PENDING_SUBMISSION = 'PENDING_SUBMISSION',
  PREPARING_CONTRACTS = 'PREPARING_CONTRACTS',
  REVIEWING_DOCUMENTS = 'REVIEWING_DOCUMENTS',
  SELECTED_OFFER = 'SELECTED_OFFER',
  SIGN_CONTRACTS = 'SIGN_CONTRACTS',
  SUBMITTED_TO_LENDER = 'SUBMITTED_TO_LENDER',
  WAITING_ON_FUNDING = 'WAITING_ON_FUNDING',
}
