import { ApplicantState } from '../../applicantStore/types/ApplicantState';
import { CoapplicantState } from '../types/CoapplicantState';

export const currentResidenceMatchesApplicant = (
  {
    currentResidenceCity,
    currentResidenceLineOne,
    currentResidenceLineTwo,
    currentResidencePostalCode,
    currentResidenceState,
  }: Partial<ApplicantState>,
  {
    coapplicantCurrentResidenceCity,
    coapplicantCurrentResidenceLineOne,
    coapplicantCurrentResidenceLineTwo,
    coapplicantCurrentResidencePostalCode,
    coapplicantCurrentResidenceState,
  }: Partial<CoapplicantState>,
): boolean => {
  return (
    currentResidenceCity === coapplicantCurrentResidenceCity &&
    currentResidenceLineOne === coapplicantCurrentResidenceLineOne &&
    currentResidenceLineTwo === coapplicantCurrentResidenceLineTwo &&
    currentResidencePostalCode === coapplicantCurrentResidencePostalCode &&
    currentResidenceState === coapplicantCurrentResidenceState
  );
};
