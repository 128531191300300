import { HALF_YEAR_IN_MONTHS_AS_NUMBER } from '../constants/halfYear';
import { ONE_YEAR_IN_MONTHS_AS_NUMBER } from '../constants/oneYear';

export const reduceIncomeMonthsAt = (
  months: number | undefined | null,
): number | null => {
  if (!months) {
    return null;
  }

  return months >= 12
    ? ONE_YEAR_IN_MONTHS_AS_NUMBER
    : HALF_YEAR_IN_MONTHS_AS_NUMBER;
};
