import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';
import { NotImplementedError } from '@thesavingsgroup/http/errors/NotImplementedError';
import get from 'lodash/get';

const annualPaymentOccurances = {
  [PayFrequency.WEEKLY]: 52,
  [PayFrequency.BI_WEEKLY]: 26,
  [PayFrequency.MONTHLY]: 12,
  [PayFrequency.TWICE_MONTHLY]: 24,
  [PayFrequency.YEARLY]: 1,
};

export const getAnnualPaymentCountByPayFrequency = (
  frequency: PayFrequency,
) => {
  const occurrences = get(annualPaymentOccurances, frequency);
  if (occurrences) {
    return occurrences;
  }
  throw new NotImplementedError(
    'Unable to determine the total number of payments',
  );
};
