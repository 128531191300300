import { HttpStatusCode } from '@thesavingsgroup/enums/HttpStatusCode';
import axios, { AxiosError } from 'axios';

import { config } from '../../../config';
import { SIGN_OUT_EVENT_NAME } from '../../../constants/events';
import { REACT_ROOT_NODE_ID } from '../../../constants/rootNode';
import ApiContext from './ApiContext';

export type ApiError = Error & {
  statusCode?: number;
  errorCode?: number;
};

const handleUnauthorized = (error: AxiosError) => {
  if (
    error.response?.status === HttpStatusCode.UNAUTHORIZED &&
    localStorage.getItem(config.session.CHECKLIGHT_TOKEN_KEY)
  ) {
    const signOutEvent = new Event(SIGN_OUT_EVENT_NAME);
    document.getElementById(REACT_ROOT_NODE_ID)?.dispatchEvent(signOutEvent);
  }
};

export const withApiContextProvider = (WrappedComponent: any) => {
  return (props: any) => {
    const api = axios.create({
      baseURL: new URL(config.api.path, config.api.baseUrl).href,
      withCredentials: true,
      headers: {},
    });

    api.interceptors.request.use((axiosConfig) => {
      const token = localStorage.getItem(config.session.CHECKLIGHT_TOKEN_KEY);
      if (token && axiosConfig?.headers) {
        axiosConfig.headers['X-Access-Token'] = token;
      }
      return axiosConfig;
    });

    api.interceptors.response.use(
      (response) => {
        const {
          data: { token, ...data },
        } = response;
        if (token) {
          localStorage.setItem(config.session.CHECKLIGHT_TOKEN_KEY, token);
        }
        //TODO: Change contracts to avoid Array
        return Array.isArray(response.data) ? response.data : data;
      },
      (error) => {
        handleUnauthorized(error);

        let errorsArray = [error];

        // attempt to convert from an API error
        const errorList = error?.response?.data?._embedded?.errors;
        if (Array.isArray(errorList)) {
          errorsArray = errorList.map(({ message, statusCode, errorCode }) => {
            const err = new Error(message) as ApiError;
            if (statusCode) {
              err.statusCode = statusCode;
            }
            if (errorCode) {
              err.errorCode = errorCode;
            }
            return err;
          });
        }

        return Promise.reject(errorsArray);
      },
    );

    return (
      <ApiContext.Provider value={api}>
        <WrappedComponent {...props} />
      </ApiContext.Provider>
    );
  };
};
