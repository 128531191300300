import { PayFrequency } from '@thesavingsgroup/enums/PayFrequency';
import { getAmountByPayFrequency } from '@thesavingsgroup/utils/getAmountByPayFrequency';
import { isEmpty } from 'lodash';

import { UserIncome } from '../../providers/context/CurrentUserContext/CurrentUserContextT';
import { PanelListFormatType } from '../shared/PanelList/PanelList';

const formatEmployerPrefix = (employerName: string) =>
  isEmpty(employerName) ? '$' : `${employerName}, $`;

export const currencyFormatOptions = (prefix: string) => ({
  formatType: PanelListFormatType.NUMBER,
  formatOptions: {
    thousandSeparator: true,
    prefix: formatEmployerPrefix(prefix),
    displayType: 'text',
    suffix: '/month',
  },
});

export const getIncomeValue = ({ incomeFrequency, amount }: UserIncome) =>
  incomeFrequency
    ? getAmountByPayFrequency(PayFrequency.MONTHLY)(
        incomeFrequency,
        Number(amount),
      )
    : '0';
