import { pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import { config } from '../../config';
import { create, devToolOptions } from '../storeManager/storeManager';
import { DevtoolsPersistStore } from '../storeManager/types/DevtoolsPersistStore';
import { attributionStore, keysToPersist } from './attributionStore';
import { AttributionState } from './types/AttributionState';

const useAttributionStore: DevtoolsPersistStore<AttributionState> = create({
  ignoreReset: true,
})(
  devtools(
    persist<AttributionState>(attributionStore({ config }), {
      name: 'consumer_portal:attribution',
      partialize(state: AttributionState): AttributionState {
        return pick(state, keysToPersist) as AttributionState;
      },
    }),
    devToolOptions('attributionStore'),
  ),
);

export default useAttributionStore;
