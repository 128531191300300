import { RateSelectedErrors } from '@thesavingsgroup/enums/RateSelectedErrors';

export const urlParamsError = {
  [RateSelectedErrors.APPLICATION_KEY_MISSING_OR_EXPIRED]:
    'Activation link has expired, please sign in to continue.',
  [RateSelectedErrors.MISSING_APPLICATION_KEY]:
    'Unable to locate your activation link, please sign in to try again.',
  [RateSelectedErrors.INVALID_APPLICATION_KEY]:
    'Invalid activation link provided, please sign in to continue.',
  [RateSelectedErrors.INVALID_RATE_SELECTED]:
    'Invalid rate selection, please sign in to continue.',
  [RateSelectedErrors.MISSING_ENCODED_DATA]:
    'Unable to locate you in our system, please sign in to continue.',
  [RateSelectedErrors.LOS_IMPORT_ERROR]:
    'Please sign in to activate your application.',
};
